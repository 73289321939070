import className from 'classnames';
import { motion, useAnimation } from 'framer-motion';
import type { CSSProperties } from 'react';
import {
    forwardRef, useImperativeHandle, useRef, useState
} from 'react';
import { BeatLoader } from 'react-spinners';

export type ButtonRef = {
    startLoading: () => Promise<void>
    stopLoading: () => Promise<void>
    getWidth: () => Promise<number>
    stopLoadingWithSuccess: () => Promise<void>
};

type IButtonProps = {
    children: string;
    className?: string;
    dark?: boolean;
    animated?: boolean;
    ref?: ButtonRef;
    disabled?: boolean;
    transparent?: boolean;
    mobile?: boolean;
    fullWidth?: boolean;
    onClick?: Function;
    style?: CSSProperties;
    hasArrow?: boolean;
};

const Button = forwardRef((props: IButtonProps, ref) => {
    const buttonRef = useRef<HTMLDivElement | null>(null);
    const [isAnimating, setIsAnimating] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const textControls = useAnimation();
    const loadingControls = useAnimation();
    const doneControls = useAnimation();
    const btnClass = className(
        'cursor-pointer',
        {
            'btn-base': !props.mobile,
            'btn-mobile': props.mobile,
            btn: true,
            'btn-dark': props.dark,
            'btn-primary': !props.dark,
            'w-full': props.fullWidth,
            'btn-transparent': props.transparent,
            disabled: props.disabled,
        },
        props.className
    );

    useImperativeHandle(
        ref,
        () => ({
            getWidth: async () => buttonRef?.current?.getBoundingClientRect().width,
            startLoading: async () => {
                if (isAnimating) {
                    return;
                }
                setIsAnimating(true);
                textControls.start({
                    opacity: 0,
                    y: -2,
                    transition: { duration: 0.2 },
                });
                await loadingControls.start({
                    zIndex: 1,
                    y: 0,
                    opacity: 1,
                    transition: { duration: 0.3, delay: 0.2 },
                });
            },
            stopLoading: async () => {
                await Promise.all([
                    loadingControls.start({
                        zIndex: 1,
                        y: 4,
                        opacity: 0,
                        transition: { duration: 0.4 },
                    }),
                ]);
                await textControls.start({
                    zIndex: 1,
                    y: 0,
                    opacity: 1,
                    transition: { duration: 0.3, delay: 0.3 },
                });
                setIsAnimating(false);
            },
            stopLoadingWithSuccess: async () => {
                await Promise.all([
                    loadingControls.start({
                        zIndex: 1,
                        y: 4,
                        opacity: 0,
                        transition: { duration: 0.4 },
                    }),
                ]);
                await textControls.start({
                    zIndex: 1,
                    y: 0,
                    opacity: 1,
                    transition: { duration: 0.3, delay: 0.3 },
                });
                await Promise.all([
                    doneControls.start({
                        zIndex: 1,
                        y: 0,
                        opacity: 1,
                        transition: { duration: 0.22 },
                    }),
                    textControls.start({
                        zIndex: 1,
                        y: 0,
                        opacity: 1,
                        transition: { duration: 0.3 },
                    }),
                ]);
                doneControls.start({
                    x: -9,
                    opacity: 0,
                    transition: { duration: 0.2, delay: 0.15 },
                });
                await textControls.start({
                    y: 0,
                    x: 0,
                    transition: { duration: 0.25, delay: 0.15 },
                });
                doneControls.start({
                    y: -4,
                    x: 0,
                    opacity: 0,
                });
                setIsAnimating(false);
            },
        }),
        [ref]
    );

    return (
        <div
            ref={buttonRef}
            className={`${btnClass} ${props.hasArrow && props.dark && 'changeSvgOnHover'}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => {
                props.onClick && !props.disabled && props.onClick();
            }}
            style={props.style ? props.style : {}}
        >
            <motion.div className={'wrapper'}>
                {props.animated && (
                    <motion.div
                        initial={{ opacity: 0, y: -4 }}
                        animate={doneControls}
                        className="checkmark-wrapper"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="rgb(255, 2, 102)"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M5 13l4 4L19 7"
                            />
                        </svg>
                    </motion.div>
                )}
                <motion.span initial={{ opacity: 1 }} animate={textControls} className={props.hasArrow ? 'flex min-w-[12.8rem] items-center justify-center' : ''}>
                    {props.children} {props.hasArrow && <svg className='changeSvgOnHover ml-6 rotate-270' width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.87024 0L5.87024 10.5595" stroke={!props.dark ? '#000000' : '#D9D9D9'} strokeWidth="2" />
                        <path d="M0.960938 6.49805L6.4619 11.9999" stroke={!props.dark ? '#000000' : '#D9D9D9'} strokeWidth="2" />
                        <path d="M11.0001 6.49805L5.49916 11.9999" stroke={!props.dark ? '#000000' : '#D9D9D9'} strokeWidth="2" />
                    </svg>
                    }
                </motion.span>
                {props.animated && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={loadingControls}
                        className="loader-wrapper"
                    >
                        <BeatLoader
                            color={
                                props.dark
                                    ? !isHovered
                                        ? 'white'
                                        : 'black'
                                    : !isHovered
                                        ? 'black'
                                        : 'white'
                            }
                            size={6}
                        />
                    </motion.div>
                )}
            </motion.div>
            {/* {props.children} */}

            <style jsx>
                {`
          .btn {
            transition: all 0.2s ease-in-out;
            @apply inline-block text-center relative;
          }

          .btn-base {
            @apply text-[1.6rem] py-[1.6rem] px-[2.4rem] leading-[1.6rem];
          }

          .btn-mobile {
            @apply text-[2rem] py-[0.9rem] px-[2rem] leading-[3rem];
          }

          .btn-primary {
            @apply text-black bg-bpm-white;
          }
          .btn-primary:hover {
            @apply text-bpm-white bg-black;
          }

          .btn-primary:hover svg path {
            stroke: white;
          }

          .btn-primary:hover .loader-wrapper {
            stroke: white;
          }

          .btn-dark {
            @apply text-bpm-white bg-black;
          }

          .btn-dark:hover {
            @apply bg-bpm-white text-black;
          }

          .btn-transparent {
            @apply bg-transparent   py-[1.6rem] px-[2.4rem];
          }

          .disabled,
          .disabled:hover {
            @apply text-white bg-black cursor-default opacity-70;
          }

          :global(.checkmark-wrapper) {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            left: 0px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }

          :global(.checkmark-wrapper svg path) {
            stroke: #111827;
          }

          :global(.checkmark-wrapper svg) {
            position: absolute;
            top: 10px;
            left: 15px;
            width: 30px;
            height: 30px;
          }

          :global(.loader-wrapper) {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        `}
            </style>
        </div>
    );
});

Button.displayName = 'Button';

export { Button };
