import { useRouter } from 'next/router';
import { createContext, useContext, useEffect } from 'react';

import { LayoutStore } from '@/stores/LayoutStore';
import { useDeviceType } from '@/utils/useDeviceType';

import { SubscriptionStore } from './SubscriptionStore';

class RootStore {
    layoutStore: LayoutStore;

    subscriptionStore: SubscriptionStore;

    constructor() {
        this.layoutStore = new LayoutStore();
        this.subscriptionStore = new SubscriptionStore();
    }
}

const StoreContext = createContext<RootStore>(new RootStore());

const StoreProvider: React.FC<{ store: RootStore; children: JSX.Element }> = ({
    store,
    children,
}) => {
    const router = useRouter();
    const deviceType = useDeviceType();
    useEffect(() => {
        deviceType && store.layoutStore.setDeviceType(deviceType);
    }, [deviceType]);

    useEffect(() => {
        store.layoutStore.closeMobileMenu();
    }, [router.asPath]);

    return (
        <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
    );
};

const useStore = () => useContext(StoreContext);

export { RootStore, StoreProvider, useStore };
