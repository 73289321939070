import React from 'react';

export enum DeviceType {
    Mobile = 'Mobile',
    Tablet = 'Tablet',
    Desktop = 'Desktop',
}

export const DeviceSize = {
    Mobile: 768,
    Tablet: 1024,
    Desktop: 1440,
};

const getSizeByMediaQuery = (width: number) => {
    let deviceType;
    if (width < DeviceSize.Mobile) {
        deviceType = DeviceType.Mobile;
    } else if (width < DeviceSize.Tablet) {
        deviceType = DeviceType.Tablet;
    } else {
        deviceType = DeviceType.Desktop;
    }
    return deviceType;
};

const useDeviceType = () => {
    const [deviceType, setDeviceType] = React.useState<DeviceType | null>(null);
  React.useEffect(() => {
    window.addEventListener('resize', () => {
      setDeviceType(getSizeByMediaQuery(window.innerWidth));
    });
    setDeviceType(getSizeByMediaQuery(window.innerWidth));
  }, []);

  return deviceType;
};

export { useDeviceType };
