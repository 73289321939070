import { action, makeAutoObservable } from 'mobx';

import { DeviceType } from '@/utils/useDeviceType';

class LayoutStore {
    mobileMenuIsOpen = false;

    accent: string | null = null;

    deviceType: DeviceType | null = null;

    get isMobile() {
        return this.deviceType === DeviceType.Mobile;
    }

    constructor() {
    makeAutoObservable(this);
    }

    @action setDeviceType = (deviceType: DeviceType) => {
        this.deviceType = deviceType;
    };

    @action setAccent = (accent: string) => {
        this.accent = accent;
    };

    @action closeMobileMenu = () => {
        this.mobileMenuIsOpen = false;
    };

    @action toggleMobileMenu = () => {
        this.mobileMenuIsOpen = !this.mobileMenuIsOpen;
    };
}
export { LayoutStore };
