import type { ApolloQueryResult } from '@apollo/client';
import { ApolloClient, InMemoryCache } from '@apollo/client';

import type { GetGlobalQuery, GetPagesQuery } from '@/generated/graphql-types';
import { GetGlobalDocument, GetPagesDocument } from '@/generated/graphql-types';

export class GraphQlAPI {
    private static instance: GraphQlAPI = new GraphQlAPI();

    private client: ApolloClient<any> | null = null;

    private constructor() {
        if (GraphQlAPI.instance) {
            throw new Error(
                'Error: Instantiation failed: Use SingletonClass.getInstance() instead of new.'
            );
        }
        GraphQlAPI.instance = this;
        this.client = new ApolloClient({
            uri: `${process.env.NEXT_PUBLIC_STRAPI_API_URL}/graphql`,
            cache: new InMemoryCache(),
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'ignore',
                },
                query: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                },
            },
        });
    }

    public static getInstance(): GraphQlAPI {
        return GraphQlAPI.instance;
    }

    public static getPageData = async (props: any) => {
        const { slug, preview } = props;
        const pagesData: ApolloQueryResult<GetPagesQuery> | undefined = await GraphQlAPI.getInstance().client?.query({
            query: GetPagesDocument,
            variables: {
                slug: slug.toLowerCase(),
                publicationState: preview ? 'PREVIEW' : 'LIVE',
                // locale,
            },
        });

        // Make sure we found something, otherwise return null
        if (pagesData?.data?.pages == null) {
            return null;
        }

        // Return the first item since there should only be one result per slug
        return pagesData?.data.pages.data[0];
    };

    public static getGlobalData = async (locale: string) => {
        const globalData: ApolloQueryResult<GetGlobalQuery> | undefined = await GraphQlAPI.getInstance().client?.query({
            query: GetGlobalDocument,
            variables: { locale },
        });
        return globalData?.data!;
    };
}
