import { action, makeAutoObservable } from 'mobx';

import { supremeConfig } from '@/api/config';
import { UserApi } from '@/api/supreme-api-sdk/src';

class SubscriptionStore {
    subscriptions:
    | null
    | Awaited<
    ReturnType<UserApi['getMeAllSubscriptions']>
    >['data']['membership'] = null;

    subscriptionsLoaded: boolean = false;

    isLoggedIn: boolean = false;

    userApiClient = new UserApi(supremeConfig);

    constructor() {
    makeAutoObservable(this);
    }

    @action logOut = async () => {
        try {
            await this.userApiClient.logout();
            this.isLoggedIn = false;
            // eslint-disable-next-line no-empty
        } catch (error) {}
    };

    @action getSubscriptionState = async () => {
        try {
            const membershipsResponse = await this.userApiClient.getMeAllSubscriptions();
            if (
                membershipsResponse
        && membershipsResponse.data
        && membershipsResponse.data.membership
            ) {
                this.subscriptions = membershipsResponse.data.membership;
            }
            this.isLoggedIn = true;
            // eslint-disable-next-line no-empty
        } catch (error) {}
        this.subscriptionsLoaded = true;
    };
}
export { SubscriptionStore };
