import { Configuration } from './supreme-api-sdk/src';

export const supremeBasePath = process.env.NEXT_PUBLIC_SUPREME_API_BASE_PATH;
const isDevelop = supremeBasePath?.includes('develop.');
const authCookieName = isDevelop ? 'bpm_session_develop' : 'bpm_session';
const adminCookieName = isDevelop
  ? 'bpm_admin_session_develop'
  : 'bpm_admin_session';

const parseCookie = () => document.cookie
    .split(';')
    .map((v) => v.split('='))
    .reduce((acc, v) => {
        if (v && v.length > 1) {
            const key = decodeURIComponent(v[0]!.trim());
            acc[key] = decodeURIComponent(v[1]!.trim());
        }
        return acc;
    }, {} as { [key: string]: string });

export const apiKey = (key?: string) => {
    if (key === 'Admin-Authorization') {
        const cookies = parseCookie();
        // eslint-disable-next-line @typescript-eslint/dot-notation
        if (cookies[adminCookieName]) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            return `Bearer ${cookies[adminCookieName]}`;
        }
        return undefined;
    }
    const cookies = parseCookie();
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (cookies[authCookieName]) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
        return `Bearer ${cookies[authCookieName]}`;
    }
    return undefined;
};

export const isAdminSession = () => {
    const cookies = parseCookie();
    return Boolean(cookies[adminCookieName]);
};

export const supremeConfig = new Configuration({
    basePath: supremeBasePath,
    includeCredentials: true,
    apiKey,
});
