import '../styles/global.css';
import 'react-toastify/dist/ReactToastify.css';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { AnimatePresence } from 'framer-motion';
import type { AppProps } from 'next/app';
import App from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { DefaultSeo } from 'next-seo';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import TiktokPixel from 'tiktok-pixel';
import { setTimeout } from 'timers';

import { supremeConfig } from '@/api/config';
import { GraphQlAPI } from '@/api/graphql';
import { UserApi } from '@/api/supreme-api-sdk/src';
import { StoreProvider, useStore } from '@/stores/RootStore';
import { getStrapiMedia } from '@/utils/media';

import FourOhFour from './404';

export const userApiClient = new UserApi(supremeConfig);

const MyApp = (props: AppProps) => {
    const { Component, pageProps } = props;

    const rootStore = useStore();

    useEffect(() => {
        rootStore.subscriptionStore.getSubscriptionState();
        setTimeout(() => window.scrollTo(0, 0), 150);
        process.env.NEXT_PUBLIC_USE_BUGSNAG === 'true' && Bugsnag.start({
            apiKey: process.env.NEXT_PUBLIC_BUGSNAG_APIKEY || '',
            plugins: [new BugsnagPluginReact()],
        });
        TiktokPixel.init('CMNE1ARC77UF4ULPFOTG', {}, { debug: false });
    }, []);

    const { global } = pageProps;
    if (!global || !global.attributes || !global.attributes.metadata) {
        return <FourOhFour />;
    }

    const { metadata, metaTitleSuffix } = global.attributes;

    return (
        <StoreProvider store={rootStore}>
            <>
                <Head>
                    <link
                        key="favicon"
                        rel="shortcut icon"
                        href={getStrapiMedia(metadata.favicon.data.attributes.url) ?? ''}
                    />
                    <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
                    <meta name="theme-color" content="#151515" />
                    <meta name="google-site-verification" content="tUcUbKaB_H_qD__tH0K_myD1L4f_Wf51TzBzBorbLes" />
                    {process.env.NEXT_PUBLIC_NO_INDEX && <meta name="robots" content="noindex" />}
                </Head>
                <Script src="https://www.google.com/recaptcha/enterprise.js?render=6LeL-yciAAAAAP0MHwC6OYrrJG1vATsij6jVFYKh" />
                <Script src="https://cdn.attn.tv/bpmmusic/dtag.js" />
                {/* Global site metadata */}
                <DefaultSeo
                    titleTemplate={`%s | ${metaTitleSuffix}`}
                    title="Page"
                    description={metadata.metaDescription}
                    openGraph={{
                        images: Object.values(
                            metadata.shareImage.data.attributes.formats
                        ).map((image: any) => ({
                            url: getStrapiMedia(image.url),
                            width: image.width,
                            height: image.height,
                        })) as any,
                    }}
                    twitter={{
                        cardType: metadata.twitterCardType,
                        handle: metadata.twitterUsername,
                    }}
                />
                <ToastContainer />
                <AnimatePresence exitBeforeEnter initial presenceAffectsLayout>
                    <Component {...pageProps} />
                </AnimatePresence>
            </>
        </StoreProvider>
    );
};

MyApp.getInitialProps = async (appContext: any) => {
    // Calls page's `getInitialProps` and fills `appProps.pageProps`
    const appProps = await App.getInitialProps(appContext);
    const globalLocale = await GraphQlAPI.getGlobalData(appContext.router.locale ?? 'en');
    return {
        ...appProps,
        pageProps: {
            global: { ...globalLocale },
        },
    };
};

export default MyApp;
