import { Button } from '@/components/Button';

export default function FourOhFour() {
    return <div className='flex h-[100vh] w-[100vw] items-center justify-center text-center align-middle text-[#e6f1e9]'>

        <div>
            <img alt="404 bpm" className='mx-auto' src="/apple-touch-icon.png" width="150" height="150" />
            <h1 className='text-[6rem]'>Something Went Wrong</h1>
            <p className='mb-16 text-[3rem] leading-[3.2rem]'>There was a problem displaying this page.<br />This page either does not exist or an error occurred.</p>
            <Button onClick={() => window.open('/', '_self')}>Go To Home</Button>
        </div>
    </div>;
}
