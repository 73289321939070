/// <reference path="./custom.d.ts" />
// tslint:disable
// @ts-nocheck
/**
 * BPM Supreme
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 4.1.6
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from 'url';
import * as isomorphicFetch from 'isomorphic-fetch';
import { Configuration } from './configuration';

const BASE_PATH = 'https://api.develop.bpmsupreme.com/v4'.replace(/\/+$/, '');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ',',
    ssv: ' ',
    tsv: '\t',
    pipes: '|',
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: 'RequiredError'
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface Ad
 */
export interface Ad {
    /**
     * 
     * @type {string}
     * @memberof Ad
     */
    id: string;
    /**
     * The type of ad
     * @type {string}
     * @memberof Ad
     */
    type: Ad.TypeEnum;
    /**
     * The title
     * @type {string}
     * @memberof Ad
     */
    title: string;
    /**
     * The sub title, Rich text possible!
     * @type {string}
     * @memberof Ad
     */
    sub_title: string;
    /**
     * The CTA title
     * @type {string}
     * @memberof Ad
     */
    cta: string;
    /**
     * The CTA link, absolute or relative
     * @type {string}
     * @memberof Ad
     */
    cta_link: string;
    /**
     * Extra info,  Rich text possible!
     * @type {string}
     * @memberof Ad
     */
    extra_info: string;
    /**
     * The iamge url
     * @type {string}
     * @memberof Ad
     */
    image_url: string;
}

/**
 * @export
 * @namespace Ad
 */
export namespace Ad {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Playlist = <any> 'playlist',
        Album = <any> 'album',
        Song = <any> 'song',
        CuratedSet = <any> 'curated-set',
        Artist = <any> 'artist'
    }
}
/**
 * 
 * @export
 * @interface AnalyticsTrack
 */
export interface AnalyticsTrack {
    /**
     * 
     * @type {Array<TrackClick | TrackGeneric | TrackSession | TrackView>}
     * @memberof AnalyticsTrack
     */
    events: Array<TrackClick | TrackGeneric | TrackSession | TrackView>;
}
/**
 * 
 * @export
 * @interface BlogArticle
 */
export interface BlogArticle {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof BlogArticle
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof BlogArticle
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof BlogArticle
     */
    updated_at: string;
    /**
     * The provider id
     * @type {string}
     * @memberof BlogArticle
     */
    provider_id: string;
    /**
     * The title
     * @type {string}
     * @memberof BlogArticle
     */
    title: string;
    /**
     * The image url, if available
     * @type {string}
     * @memberof BlogArticle
     */
    image_url?: string;
    /**
     * The original posted date
     * @type {string}
     * @memberof BlogArticle
     */
    posted_date: string;
    /**
     * The video URL, if available
     * @type {string}
     * @memberof BlogArticle
     */
    video_url?: string;
    /**
     * The external link to the providers blog article
     * @type {string}
     * @memberof BlogArticle
     */
    link: string;
    /**
     * ToDo. (only needed if news are rendered in platform instead of opening external link)
     * @type {any}
     * @memberof BlogArticle
     */
    mappings: any;
    /**
     * The simplified article version, HTML removed. Please also consult with BPM Team on how to render special cases like music players (see mappings)
     * @type {string}
     * @memberof BlogArticle
     */
    rendered: string;
    /**
     * Original article (HTML).  Please also consult with BPM Team on how to render special cases like music players (see mappings)
     * @type {string}
     * @memberof BlogArticle
     */
    original_rendered: string;
    /**
     * Unique article slug for this provider
     * @type {string}
     * @memberof BlogArticle
     */
    slug: string;
    /**
     * Tags associated with this article
     * @type {Array<BlogArticleTag>}
     * @memberof BlogArticle
     */
    tags: Array<BlogArticleTag>;
    /**
     * 
     * @type {BlogArticleProvider}
     * @memberof BlogArticle
     */
    provider: BlogArticleProvider;
}
/**
 * 
 * @export
 * @interface BlogArticleProvider
 */
export interface BlogArticleProvider {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof BlogArticleProvider
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof BlogArticleProvider
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof BlogArticleProvider
     */
    updated_at: string;
    /**
     * Provider name
     * @type {string}
     * @memberof BlogArticleProvider
     */
    title: string;
    /**
     * Provider image url
     * @type {string}
     * @memberof BlogArticleProvider
     */
    image_url: string;
    /**
     * Provider banner image url
     * @type {string}
     * @memberof BlogArticleProvider
     */
    banner_image_url: string;
    /**
     * Does current user follow this provider? Only set if Authorization is set in request
     * @type {boolean}
     * @memberof BlogArticleProvider
     */
    is_followed?: boolean;
}
/**
 * 
 * @export
 * @interface BlogArticleTag
 */
export interface BlogArticleTag {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof BlogArticleTag
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof BlogArticleTag
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof BlogArticleTag
     */
    updated_at: string;
    /**
     * Tag name
     * @type {string}
     * @memberof BlogArticleTag
     */
    title: string;
}
/**
 * 
 * @export
 * @interface ChangeSubscriptionRequest
 */
export interface ChangeSubscriptionRequest {
    /**
     * 
     * @type {ChangeSubscriptionRequestCreditCard | ChangeSubscriptionRequestPayPal | ChangeSubscriptionRequestStripe | ChangeSubscriptionRequestPaymentCurrent}
     * @memberof ChangeSubscriptionRequest
     */
    payment_data: ChangeSubscriptionRequestCreditCard | ChangeSubscriptionRequestPayPal | ChangeSubscriptionRequestStripe | ChangeSubscriptionRequestPaymentCurrent;
    /**
     * Name of the coupon
     * @type {string}
     * @memberof ChangeSubscriptionRequest
     */
    coupon?: string;
}
/**
 * 
 * @export
 * @interface ChangeSubscriptionRequestCreditCard
 */
export interface ChangeSubscriptionRequestCreditCard {
    /**
     * 
     * @type {string}
     * @memberof ChangeSubscriptionRequestCreditCard
     */
    method: ChangeSubscriptionRequestCreditCard.MethodEnum;
    /**
     * 
     * @type {string}
     * @memberof ChangeSubscriptionRequestCreditCard
     */
    pan: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeSubscriptionRequestCreditCard
     */
    expMonth: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeSubscriptionRequestCreditCard
     */
    expYear: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeSubscriptionRequestCreditCard
     */
    cvv: string;
}

/**
 * @export
 * @namespace ChangeSubscriptionRequestCreditCard
 */
export namespace ChangeSubscriptionRequestCreditCard {
    /**
     * @export
     * @enum {string}
     */
    export enum MethodEnum {
        Card = <any> 'card'
    }
}
/**
 * 
 * @export
 * @interface ChangeSubscriptionRequestPayPal
 */
export interface ChangeSubscriptionRequestPayPal {
    /**
     * 
     * @type {string}
     * @memberof ChangeSubscriptionRequestPayPal
     */
    method: ChangeSubscriptionRequestPayPal.MethodEnum;
    /**
     * The URL to redirect to after operation is completed. The APi will append /success or /failure to the URL
     * @type {string}
     * @memberof ChangeSubscriptionRequestPayPal
     */
    redirect_base: string;
}

/**
 * @export
 * @namespace ChangeSubscriptionRequestPayPal
 */
export namespace ChangeSubscriptionRequestPayPal {
    /**
     * @export
     * @enum {string}
     */
    export enum MethodEnum {
        Paypal = <any> 'paypal'
    }
}
/**
 * 
 * @export
 * @interface ChangeSubscriptionRequestPaymentCurrent
 */
export interface ChangeSubscriptionRequestPaymentCurrent {
    /**
     * 
     * @type {string}
     * @memberof ChangeSubscriptionRequestPaymentCurrent
     */
    method: ChangeSubscriptionRequestPaymentCurrent.MethodEnum;
    /**
     * If existing is choosen, always pass a redirect base. In some cases users need to re-authorize their paypal subscription! The URL to redirect to after operation is completed. The APi will append /success or /failure to the URL
     * @type {string}
     * @memberof ChangeSubscriptionRequestPaymentCurrent
     */
    redirect_base: string;
}

/**
 * @export
 * @namespace ChangeSubscriptionRequestPaymentCurrent
 */
export namespace ChangeSubscriptionRequestPaymentCurrent {
    /**
     * @export
     * @enum {string}
     */
    export enum MethodEnum {
        Existing = <any> 'existing'
    }
}
/**
 * 
 * @export
 * @interface ChangeSubscriptionRequestStripe
 */
export interface ChangeSubscriptionRequestStripe {
    /**
     * 
     * @type {string}
     * @memberof ChangeSubscriptionRequestStripe
     */
    method: ChangeSubscriptionRequestStripe.MethodEnum;
    /**
     * 
     * @type {string}
     * @memberof ChangeSubscriptionRequestStripe
     */
    payment_method_id?: string;
}

/**
 * @export
 * @namespace ChangeSubscriptionRequestStripe
 */
export namespace ChangeSubscriptionRequestStripe {
    /**
     * @export
     * @enum {string}
     */
    export enum MethodEnum {
        Stripe = <any> 'stripe'
    }
}
/**
 * 
 * @export
 * @interface ChangeSubscriptionResultPaypal
 */
export interface ChangeSubscriptionResultPaypal {
    /**
     * 
     * @type {string}
     * @memberof ChangeSubscriptionResultPaypal
     */
    type: ChangeSubscriptionResultPaypal.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ChangeSubscriptionResultPaypal
     */
    url: string;
}

/**
 * @export
 * @namespace ChangeSubscriptionResultPaypal
 */
export namespace ChangeSubscriptionResultPaypal {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Paypal = <any> 'paypal'
    }
}
/**
 * 
 * @export
 * @interface ChangeSubscriptionResultStripe
 */
export interface ChangeSubscriptionResultStripe {
    /**
     * 
     * @type {string}
     * @memberof ChangeSubscriptionResultStripe
     */
    type: ChangeSubscriptionResultStripe.TypeEnum;
    /**
     * 
     * @type {Subscription}
     * @memberof ChangeSubscriptionResultStripe
     */
    subscription: Subscription;
    /**
     * 
     * @type {string}
     * @memberof ChangeSubscriptionResultStripe
     */
    invoice_page?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChangeSubscriptionResultStripe
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChangeSubscriptionResultStripe
     */
    payment_client_secret?: string;
}

/**
 * @export
 * @namespace ChangeSubscriptionResultStripe
 */
export namespace ChangeSubscriptionResultStripe {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Stripe = <any> 'stripe'
    }
}
/**
 * 
 * @export
 * @interface ChangeSusbcriptionResult
 */
export interface ChangeSusbcriptionResult {
}
/**
 * 
 * @export
 * @interface ChangeSusbcriptionResultOrder
 */
export interface ChangeSusbcriptionResultOrder {
}
/**
 * 
 * @export
 * @interface CommentsIdBody
 */
export interface CommentsIdBody {
    /**
     * The comment
     * @type {string}
     * @memberof CommentsIdBody
     */
    comment: string;
}
/**
 * 
 * @export
 * @interface CouponUsageCalc
 */
export interface CouponUsageCalc {
    /**
     * 
     * @type {string}
     * @memberof CouponUsageCalc
     */
    original_amount: string;
    /**
     * 
     * @type {string}
     * @memberof CouponUsageCalc
     */
    off_amount: string;
    /**
     * Final amount beeing charged now
     * @type {string}
     * @memberof CouponUsageCalc
     */
    final_amount: string;
    /**
     * 
     * @type {string}
     * @memberof CouponUsageCalc
     */
    type?: CouponUsageCalc.TypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CouponUsageCalc
     */
    monthly_activation_count?: number;
    /**
     * 
     * @type {number}
     * @memberof CouponUsageCalc
     */
    value?: number;
    /**
     * 
     * @type {CouponUsageSchedule}
     * @memberof CouponUsageCalc
     */
    schedule?: CouponUsageSchedule;
}

/**
 * @export
 * @namespace CouponUsageCalc
 */
export namespace CouponUsageCalc {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        PercentageOff = <any> 'percentage_off',
        DollarOff = <any> 'dollar_off',
        PromoCredits = <any> 'promo_credits',
        RegularCredits = <any> 'regular_credits'
    }
}
/**
 * 
 * @export
 * @interface CouponUsageSchedule
 */
export interface CouponUsageSchedule {
    /**
     * Rewnewl price for `renewals` months
     * @type {string}
     * @memberof CouponUsageSchedule
     */
    renewal_price: string;
    /**
     * Amount of months the renewal price is valid for
     * @type {number}
     * @memberof CouponUsageSchedule
     */
    renewals: number;
    /**
     * Renewal price after schedule
     * @type {string}
     * @memberof CouponUsageSchedule
     */
    renewal_price_after: string;
    /**
     * When `renewal_price_after` takes affect
     * @type {string}
     * @memberof CouponUsageSchedule
     */
    start_of_renewal_price_after: string;
}
/**
 * 
 * @export
 * @interface CreateDevice
 */
export interface CreateDevice {
    /**
     * Set this to true if you want the response to include a delivery_iv
     * @type {boolean}
     * @memberof CreateDevice
     */
    request_iv?: boolean;
    /**
     * Unique identifier of the application
     * @type {string}
     * @memberof CreateDevice
     */
    app_type: string;
    /**
     * Current version of the application
     * @type {string}
     * @memberof CreateDevice
     */
    app_version: string;
    /**
     * Current build version of the application
     * @type {string}
     * @memberof CreateDevice
     */
    build_version: string;
    /**
     * Push token, if available
     * @type {string}
     * @memberof CreateDevice
     */
    device_token?: string;
    /**
     * The unique device id
     * @type {string}
     * @memberof CreateDevice
     */
    device_uuid: string;
    /**
     * The token provider
     * @type {string}
     * @memberof CreateDevice
     */
    token_provider?: CreateDevice.TokenProviderEnum;
    /**
     * The applications os (like web, mac os, ios, android). Note: If device_data_os == 'web', the api will automatic set session cookies.
     * @type {string}
     * @memberof CreateDevice
     */
    device_data_os?: string;
    /**
     * Version of the applications os
     * @type {string}
     * @memberof CreateDevice
     */
    device_data_os_version?: string;
    /**
     * For example iPhone 7, iPhone X
     * @type {string}
     * @memberof CreateDevice
     */
    device_data_device_type?: string;
    /**
     * Given name of the device, like Alexander´s iPhone
     * @type {string}
     * @memberof CreateDevice
     */
    device_data_device_name?: string;
    /**
     * For example Phone, Computer, Tablet, ...
     * @type {string}
     * @memberof CreateDevice
     */
    device_data_device_category?: string;
    /**
     * Set to true if debug session. Note: Under iOS, this will also indicate the sandbox push environment for the API.
     * @type {boolean}
     * @memberof CreateDevice
     */
    debug: boolean;
    /**
     * Device language code, like en-US
     * @type {string}
     * @memberof CreateDevice
     */
    language: string;
    /**
     * The current device badge. Only applicable to devices with a app badge functionality.
     * @type {number}
     * @memberof CreateDevice
     */
    badge?: number;
}

/**
 * @export
 * @namespace CreateDevice
 */
export namespace CreateDevice {
    /**
     * @export
     * @enum {string}
     */
    export enum TokenProviderEnum {
        Apple = <any> 'apple',
        Google = <any> 'google',
        Web = <any> 'web'
    }
}
/**
 * 
 * @export
 * @interface Device
 */
export interface Device {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof Device
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Device
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Device
     */
    updated_at: string;
    /**
     * Unique identifier of the application
     * @type {string}
     * @memberof Device
     */
    app_type: string;
    /**
     * Current version of the application
     * @type {string}
     * @memberof Device
     */
    app_version: string;
    /**
     * Current build version of the application
     * @type {string}
     * @memberof Device
     */
    build_version: string;
    /**
     * Push token, if available
     * @type {string}
     * @memberof Device
     */
    device_token?: string;
    /**
     * The token provider
     * @type {string}
     * @memberof Device
     */
    token_provider?: Device.TokenProviderEnum;
    /**
     * The applications os (like web, mac os, ios, android). Note: If device_data_os == 'web', the api will automatic set session cookies.
     * @type {string}
     * @memberof Device
     */
    device_data_os?: string;
    /**
     * Version of the applications os
     * @type {string}
     * @memberof Device
     */
    device_data_os_version?: string;
    /**
     * For example iPhone 7, iPhone X
     * @type {string}
     * @memberof Device
     */
    device_data_device_type?: string;
    /**
     * Given name of the device, like Alexander´s iPhone
     * @type {string}
     * @memberof Device
     */
    device_data_device_name?: string;
    /**
     * For example Phone, Computer, Tablet, ...
     * @type {string}
     * @memberof Device
     */
    device_data_device_category?: string;
    /**
     * The unique device id
     * @type {string}
     * @memberof Device
     */
    device_uuid?: string;
    /**
     * Set to true if debug session. Note: Under iOS, this will also indicate the sandbox push environment for the API.
     * @type {boolean}
     * @memberof Device
     */
    debug: boolean;
    /**
     * Device language code, like en-US
     * @type {string}
     * @memberof Device
     */
    language: string;
    /**
     * The current device badge. Only applicable to devices with a app badge functionality.
     * @type {number}
     * @memberof Device
     */
    badge?: number;
    /**
     * The current session token. Use this in subsequent requests (if choosen authentication method is Authorization Header).
     * @type {string}
     * @memberof Device
     */
    session_token: string;
    /**
     * The delivery initialization vector for key pair delivery. Only set if request_iv was set to true.
     * @type {string}
     * @memberof Device
     */
    delivery_iv?: string;
    /**
     * If this device is the current device used with the current session
     * @type {boolean}
     * @memberof Device
     */
    is_current_device?: boolean;
    /**
     * Last IP address
     * @type {boolean}
     * @memberof Device
     */
    ip?: boolean;
}

/**
 * @export
 * @namespace Device
 */
export namespace Device {
    /**
     * @export
     * @enum {string}
     */
    export enum TokenProviderEnum {
        Apple = <any> 'apple',
        Google = <any> 'google',
        Web = <any> 'web'
    }
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DeviceAuthorizationType {
    Ignore = <any> 'ignore',
    Authorized = <any> 'authorized',
    LimitReached = <any> 'limit-reached',
    LimitReachedUpgradable = <any> 'limit-reached-upgradable',
    Verify = <any> 'verify'
}
/**
 * 
 * @export
 * @interface DeviceUuidNameBody
 */
export interface DeviceUuidNameBody {
    /**
     * 
     * @type {string}
     * @memberof DeviceUuidNameBody
     */
    name: string;
}
/**
 * 
 * @export
 * @interface DeviceWithUser
 */
export interface DeviceWithUser {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof DeviceWithUser
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof DeviceWithUser
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof DeviceWithUser
     */
    updated_at: string;
    /**
     * Unique identifier of the application
     * @type {string}
     * @memberof DeviceWithUser
     */
    app_type: string;
    /**
     * Domain Set Token
     * @type {string}
     * @memberof DeviceWithUser
     */
    dst?: string;
    /**
     * Current version of the application
     * @type {string}
     * @memberof DeviceWithUser
     */
    app_version: string;
    /**
     * Current build version of the application
     * @type {string}
     * @memberof DeviceWithUser
     */
    build_version: string;
    /**
     * Push token, if available
     * @type {string}
     * @memberof DeviceWithUser
     */
    device_token?: string;
    /**
     * The token provider
     * @type {string}
     * @memberof DeviceWithUser
     */
    token_provider?: DeviceWithUser.TokenProviderEnum;
    /**
     * The applications os (like web, mac os, ios, android). Note: If device_data_os == 'web', the api will automatic set session cookies.
     * @type {string}
     * @memberof DeviceWithUser
     */
    device_data_os?: string;
    /**
     * Version of the applications os
     * @type {string}
     * @memberof DeviceWithUser
     */
    device_data_os_version?: string;
    /**
     * For example iPhone 7, iPhone X
     * @type {string}
     * @memberof DeviceWithUser
     */
    device_data_device_type?: string;
    /**
     * Given name of the device, like Alexander´s iPhone
     * @type {string}
     * @memberof DeviceWithUser
     */
    device_data_device_name?: string;
    /**
     * For example Phone, Computer, Tablet, ...
     * @type {string}
     * @memberof DeviceWithUser
     */
    device_data_device_category?: string;
    /**
     * The unique device id
     * @type {string}
     * @memberof DeviceWithUser
     */
    device_uuid?: string;
    /**
     * Set to true if debug session. Note: Under iOS, this will also indicate the sandbox push environment for the API.
     * @type {boolean}
     * @memberof DeviceWithUser
     */
    debug: boolean;
    /**
     * Device language code, like en-US
     * @type {string}
     * @memberof DeviceWithUser
     */
    language: string;
    /**
     * The current device badge. Only applicable to devices with a app badge functionality.
     * @type {number}
     * @memberof DeviceWithUser
     */
    badge?: number;
    /**
     * The current session token. Use this in subsequent requests (if choosen authentication method is Authorization Header).
     * @type {string}
     * @memberof DeviceWithUser
     */
    session_token: string;
    /**
     * The delivery initialization vector for key pair delivery. Only set if request_iv was set to true.
     * @type {string}
     * @memberof DeviceWithUser
     */
    delivery_iv?: string;
    /**
     * 
     * @type {User}
     * @memberof DeviceWithUser
     */
    user: User;
}

/**
 * @export
 * @namespace DeviceWithUser
 */
export namespace DeviceWithUser {
    /**
     * @export
     * @enum {string}
     */
    export enum TokenProviderEnum {
        Apple = <any> 'apple',
        Google = <any> 'google',
        Web = <any> 'web'
    }
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DownloadFeature {
    Playlist = <any> 'playlist',
    CuratedSet = <any> 'curated_set'
}
/**
 * 
 * @export
 * @interface EnterExistingBody
 */
export interface EnterExistingBody {
    /**
     * The entered zip code
     * @type {string}
     * @memberof EnterExistingBody
     */
    zip: string;
    /**
     * The referal code
     * @type {string}
     * @memberof EnterExistingBody
     */
    referral?: string;
}
/**
 * 
 * @export
 * @interface EnterNewBody
 */
export interface EnterNewBody {
    /**
     * The entered zip code
     * @type {string}
     * @memberof EnterNewBody
     */
    zip: string;
    /**
     * The referal code
     * @type {string}
     * @memberof EnterNewBody
     */
    referral?: string;
    /**
     * 
     * @type {string}
     * @memberof EnterNewBody
     */
    email?: string;
    /**
     * Users full name
     * @type {string}
     * @memberof EnterNewBody
     */
    name?: string;
    /**
     * 
     * @type {CreateDevice}
     * @memberof EnterNewBody
     */
    device?: CreateDevice;
}
/**
 * 
 * @export
 * @interface Feature
 */
export interface Feature {
}
/**
 * 
 * @export
 * @interface ForgotpasswordBody
 */
export interface ForgotpasswordBody {
    /**
     * 
     * @type {string}
     * @memberof ForgotpasswordBody
     */
    email: string;
}
/**
 * 
 * @export
 * @interface GetMeWithSubscription
 */
export interface GetMeWithSubscription {
    /**
     * 
     * @type {User}
     * @memberof GetMeWithSubscription
     */
    user: User;
    /**
     * 
     * @type {Membership}
     * @memberof GetMeWithSubscription
     */
    membership: Membership;
    /**
     * 
     * @type {boolean}
     * @memberof GetMeWithSubscription
     */
    use_stripe_integration?: boolean;
    /**
     * 
     * @type {GetMeWithSubscriptionDeviceAuthorization}
     * @memberof GetMeWithSubscription
     */
    device_authorization: GetMeWithSubscriptionDeviceAuthorization;
}
/**
 * 
 * @export
 * @interface GetMeWithSubscriptionDeviceAuthorization
 */
export interface GetMeWithSubscriptionDeviceAuthorization {
    /**
     * 
     * @type {DeviceAuthorizationType}
     * @memberof GetMeWithSubscriptionDeviceAuthorization
     */
    type?: DeviceAuthorizationType;
    /**
     * 
     * @type {Array<Device>}
     * @memberof GetMeWithSubscriptionDeviceAuthorization
     */
    devices?: Array<Device>;
}
/**
 * 
 * @export
 * @interface Giveaway
 */
export interface Giveaway {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof Giveaway
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Giveaway
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Giveaway
     */
    updated_at: string;
    /**
     * Main colors of the giveaways artwork, seperated by comma. For example: #db6d4d, #261b27, #792e3a, #93948d, #8d8c8c
     * @type {string}
     * @memberof Giveaway
     */
    artwork_gradient_colors?: string;
    /**
     * The start date of the giveaway
     * @type {string}
     * @memberof Giveaway
     */
    start_date: string;
    /**
     * The entry deadline date of the giveaway. Deactivate entry button after this date.
     * @type {string}
     * @memberof Giveaway
     */
    entry_deadline: string;
    /**
     * The announcement date of the giveaway
     * @type {string}
     * @memberof Giveaway
     */
    announcement: string;
    /**
     * The title
     * @type {string}
     * @memberof Giveaway
     */
    title: string;
    /**
     * The optional subtitle
     * @type {string}
     * @memberof Giveaway
     */
    subtitle?: string;
    /**
     * The description as HTML string
     * @type {string}
     * @memberof Giveaway
     */
    description: string;
    /**
     * The slug
     * @type {string}
     * @memberof Giveaway
     */
    slug: string;
    /**
     * The platform this giveaway is for
     * @type {string}
     * @memberof Giveaway
     */
    platform: Giveaway.PlatformEnum;
    /**
     * The artowrk URL
     * @type {string}
     * @memberof Giveaway
     */
    artwork_url: string;
    /**
     * The banner image URL
     * @type {string}
     * @memberof Giveaway
     */
    banner_image_url: string;
    /**
     * The mobile banner image URL
     * @type {string}
     * @memberof Giveaway
     */
    mobile_banner_image_url?: string;
    /**
     * The entry page image URL
     * @type {string}
     * @memberof Giveaway
     */
    entry_page_image_url?: string;
    /**
     * The URL to the terms and conditions
     * @type {string}
     * @memberof Giveaway
     */
    terms_pdf_url: string;
    /**
     * The share URL
     * @type {string}
     * @memberof Giveaway
     */
    share_url: string;
    /**
     * The giveaways prizes
     * @type {Array<GiveawayPrize>}
     * @memberof Giveaway
     */
    GiveawayPrizes: Array<GiveawayPrize>;
    /**
     * The giveaways share links, to be shown after entry
     * @type {Array<GiveawayShareLink>}
     * @memberof Giveaway
     */
    GiveawayShareLinks: Array<GiveawayShareLink>;
}

/**
 * @export
 * @namespace Giveaway
 */
export namespace Giveaway {
    /**
     * @export
     * @enum {string}
     */
    export enum PlatformEnum {
        Supreme = <any> 'supreme',
        Create = <any> 'create',
        Sync = <any> 'sync',
        Latino = <any> 'latino'
    }
}
/**
 * 
 * @export
 * @interface GiveawayComment
 */
export interface GiveawayComment {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof GiveawayComment
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof GiveawayComment
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof GiveawayComment
     */
    updated_at: string;
    /**
     * Amount of likes on this comment
     * @type {number}
     * @memberof GiveawayComment
     */
    like_count: number;
    /**
     * The comment
     * @type {string}
     * @memberof GiveawayComment
     */
    comment: string;
    /**
     * If current user liked this comment
     * @type {boolean}
     * @memberof GiveawayComment
     */
    liked: boolean;
    /**
     * 
     * @type {GiveawayCommentUser}
     * @memberof GiveawayComment
     */
    user: GiveawayCommentUser;
}
/**
 * The user who commented
 * @export
 * @interface GiveawayCommentUser
 */
export interface GiveawayCommentUser {
    /**
     * The user id
     * @type {number}
     * @memberof GiveawayCommentUser
     */
    id: number;
    /**
     * The user name
     * @type {string}
     * @memberof GiveawayCommentUser
     */
    username: string;
    /**
     * The user full name
     * @type {string}
     * @memberof GiveawayCommentUser
     */
    full_name: string;
    /**
     * The user profile image thumbnail url
     * @type {string}
     * @memberof GiveawayCommentUser
     */
    profile_image_thumbnail_url?: string;
    /**
     * If the user is verified (show the verified badge)
     * @type {boolean}
     * @memberof GiveawayCommentUser
     */
    chat_verified?: boolean;
}
/**
 * 
 * @export
 * @interface GiveawayEntry
 */
export interface GiveawayEntry {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof GiveawayEntry
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof GiveawayEntry
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof GiveawayEntry
     */
    updated_at: string;
    /**
     * The score
     * @type {number}
     * @memberof GiveawayEntry
     */
    score?: number;
    /**
     * The token to be used to redeem new points for sharing links
     * @type {string}
     * @memberof GiveawayEntry
     */
    share_token: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GiveawayEntry
     */
    share_links_used?: Array<string>;
}
/**
 * 
 * @export
 * @interface GiveawayPrize
 */
export interface GiveawayPrize {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof GiveawayPrize
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof GiveawayPrize
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof GiveawayPrize
     */
    updated_at: string;
    /**
     * The prize title
     * @type {string}
     * @memberof GiveawayPrize
     */
    title: string;
    /**
     * The prize description
     * @type {string}
     * @memberof GiveawayPrize
     */
    description: string;
    /**
     * The prize external link
     * @type {string}
     * @memberof GiveawayPrize
     */
    link: string;
    /**
     * The prize order
     * @type {number}
     * @memberof GiveawayPrize
     */
    order: number;
    /**
     * The prize image URL
     * @type {string}
     * @memberof GiveawayPrize
     */
    image_url: string;
}
/**
 * 
 * @export
 * @interface GiveawayShareLink
 */
export interface GiveawayShareLink {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof GiveawayShareLink
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof GiveawayShareLink
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof GiveawayShareLink
     */
    updated_at: string;
    /**
     * The share link title
     * @type {string}
     * @memberof GiveawayShareLink
     */
    title: string;
    /**
     * The share link slug
     * @type {string}
     * @memberof GiveawayShareLink
     */
    slug: string;
    /**
     * The share link score (to earn when executed)
     * @type {number}
     * @memberof GiveawayShareLink
     */
    score: number;
    /**
     * The share link order
     * @type {number}
     * @memberof GiveawayShareLink
     */
    order: number;
}
/**
 * 
 * @export
 * @interface IdCommentBody
 */
export interface IdCommentBody {
    /**
     * The comment
     * @type {string}
     * @memberof IdCommentBody
     */
    comment: string;
}
/**
 * 
 * @export
 * @interface IdKeyBody
 */
export interface IdKeyBody {
    /**
     * The key returned from the SDK
     * @type {string}
     * @memberof IdKeyBody
     */
    key: string;
    /**
     * The hash returned from the SDK
     * @type {string}
     * @memberof IdKeyBody
     */
    hash: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {InlineResponse200Data}
     * @memberof InlineResponse200
     */
    data: InlineResponse200Data;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {DeviceWithUser}
     * @memberof InlineResponse2001
     */
    data: DeviceWithUser;
}
/**
 * 
 * @export
 * @interface InlineResponse20010
 */
export interface InlineResponse20010 {
    /**
     * 
     * @type {InlineResponse20010Data}
     * @memberof InlineResponse20010
     */
    data: InlineResponse20010Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20010Data
 */
export interface InlineResponse20010Data {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse20010Data
     */
    show: boolean;
}
/**
 * 
 * @export
 * @interface InlineResponse20011
 */
export interface InlineResponse20011 {
    /**
     * 
     * @type {PaymentInstrument}
     * @memberof InlineResponse20011
     */
    data: PaymentInstrument;
}
/**
 * 
 * @export
 * @interface InlineResponse20012
 */
export interface InlineResponse20012 {
    /**
     * 
     * @type {Array<Payment>}
     * @memberof InlineResponse20012
     */
    data: Array<Payment>;
}
/**
 * 
 * @export
 * @interface InlineResponse20013
 */
export interface InlineResponse20013 {
    /**
     * 
     * @type {Array<Device>}
     * @memberof InlineResponse20013
     */
    data: Array<Device>;
}
/**
 * 
 * @export
 * @interface InlineResponse20014
 */
export interface InlineResponse20014 {
    /**
     * 
     * @type {InlineResponse20014Data}
     * @memberof InlineResponse20014
     */
    data: InlineResponse20014Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20014Data
 */
export interface InlineResponse20014Data {
    /**
     * 
     * @type {Array<Device>}
     * @memberof InlineResponse20014Data
     */
    web: Array<Device>;
    /**
     * 
     * @type {Array<Device>}
     * @memberof InlineResponse20014Data
     */
    app: Array<Device>;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20014Data
     */
    web_limit: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20014Data
     */
    app_limit: number;
}
/**
 * 
 * @export
 * @interface InlineResponse20015
 */
export interface InlineResponse20015 {
    /**
     * 
     * @type {InlineResponse20015Data}
     * @memberof InlineResponse20015
     */
    data: InlineResponse20015Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20015Data
 */
export interface InlineResponse20015Data {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20015Data
     */
    dst: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20016
 */
export interface InlineResponse20016 {
    /**
     * 
     * @type {InlineResponse20016Data}
     * @memberof InlineResponse20016
     */
    data: InlineResponse20016Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20016Data
 */
export interface InlineResponse20016Data {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20016Data
     */
    test: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20017
 */
export interface InlineResponse20017 {
    /**
     * 
     * @type {InlineResponse20017Data}
     * @memberof InlineResponse20017
     */
    data: InlineResponse20017Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20017Data
 */
export interface InlineResponse20017Data {
    /**
     * Always true
     * @type {boolean}
     * @memberof InlineResponse20017Data
     */
    success: boolean;
}
/**
 * 
 * @export
 * @interface InlineResponse20018
 */
export interface InlineResponse20018 {
    /**
     * 
     * @type {any}
     * @memberof InlineResponse20018
     */
    data: any;
}
/**
 * 
 * @export
 * @interface InlineResponse20019
 */
export interface InlineResponse20019 {
    /**
     * 
     * @type {Array<BlogArticle>}
     * @memberof InlineResponse20019
     */
    data: Array<BlogArticle>;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {any}
     * @memberof InlineResponse2002
     */
    data: any;
}
/**
 * 
 * @export
 * @interface InlineResponse20020
 */
export interface InlineResponse20020 {
    /**
     * 
     * @type {Array<BlogArticleTag>}
     * @memberof InlineResponse20020
     */
    data: Array<BlogArticleTag>;
}
/**
 * 
 * @export
 * @interface InlineResponse20021
 */
export interface InlineResponse20021 {
    /**
     * 
     * @type {BlogArticle}
     * @memberof InlineResponse20021
     */
    data: BlogArticle;
}
/**
 * 
 * @export
 * @interface InlineResponse20022
 */
export interface InlineResponse20022 {
    /**
     * 
     * @type {InlineResponse20022Data}
     * @memberof InlineResponse20022
     */
    data: InlineResponse20022Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20022Data
 */
export interface InlineResponse20022Data {
    /**
     * 
     * @type {Array<Giveaway>}
     * @memberof InlineResponse20022Data
     */
    current: Array<Giveaway>;
    /**
     * 
     * @type {Array<Giveaway>}
     * @memberof InlineResponse20022Data
     */
    past: Array<Giveaway>;
}
/**
 * 
 * @export
 * @interface InlineResponse20023
 */
export interface InlineResponse20023 {
    /**
     * 
     * @type {Giveaway}
     * @memberof InlineResponse20023
     */
    data: Giveaway;
}
/**
 * 
 * @export
 * @interface InlineResponse20024
 */
export interface InlineResponse20024 {
    /**
     * 
     * @type {Array<GiveawayComment>}
     * @memberof InlineResponse20024
     */
    data: Array<GiveawayComment>;
}
/**
 * 
 * @export
 * @interface InlineResponse20025
 */
export interface InlineResponse20025 {
    /**
     * 
     * @type {GiveawayComment}
     * @memberof InlineResponse20025
     */
    data: GiveawayComment;
}
/**
 * 
 * @export
 * @interface InlineResponse20026
 */
export interface InlineResponse20026 {
    /**
     * 
     * @type {GiveawayEntry}
     * @memberof InlineResponse20026
     */
    data: GiveawayEntry;
}
/**
 * 
 * @export
 * @interface InlineResponse20027
 */
export interface InlineResponse20027 {
    /**
     * 
     * @type {Notification}
     * @memberof InlineResponse20027
     */
    data: Notification;
}
/**
 * 
 * @export
 * @interface InlineResponse20028
 */
export interface InlineResponse20028 {
    /**
     * 
     * @type {InlineResponse20028Data}
     * @memberof InlineResponse20028
     */
    data: InlineResponse20028Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20028Data
 */
export interface InlineResponse20028Data {
    /**
     * 
     * @type {Array<SignUpQuestion>}
     * @memberof InlineResponse20028Data
     */
    questions: Array<SignUpQuestion>;
    /**
     * 
     * @type {SignUpApplication}
     * @memberof InlineResponse20028Data
     */
    application?: SignUpApplication;
}
/**
 * 
 * @export
 * @interface InlineResponse20029
 */
export interface InlineResponse20029 {
    /**
     * 
     * @type {InlineResponse20029Data}
     * @memberof InlineResponse20029
     */
    data: InlineResponse20029Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20029Data
 */
export interface InlineResponse20029Data {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20029Data
     */
    status: InlineResponse20029Data.StatusEnum;
}

/**
 * @export
 * @namespace InlineResponse20029Data
 */
export namespace InlineResponse20029Data {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Pending = <any> 'pending',
        Approved = <any> 'approved',
        Declined = <any> 'declined'
    }
}
/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * 
     * @type {InlineResponse2003Data}
     * @memberof InlineResponse2003
     */
    data: InlineResponse2003Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20030
 */
export interface InlineResponse20030 {
    /**
     * 
     * @type {Array<SubscriptionPackage>}
     * @memberof InlineResponse20030
     */
    data: Array<SubscriptionPackage>;
}
/**
 * 
 * @export
 * @interface InlineResponse20031
 */
export interface InlineResponse20031 {
    /**
     * 
     * @type {SubscriptionPackage}
     * @memberof InlineResponse20031
     */
    data: SubscriptionPackage;
}
/**
 * 
 * @export
 * @interface InlineResponse20032
 */
export interface InlineResponse20032 {
    /**
     * 
     * @type {InlineResponse20032Data}
     * @memberof InlineResponse20032
     */
    data: InlineResponse20032Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20032Data
 */
export interface InlineResponse20032Data {
    /**
     * 
     * @type {CouponUsageCalc}
     * @memberof InlineResponse20032Data
     */
    info: CouponUsageCalc;
}
/**
 * 
 * @export
 * @interface InlineResponse20033
 */
export interface InlineResponse20033 {
    /**
     * 
     * @type {InlineResponse20033Data}
     * @memberof InlineResponse20033
     */
    data: InlineResponse20033Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20033Data
 */
export interface InlineResponse20033Data {
    /**
     * The URl to redirect the current window to.
     * @type {string}
     * @memberof InlineResponse20033Data
     */
    redirect: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20034
 */
export interface InlineResponse20034 {
    /**
     * 
     * @type {SubscriptionPreview}
     * @memberof InlineResponse20034
     */
    data: SubscriptionPreview;
}
/**
 * 
 * @export
 * @interface InlineResponse20035
 */
export interface InlineResponse20035 {
    /**
     * 
     * @type {Subscription}
     * @memberof InlineResponse20035
     */
    data: Subscription;
}
/**
 * 
 * @export
 * @interface InlineResponse20036
 */
export interface InlineResponse20036 {
    /**
     * 
     * @type {StripeSubscriptionResult}
     * @memberof InlineResponse20036
     */
    data: StripeSubscriptionResult;
}
/**
 * 
 * @export
 * @interface InlineResponse20037
 */
export interface InlineResponse20037 {
    /**
     * 
     * @type {ChangeSusbcriptionResult}
     * @memberof InlineResponse20037
     */
    data: ChangeSusbcriptionResult;
}
/**
 * 
 * @export
 * @interface InlineResponse20038
 */
export interface InlineResponse20038 {
    /**
     * 
     * @type {InlineResponse20038Data}
     * @memberof InlineResponse20038
     */
    data: InlineResponse20038Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20038Data
 */
export interface InlineResponse20038Data {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20038Data
     */
    intent: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20039
 */
export interface InlineResponse20039 {
    /**
     * 
     * @type {PaymentInstrumentStripe}
     * @memberof InlineResponse20039
     */
    data: PaymentInstrumentStripe;
}
/**
 * 
 * @export
 * @interface InlineResponse2003Data
 */
export interface InlineResponse2003Data {
    /**
     * 
     * @type {User}
     * @memberof InlineResponse2003Data
     */
    user: User;
}
/**
 * 
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
    /**
     * 
     * @type {User}
     * @memberof InlineResponse2004
     */
    data: User;
}
/**
 * 
 * @export
 * @interface InlineResponse20040
 */
export interface InlineResponse20040 {
    /**
     * 
     * @type {Ad}
     * @memberof InlineResponse20040
     */
    data: Ad;
}
/**
 * 
 * @export
 * @interface InlineResponse20041
 */
export interface InlineResponse20041 {
    /**
     * 
     * @type {InlineResponse20041Data}
     * @memberof InlineResponse20041
     */
    data: InlineResponse20041Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20041Data
 */
export interface InlineResponse20041Data {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse20041Data
     */
    available: boolean;
}
/**
 * 
 * @export
 * @interface InlineResponse20042
 */
export interface InlineResponse20042 {
    /**
     * 
     * @type {Array<InlineResponse20042Data>}
     * @memberof InlineResponse20042
     */
    data: Array<InlineResponse20042Data>;
}
/**
 * 
 * @export
 * @interface InlineResponse20042Data
 */
export interface InlineResponse20042Data {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20042Data
     */
    type: InlineResponse20042Data.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20042Data
     */
    name: string;
}

/**
 * @export
 * @namespace InlineResponse20042Data
 */
export namespace InlineResponse20042Data {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        School = <any> 'School',
        Partner = <any> 'Partner'
    }
}
/**
 * 
 * @export
 * @interface InlineResponse20043
 */
export interface InlineResponse20043 {
    /**
     * 
     * @type {InlineResponse20043Data}
     * @memberof InlineResponse20043
     */
    data: InlineResponse20043Data;
}
/**
 * 
 * @export
 * @interface InlineResponse20043Data
 */
export interface InlineResponse20043Data {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20043Data
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
    /**
     * 
     * @type {GetMeWithSubscription}
     * @memberof InlineResponse2005
     */
    data: GetMeWithSubscription;
}
/**
 * 
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
    /**
     * 
     * @type {InlineResponse2006Data}
     * @memberof InlineResponse2006
     */
    data: InlineResponse2006Data;
}
/**
 * 
 * @export
 * @interface InlineResponse2006Data
 */
export interface InlineResponse2006Data {
    /**
     * 
     * @type {User}
     * @memberof InlineResponse2006Data
     */
    user: User;
    /**
     * 
     * @type {InlineResponse2006DataMembership}
     * @memberof InlineResponse2006Data
     */
    membership: InlineResponse2006DataMembership;
}
/**
 * 
 * @export
 * @interface InlineResponse2006DataMembership
 */
export interface InlineResponse2006DataMembership {
    /**
     * 
     * @type {Membership}
     * @memberof InlineResponse2006DataMembership
     */
    supreme: Membership;
    /**
     * 
     * @type {Membership}
     * @memberof InlineResponse2006DataMembership
     */
    create: Membership;
}
/**
 * 
 * @export
 * @interface InlineResponse2007
 */
export interface InlineResponse2007 {
    /**
     * 
     * @type {InlineResponse2007Data}
     * @memberof InlineResponse2007
     */
    data: InlineResponse2007Data;
}
/**
 * 
 * @export
 * @interface InlineResponse2007Data
 */
export interface InlineResponse2007Data {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2007Data
     */
    url: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2008
 */
export interface InlineResponse2008 {
    /**
     * 
     * @type {UserAddress}
     * @memberof InlineResponse2008
     */
    data: UserAddress;
}
/**
 * 
 * @export
 * @interface InlineResponse2009
 */
export interface InlineResponse2009 {
    /**
     * 
     * @type {Preferences}
     * @memberof InlineResponse2009
     */
    data: Preferences;
}
/**
 * 
 * @export
 * @interface InlineResponse200Data
 */
export interface InlineResponse200Data {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200Data
     */
    message: string;
}
/**
 * 
 * @export
 * @interface InlineResponse201
 */
export interface InlineResponse201 {
    /**
     * 
     * @type {Array<Notification>}
     * @memberof InlineResponse201
     */
    data: Array<Notification>;
}
/**
 * 
 * @export
 * @interface InlineResponse400
 */
export interface InlineResponse400 {
    /**
     * 
     * @type {InvalidParametersError}
     * @memberof InlineResponse400
     */
    error: InvalidParametersError;
}
/**
 * 
 * @export
 * @interface InlineResponse401
 */
export interface InlineResponse401 {
    /**
     * 
     * @type {Error}
     * @memberof InlineResponse401
     */
    error: Error;
}
/**
 * 
 * @export
 * @interface InlineResponse403
 */
export interface InlineResponse403 {
    /**
     * 
     * @type {NotAccessibleError}
     * @memberof InlineResponse403
     */
    error: NotAccessibleError;
}
/**
 * 
 * @export
 * @interface InlineResponse4031
 */
export interface InlineResponse4031 {
    /**
     * 
     * @type {ResourceAlreadyExists}
     * @memberof InlineResponse4031
     */
    error: ResourceAlreadyExists;
}
/**
 * 
 * @export
 * @interface InlineResponse4032
 */
export interface InlineResponse4032 {
    /**
     * 
     * @type {NotVerifiedError}
     * @memberof InlineResponse4032
     */
    error: NotVerifiedError;
}
/**
 * 
 * @export
 * @interface InlineResponse4033
 */
export interface InlineResponse4033 {
    /**
     * 
     * @type {InvalidSubscriptionError}
     * @memberof InlineResponse4033
     */
    error: InvalidSubscriptionError;
}
/**
 * 
 * @export
 * @interface InlineResponse404
 */
export interface InlineResponse404 {
    /**
     * 
     * @type {ResourceNotFoundError}
     * @memberof InlineResponse404
     */
    error: ResourceNotFoundError;
}
/**
 * 
 * @export
 * @interface InternalServerError
 */
export interface InternalServerError {
}
/**
 * 
 * @export
 * @interface InvalidParametersError
 */
export interface InvalidParametersError extends Error {
    /**
     * 
     * @type {Array<string>}
     * @memberof InvalidParametersError
     */
    missing_keys: Array<string>;
    /**
     * object format: [invalid_key]: 'message'
     * @type {any}
     * @memberof InvalidParametersError
     */
    invalid_keys?: any;
}
/**
 * 
 * @export
 * @interface InvalidSubscriptionError
 */
export interface InvalidSubscriptionError extends Error {
    /**
     * 0 = None, 1 = Standard, 2 = Premium
     * @type {number}
     * @memberof InvalidSubscriptionError
     */
    current_supscription?: InvalidSubscriptionError.CurrentSupscriptionEnum;
    /**
     * 0 = None, 1 = Standard, 2 = Premium
     * @type {number}
     * @memberof InvalidSubscriptionError
     */
    required_subscription?: InvalidSubscriptionError.RequiredSubscriptionEnum;
}

/**
 * @export
 * @namespace InvalidSubscriptionError
 */
export namespace InvalidSubscriptionError {
    /**
     * @export
     * @enum {string}
     */
    export enum CurrentSupscriptionEnum {
        NUMBER_0 = <any> 0,
        NUMBER_1 = <any> 1,
        NUMBER_2 = <any> 2
    }
    /**
     * @export
     * @enum {string}
     */
    export enum RequiredSubscriptionEnum {
        NUMBER_0 = <any> 0,
        NUMBER_1 = <any> 1,
        NUMBER_2 = <any> 2
    }
}
/**
 * 
 * @export
 * @interface KeyExpiredError
 */
export interface KeyExpiredError {
}
/**
 * 
 * @export
 * @interface LoginBody
 */
export interface LoginBody {
    /**
     * 
     * @type {string}
     * @memberof LoginBody
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LoginBody
     */
    password: string;
    /**
     * Like web, app, ...
     * @type {string}
     * @memberof LoginBody
     */
    from?: string;
    /**
     * 
     * @type {CreateDevice}
     * @memberof LoginBody
     */
    device: CreateDevice;
}
/**
 * 
 * @export
 * @interface MarketingSubscribeBody
 */
export interface MarketingSubscribeBody {
    /**
     * The campaign name
     * @type {string}
     * @memberof MarketingSubscribeBody
     */
    type: string;
    /**
     * The full name. If provided, must be in format first-name last-name (both required)
     * @type {string}
     * @memberof MarketingSubscribeBody
     */
    name?: string;
    /**
     * The email address
     * @type {string}
     * @memberof MarketingSubscribeBody
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingSubscribeBody
     */
    platform: MarketingSubscribeBody.PlatformEnum;
}

/**
 * @export
 * @namespace MarketingSubscribeBody
 */
export namespace MarketingSubscribeBody {
    /**
     * @export
     * @enum {string}
     */
    export enum PlatformEnum {
        Supreme = <any> 'supreme',
        Create = <any> 'create',
        Latino = <any> 'latino',
        Stream = <any> 'stream',
        Sync = <any> 'sync'
    }
}
/**
 * 
 * @export
 * @interface MeChangePasswordBody
 */
export interface MeChangePasswordBody {
    /**
     * 
     * @type {string}
     * @memberof MeChangePasswordBody
     */
    old_password: string;
    /**
     * 
     * @type {string}
     * @memberof MeChangePasswordBody
     */
    new_password: string;
}
/**
 * 
 * @export
 * @interface MePictureBody
 */
export interface MePictureBody {
    /**
     * The Image file
     * @type {Blob}
     * @memberof MePictureBody
     */
    image?: Blob;
}
/**
 * 
 * @export
 * @interface MeThemeBody
 */
export interface MeThemeBody {
    /**
     * The users current theme (black/white)
     * @type {string}
     * @memberof MeThemeBody
     */
    theme?: MeThemeBody.ThemeEnum;
}

/**
 * @export
 * @namespace MeThemeBody
 */
export namespace MeThemeBody {
    /**
     * @export
     * @enum {string}
     */
    export enum ThemeEnum {
        Black = <any> 'black',
        White = <any> 'white'
    }
}
/**
 * 
 * @export
 * @interface Membership
 */
export interface Membership {
    /**
     * 
     * @type {boolean}
     * @memberof Membership
     */
    has_membership: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Membership
     */
    trial_applicable: boolean;
    /**
     * 
     * @type {Array<Feature>}
     * @memberof Membership
     */
    available_features: Array<Feature>;
    /**
     * 
     * @type {number}
     * @memberof Membership
     */
    failed_payment_package_id?: number;
    /**
     * 
     * @type {SubscriptionDetailsv4}
     * @memberof Membership
     */
    subscription?: SubscriptionDetailsv4;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * Error Type
     * @type {string}
     * @memberof ModelError
     */
    type: string;
    /**
     * The HTTP Code
     * @type {number}
     * @memberof ModelError
     */
    code: number;
    /**
     * Message. Can be displayed to the user (not localized)
     * @type {string}
     * @memberof ModelError
     */
    message: string;
}
/**
 * 
 * @export
 * @interface NotAccessibleError
 */
export interface NotAccessibleError {
}
/**
 * 
 * @export
 * @interface NotVerifiedError
 */
export interface NotVerifiedError extends Error {
    /**
     * 
     * @type {boolean}
     * @memberof NotVerifiedError
     */
    is_declined: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotVerifiedError
     */
    submission_pending: boolean;
}
/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof Notification
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Notification
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Notification
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    title: string;
    /**
     * Relative or absolute URL
     * @type {string}
     * @memberof Notification
     */
    link?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    body?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    associated_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    associated_type: string;
    /**
     * 
     * @type {NotificationTypeIdentifier}
     * @memberof Notification
     */
    type_identifier: NotificationTypeIdentifier;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    user_id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Notification
     */
    read: boolean;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    platform: Notification.PlatformEnum;
}

/**
 * @export
 * @namespace Notification
 */
export namespace Notification {
    /**
     * @export
     * @enum {string}
     */
    export enum PlatformEnum {
        Stream = <any> 'stream',
        Download = <any> 'download',
        Create = <any> 'create',
        Artist = <any> 'artist'
    }
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum NotificationTypeIdentifier {
    NewArtistRelease = <any> 'NewArtistRelease',
    NewPlaylistUpdate = <any> 'NewPlaylistUpdate',
    NewRecommendation = <any> 'NewRecommendation',
    NewLabelRelease = <any> 'NewLabelRelease',
    ApplicationApproved = <any> 'ApplicationApproved',
    CrateDownloadReady = <any> 'CrateDownloadReady',
    InvitedToUserPlaylist = <any> 'InvitedToUserPlaylist',
    InvitedToDrive = <any> 'InvitedToDrive',
    AcceptedCollaborationInvite = <any> 'AcceptedCollaborationInvite',
    CollaborationUpdate = <any> 'CollaborationUpdate',
    CollaborationEnded = <any> 'CollaborationEnded'
}
/**
 * 
 * @export
 * @interface NotificationsBody
 */
export interface NotificationsBody {
    /**
     * 
     * @type {string}
     * @memberof NotificationsBody
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationsBody
     */
    body?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationsBody
     */
    image?: string;
    /**
     * 
     * @type {NotificationTypeIdentifier}
     * @memberof NotificationsBody
     */
    type_identifier?: NotificationTypeIdentifier;
    /**
     * 
     * @type {string}
     * @memberof NotificationsBody
     */
    platform?: NotificationsBody.PlatformEnum;
}

/**
 * @export
 * @namespace NotificationsBody
 */
export namespace NotificationsBody {
    /**
     * @export
     * @enum {string}
     */
    export enum PlatformEnum {
        Stream = <any> 'stream',
        Download = <any> 'download',
        Create = <any> 'create',
        Artist = <any> 'artist'
    }
}
/**
 * If card is not provided, default one for user is used.
 * @export
 * @interface PackageIdCreditcardBody
 */
export interface PackageIdCreditcardBody {
    /**
     * Name of the coupon
     * @type {string}
     * @memberof PackageIdCreditcardBody
     */
    coupon?: string;
    /**
     * 
     * @type {string}
     * @memberof PackageIdCreditcardBody
     */
    pan?: string;
    /**
     * Length min 1, max 2
     * @type {string}
     * @memberof PackageIdCreditcardBody
     */
    expMonth?: string;
    /**
     * Length min 2, max 4
     * @type {string}
     * @memberof PackageIdCreditcardBody
     */
    expYear?: string;
    /**
     * Length min 2, max 4
     * @type {string}
     * @memberof PackageIdCreditcardBody
     */
    cvv?: string;
    /**
     * From which page
     * @type {string}
     * @memberof PackageIdCreditcardBody
     */
    from: string;
}
/**
 * 
 * @export
 * @interface PackageIdPaypalBody
 */
export interface PackageIdPaypalBody {
    /**
     * Name of the coupon
     * @type {string}
     * @memberof PackageIdPaypalBody
     */
    coupon?: string;
    /**
     * The URL to redirect to after operation is completed. The APi will append /success or /failure to the URL
     * @type {string}
     * @memberof PackageIdPaypalBody
     */
    redirect_base: string;
    /**
     * From which page
     * @type {string}
     * @memberof PackageIdPaypalBody
     */
    from: string;
}
/**
 * 
 * @export
 * @interface PackageIdPreviewBody
 */
export interface PackageIdPreviewBody {
    /**
     * Name of the coupon
     * @type {string}
     * @memberof PackageIdPreviewBody
     */
    coupon?: string;
}
/**
 * 
 * @export
 * @interface ParentalcontrolDisableexplicitcontentBody
 */
export interface ParentalcontrolDisableexplicitcontentBody {
    /**
     * The 4 digit code.
     * @type {string}
     * @memberof ParentalcontrolDisableexplicitcontentBody
     */
    code?: string;
}
/**
 * 
 * @export
 * @interface ParentalcontrolEnableexplicitcontentBody
 */
export interface ParentalcontrolEnableexplicitcontentBody {
    /**
     * The 4 digit code.
     * @type {string}
     * @memberof ParentalcontrolEnableexplicitcontentBody
     */
    code?: string;
}
/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    status: Payment.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    paid_amount: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    method: Payment.MethodEnum;
    /**
     * `a` is Auto renewal, `b` is one time payment
     * @type {string}
     * @memberof Payment
     */
    mode: Payment.ModeEnum;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    expire_date: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    plan_name: Payment.PlanNameEnum;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    period: number;
    /**
     * If subscriptions is set to transition to another package, this will show the package name.
     * @type {string}
     * @memberof Payment
     */
    transition?: string;
}

/**
 * @export
 * @namespace Payment
 */
export namespace Payment {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Ongoing = <any> 'ongoing',
        Cancelled = <any> 'cancelled',
        Pending = <any> 'pending'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum MethodEnum {
        Paypal = <any> 'paypal',
        Authorize = <any> 'authorize',
        Stripe = <any> 'stripe'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum ModeEnum {
        A = <any> 'a',
        B = <any> 'b'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum PlanNameEnum {
        Standard = <any> 'Standard',
        Premium = <any> 'Premium',
        PremiumPlus = <any> 'PremiumPlus'
    }
}
/**
 * 
 * @export
 * @interface PaymentInstrument
 */
export interface PaymentInstrument {
}
/**
 * 
 * @export
 * @interface PaymentInstrumentAuthorize
 */
export interface PaymentInstrumentAuthorize {
    /**
     * 
     * @type {string}
     * @memberof PaymentInstrumentAuthorize
     */
    method: PaymentInstrumentAuthorize.MethodEnum;
    /**
     * 
     * @type {PaymentInstrumentAuthorizeCard}
     * @memberof PaymentInstrumentAuthorize
     */
    card?: PaymentInstrumentAuthorizeCard;
}

/**
 * @export
 * @namespace PaymentInstrumentAuthorize
 */
export namespace PaymentInstrumentAuthorize {
    /**
     * @export
     * @enum {string}
     */
    export enum MethodEnum {
        Authorize = <any> 'authorize'
    }
}
/**
 * 
 * @export
 * @interface PaymentInstrumentAuthorizeCard
 */
export interface PaymentInstrumentAuthorizeCard {
    /**
     * 
     * @type {string}
     * @memberof PaymentInstrumentAuthorizeCard
     */
    cardNumber: string;
}
/**
 * 
 * @export
 * @interface PaymentInstrumentPaypal
 */
export interface PaymentInstrumentPaypal {
    /**
     * 
     * @type {string}
     * @memberof PaymentInstrumentPaypal
     */
    method: PaymentInstrumentPaypal.MethodEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentInstrumentPaypal
     */
    email?: string;
}

/**
 * @export
 * @namespace PaymentInstrumentPaypal
 */
export namespace PaymentInstrumentPaypal {
    /**
     * @export
     * @enum {string}
     */
    export enum MethodEnum {
        Paypal = <any> 'paypal'
    }
}
/**
 * 
 * @export
 * @interface PaymentInstrumentStripe
 */
export interface PaymentInstrumentStripe {
    /**
     * 
     * @type {string}
     * @memberof PaymentInstrumentStripe
     */
    method: PaymentInstrumentStripe.MethodEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentInstrumentStripe
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentInstrumentStripe
     */
    id: string;
}

/**
 * @export
 * @namespace PaymentInstrumentStripe
 */
export namespace PaymentInstrumentStripe {
    /**
     * @export
     * @enum {string}
     */
    export enum MethodEnum {
        Stripe = <any> 'stripe'
    }
}
/**
 * 
 * @export
 * @interface PaymentInstrumentSystem
 */
export interface PaymentInstrumentSystem {
    /**
     * 
     * @type {string}
     * @memberof PaymentInstrumentSystem
     */
    method: PaymentInstrumentSystem.MethodEnum;
}

/**
 * @export
 * @namespace PaymentInstrumentSystem
 */
export namespace PaymentInstrumentSystem {
    /**
     * @export
     * @enum {string}
     */
    export enum MethodEnum {
        System = <any> 'system'
    }
}
/**
 * 
 * @export
 * @interface Preferences
 */
export interface Preferences {
    /**
     * 
     * @type {boolean}
     * @memberof Preferences
     */
    promotional: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Preferences
     */
    weekly_newsletter: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Preferences
     */
    contest_and_giveaways: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Preferences
     */
    event_workshop_invites: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Preferences
     */
    recommendations: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Preferences
     */
    artist_updates: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Preferences
     */
    collection_updates: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Preferences
     */
    app_recommendations: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Preferences
     */
    create_label_updates: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Preferences
     */
    create_recommendations: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Preferences
     */
    ap_app_competition: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Preferences
     */
    ap_app_playlist: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Preferences
     */
    ap_app_promotion: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Preferences
     */
    ap_app_submission: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Preferences
     */
    ap_email_competition: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Preferences
     */
    ap_email_playlist: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Preferences
     */
    ap_email_promotion: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Preferences
     */
    ap_email_submission: boolean;
}
/**
 * 
 * @export
 * @interface PromotionSignUpBody
 */
export interface PromotionSignUpBody {
    /**
     * The access code
     * @type {string}
     * @memberof PromotionSignUpBody
     */
    access_code: string;
    /**
     * The partner name
     * @type {string}
     * @memberof PromotionSignUpBody
     */
    partner: string;
    /**
     * The selected group
     * @type {string}
     * @memberof PromotionSignUpBody
     */
    group: PromotionSignUpBody.GroupEnum;
    /**
     * Billing address city
     * @type {string}
     * @memberof PromotionSignUpBody
     */
    city: string;
    /**
     * Billing address state
     * @type {string}
     * @memberof PromotionSignUpBody
     */
    state?: string;
    /**
     * Billing address company
     * @type {string}
     * @memberof PromotionSignUpBody
     */
    company?: string;
    /**
     * Billing address line 2
     * @type {string}
     * @memberof PromotionSignUpBody
     */
    line2?: string;
    /**
     * Billing address country
     * @type {string}
     * @memberof PromotionSignUpBody
     */
    country: string;
    /**
     * Billing address line 1
     * @type {string}
     * @memberof PromotionSignUpBody
     */
    line1: string;
    /**
     * Billing address zip
     * @type {string}
     * @memberof PromotionSignUpBody
     */
    zip: string;
    /**
     * Billing address first name
     * @type {string}
     * @memberof PromotionSignUpBody
     */
    first_name: string;
    /**
     * Billing address last name
     * @type {string}
     * @memberof PromotionSignUpBody
     */
    last_name: string;
    /**
     * Credit card name
     * @type {string}
     * @memberof PromotionSignUpBody
     */
    cardname: string;
    /**
     * Credit card cvv
     * @type {string}
     * @memberof PromotionSignUpBody
     */
    cvv: string;
    /**
     * Credit card expiration in format month/year
     * @type {string}
     * @memberof PromotionSignUpBody
     */
    expiration: string;
    /**
     * Credit card pan
     * @type {string}
     * @memberof PromotionSignUpBody
     */
    pan: string;
    /**
     * 
     * @type {CreateDevice}
     * @memberof PromotionSignUpBody
     */
    device: CreateDevice;
    /**
     * The email
     * @type {string}
     * @memberof PromotionSignUpBody
     */
    email: string;
    /**
     * The password
     * @type {string}
     * @memberof PromotionSignUpBody
     */
    password: string;
}

/**
 * @export
 * @namespace PromotionSignUpBody
 */
export namespace PromotionSignUpBody {
    /**
     * @export
     * @enum {string}
     */
    export enum GroupEnum {
        School = <any> 'School',
        Partner = <any> 'Partner'
    }
}
/**
 * 
 * @export
 * @interface ResetpasswordBody
 */
export interface ResetpasswordBody {
    /**
     * The reset token
     * @type {string}
     * @memberof ResetpasswordBody
     */
    code: string;
    /**
     * The password. Minimum length of 6
     * @type {string}
     * @memberof ResetpasswordBody
     */
    password: string;
}
/**
 * 
 * @export
 * @interface ResourceAlreadyExists
 */
export interface ResourceAlreadyExists extends Error {
    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceAlreadyExists
     */
    existing_keys?: Array<string>;
}
/**
 * 
 * @export
 * @interface ResourceNotFoundError
 */
export interface ResourceNotFoundError extends Error {
    /**
     * The resource type
     * @type {string}
     * @memberof ResourceNotFoundError
     */
    resource_type?: string;
}
/**
 * 
 * @export
 * @interface SessionNotFoundError
 */
export interface SessionNotFoundError {
}
/**
 * 
 * @export
 * @interface SetupintentMethodBody
 */
export interface SetupintentMethodBody {
    /**
     * 
     * @type {string}
     * @memberof SetupintentMethodBody
     */
    setup_intent_id?: string;
}
/**
 * 
 * @export
 * @interface SignUpApplication
 */
export interface SignUpApplication {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof SignUpApplication
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof SignUpApplication
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof SignUpApplication
     */
    updated_at: string;
    /**
     * Status of the application
     * @type {string}
     * @memberof SignUpApplication
     */
    status: SignUpApplication.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SignUpApplication
     */
    updated_by?: string;
    /**
     * Answers given by user
     * @type {Array<SignUpQuestionAnswer>}
     * @memberof SignUpApplication
     */
    SignUpQuestionAnswers: Array<SignUpQuestionAnswer>;
}

/**
 * @export
 * @namespace SignUpApplication
 */
export namespace SignUpApplication {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Pending = <any> 'pending',
        Approved = <any> 'approved',
        Declined = <any> 'declined'
    }
}
/**
 * A object of id: string | File. If an previously existing answer has been edited, prefix the 'id' with 'existing-'.
 * @export
 * @interface SignUpApplicationSubmit
 */
export interface SignUpApplicationSubmit {
}
/**
 * 
 * @export
 * @interface SignUpExistingBody
 */
export interface SignUpExistingBody {
    /**
     * The access code
     * @type {string}
     * @memberof SignUpExistingBody
     */
    access_code: string;
    /**
     * The partner name
     * @type {string}
     * @memberof SignUpExistingBody
     */
    partner: string;
    /**
     * The selected group
     * @type {string}
     * @memberof SignUpExistingBody
     */
    group: SignUpExistingBody.GroupEnum;
    /**
     * Billing address city
     * @type {string}
     * @memberof SignUpExistingBody
     */
    city: string;
    /**
     * Billing address state
     * @type {string}
     * @memberof SignUpExistingBody
     */
    state?: string;
    /**
     * Billing address company
     * @type {string}
     * @memberof SignUpExistingBody
     */
    company?: string;
    /**
     * Billing address country
     * @type {string}
     * @memberof SignUpExistingBody
     */
    country: string;
    /**
     * Billing address line 1
     * @type {string}
     * @memberof SignUpExistingBody
     */
    line1: string;
    /**
     * Billing address line 2
     * @type {string}
     * @memberof SignUpExistingBody
     */
    line2?: string;
    /**
     * Billing address zip
     * @type {string}
     * @memberof SignUpExistingBody
     */
    zip: string;
    /**
     * Billing address first name
     * @type {string}
     * @memberof SignUpExistingBody
     */
    first_name: string;
    /**
     * Billing address last name
     * @type {string}
     * @memberof SignUpExistingBody
     */
    last_name: string;
    /**
     * Credit card name
     * @type {string}
     * @memberof SignUpExistingBody
     */
    cardname: string;
    /**
     * Credit card cvv
     * @type {string}
     * @memberof SignUpExistingBody
     */
    cvv: string;
    /**
     * Credit card expiration in format month/year
     * @type {string}
     * @memberof SignUpExistingBody
     */
    expiration: string;
    /**
     * Credit card pan
     * @type {string}
     * @memberof SignUpExistingBody
     */
    pan: string;
}

/**
 * @export
 * @namespace SignUpExistingBody
 */
export namespace SignUpExistingBody {
    /**
     * @export
     * @enum {string}
     */
    export enum GroupEnum {
        School = <any> 'School',
        Partner = <any> 'Partner'
    }
}
/**
 * 
 * @export
 * @interface SignUpQuestion
 */
export interface SignUpQuestion {
    /**
     * Unique Entity Id
     * @type {string}
     * @memberof SignUpQuestion
     */
    id: string;
    /**
     * Entity creation date
     * @type {string}
     * @memberof SignUpQuestion
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof SignUpQuestion
     */
    updated_at: string;
    /**
     * The question to show
     * @type {string}
     * @memberof SignUpQuestion
     */
    question: string;
    /**
     * Type of question
     * @type {string}
     * @memberof SignUpQuestion
     */
    type: SignUpQuestion.TypeEnum;
    /**
     * Position, asc
     * @type {number}
     * @memberof SignUpQuestion
     */
    position: number;
    /**
     * Placeholder for input
     * @type {string}
     * @memberof SignUpQuestion
     */
    placeholder: string;
}

/**
 * @export
 * @namespace SignUpQuestion
 */
export namespace SignUpQuestion {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Text = <any> 'text',
        ShortText = <any> 'short_text',
        File = <any> 'file',
        Link = <any> 'link',
        Email = <any> 'email',
        Social = <any> 'social'
    }
}
/**
 * 
 * @export
 * @interface SignUpQuestionAnswer
 */
export interface SignUpQuestionAnswer {
    /**
     * Entity creation date
     * @type {string}
     * @memberof SignUpQuestionAnswer
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof SignUpQuestionAnswer
     */
    updated_at: string;
    /**
     * Users answer given
     * @type {string}
     * @memberof SignUpQuestionAnswer
     */
    answer: string;
    /**
     * Answer status
     * @type {string}
     * @memberof SignUpQuestionAnswer
     */
    status: SignUpQuestionAnswer.StatusEnum;
    /**
     * Reason answer was delcined (if status == 'declined')
     * @type {string}
     * @memberof SignUpQuestionAnswer
     */
    declined_reason?: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpQuestionAnswer
     */
    updated_by?: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpQuestionAnswer
     */
    application_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpQuestionAnswer
     */
    question_id?: string;
    /**
     * User choosen position
     * @type {number}
     * @memberof SignUpQuestionAnswer
     */
    position: number;
}

/**
 * @export
 * @namespace SignUpQuestionAnswer
 */
export namespace SignUpQuestionAnswer {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Pending = <any> 'pending',
        Approved = <any> 'approved',
        Declined = <any> 'declined'
    }
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum StreamFeature {
    OfflineCrate = <any> 'offline_crate',
    Playlist = <any> 'playlist',
    UserPlaylist = <any> 'user_playlist',
    CuratedSet = <any> 'curated_set'
}
/**
 * 
 * @export
 * @interface StripeSubscriptionResult
 */
export interface StripeSubscriptionResult {
    /**
     * 
     * @type {Subscription}
     * @memberof StripeSubscriptionResult
     */
    subscription: Subscription;
    /**
     * 
     * @type {boolean}
     * @memberof StripeSubscriptionResult
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionResult
     */
    payment_client_secret?: string;
}
/**
 * 
 * @export
 * @interface SubscribePackageIdBody
 */
export interface SubscribePackageIdBody {
    /**
     * Name of the coupon
     * @type {string}
     * @memberof SubscribePackageIdBody
     */
    coupon?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscribePackageIdBody
     */
    payment_method_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscribePackageIdBody
     */
    invoice_page?: string;
    /**
     * From which page
     * @type {string}
     * @memberof SubscribePackageIdBody
     */
    from: string;
}
/**
 * 
 * @export
 * @interface Subscription
 */
export interface Subscription {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof Subscription
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof Subscription
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof Subscription
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    plan_name: Subscription.PlanNameEnum;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    plan_period: number;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    status: Subscription.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    method: Subscription.MethodEnum;
    /**
     * `a` is Auto renewal, `b` is one time payment
     * @type {string}
     * @memberof Subscription
     */
    mode: Subscription.ModeEnum;
    /**
     * Also next renewal date in case of still ongoing subscription
     * @type {string}
     * @memberof Subscription
     */
    expire_date: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    platform: Subscription.PlatformEnum;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    package_id: number;
}

/**
 * @export
 * @namespace Subscription
 */
export namespace Subscription {
    /**
     * @export
     * @enum {string}
     */
    export enum PlanNameEnum {
        Standard = <any> 'Standard',
        Premium = <any> 'Premium',
        PremiumPlus = <any> 'PremiumPlus'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Ongoing = <any> 'ongoing',
        Cancelled = <any> 'cancelled',
        Pending = <any> 'pending'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum MethodEnum {
        Paypal = <any> 'paypal',
        Authorize = <any> 'authorize',
        Stripe = <any> 'stripe'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum ModeEnum {
        A = <any> 'a',
        B = <any> 'b'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum PlatformEnum {
        Supreme = <any> 'supreme',
        Stream = <any> 'stream',
        Create = <any> 'create'
    }
}
/**
 * 
 * @export
 * @interface SubscriptionDetailsv4
 */
export interface SubscriptionDetailsv4 {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDetailsv4
     */
    start_date: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDetailsv4
     */
    membership_type: SubscriptionDetailsv4.MembershipTypeEnum;
    /**
     * 
     * @type {SubscriptionInfo}
     * @memberof SubscriptionDetailsv4
     */
    membership_info: SubscriptionInfo;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDetailsv4
     */
    package_id: number;
    /**
     * 
     * @type {SubscriptionPackage}
     * @memberof SubscriptionDetailsv4
     */
    _package?: SubscriptionPackage;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDetailsv4
     */
    renewal_interval: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDetailsv4
     */
    next_billing?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDetailsv4
     */
    expire_date: string;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionDetailsv4
     */
    require_payment_method_change: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionDetailsv4
     */
    show_price_increase_modal: boolean;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDetailsv4
     */
    platform: SubscriptionDetailsv4.PlatformEnum;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDetailsv4
     */
    library?: SubscriptionDetailsv4.LibraryEnum;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDetailsv4
     */
    status: SubscriptionDetailsv4.StatusEnum;
}

/**
 * @export
 * @namespace SubscriptionDetailsv4
 */
export namespace SubscriptionDetailsv4 {
    /**
     * @export
     * @enum {string}
     */
    export enum MembershipTypeEnum {
        Standard = <any> 'Standard',
        Premium = <any> 'Premium',
        PremiumPlus = <any> 'PremiumPlus'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum PlatformEnum {
        Supreme = <any> 'supreme',
        Create = <any> 'create',
        Stream = <any> 'stream',
        Sync = <any> 'sync'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum LibraryEnum {
        Latin = <any> 'latin',
        All = <any> 'all',
        Music = <any> 'music'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Cancelled = <any> 'cancelled',
        Pending = <any> 'pending',
        Ongoing = <any> 'ongoing'
    }
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SubscriptionInfo {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SubscriptionMode {
    ChangeLib = <any> 'change-lib',
    New = <any> 'new',
    Upgrade = <any> 'upgrade',
    Downgrade = <any> 'downgrade',
    ChangePaymentInstrument = <any> 'change-payment-instrument'
}
/**
 * 
 * @export
 * @interface SubscriptionPackage
 */
export interface SubscriptionPackage {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof SubscriptionPackage
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof SubscriptionPackage
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof SubscriptionPackage
     */
    updated_at: string;
    /**
     * Subscription Level
     * @type {string}
     * @memberof SubscriptionPackage
     */
    name: SubscriptionPackage.NameEnum;
    /**
     * Pricing for renewal
     * @type {string}
     * @memberof SubscriptionPackage
     */
    amount: string;
    /**
     * Subscription period for renewals in months
     * @type {number}
     * @memberof SubscriptionPackage
     */
    period: SubscriptionPackage.PeriodEnum;
    /**
     * Trial amount
     * @type {string}
     * @memberof SubscriptionPackage
     */
    trial_amount?: string;
    /**
     * Trial period in months
     * @type {number}
     * @memberof SubscriptionPackage
     */
    trial_period: SubscriptionPackage.TrialPeriodEnum;
    /**
     * Package type
     * @type {string}
     * @memberof SubscriptionPackage
     */
    method: SubscriptionPackage.MethodEnum;
    /**
     * Amount of credits this package will add, if platform is credit based
     * @type {number}
     * @memberof SubscriptionPackage
     */
    amount_of_credits?: number;
    /**
     * Amount of promo credits this package will add, if platform is credit based
     * @type {number}
     * @memberof SubscriptionPackage
     */
    amount_of_promo_credits?: number;
    /**
     * Instructions, monstly used for API
     * @type {string}
     * @memberof SubscriptionPackage
     */
    instructions?: string;
    /**
     * The library, if platform has library specifications
     * @type {string}
     * @memberof SubscriptionPackage
     */
    library?: SubscriptionPackage.LibraryEnum;
    /**
     * The platform for this package. Supreme represents download
     * @type {string}
     * @memberof SubscriptionPackage
     */
    platform: SubscriptionPackage.PlatformEnum;
    /**
     * Package description
     * @type {string}
     * @memberof SubscriptionPackage
     */
    description?: string;
    /**
     * 
     * @type {SubscriptionPackageInfo}
     * @memberof SubscriptionPackage
     */
    info?: SubscriptionPackageInfo;
    /**
     * Promotion text for promoted packages
     * @type {string}
     * @memberof SubscriptionPackage
     */
    promotion_text?: string;
    /**
     * If users is currently subscribed to this package
     * @type {boolean}
     * @memberof SubscriptionPackage
     */
    is_current: boolean;
    /**
     * If this package is a default package
     * @type {boolean}
     * @memberof SubscriptionPackage
     */
    is_default: boolean;
    /**
     * If this package is a promotion package
     * @type {boolean}
     * @memberof SubscriptionPackage
     */
    is_promotion: boolean;
}

/**
 * @export
 * @namespace SubscriptionPackage
 */
export namespace SubscriptionPackage {
    /**
     * @export
     * @enum {string}
     */
    export enum NameEnum {
        Premium = <any> 'Premium',
        Standard = <any> 'Standard',
        PremiumPlus = <any> 'PremiumPlus'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum PeriodEnum {
        NUMBER_1 = <any> 1,
        NUMBER_3 = <any> 3,
        NUMBER_6 = <any> 6,
        NUMBER_12 = <any> 12,
        NUMBER_0 = <any> 0
    }
    /**
     * @export
     * @enum {string}
     */
    export enum TrialPeriodEnum {
        NUMBER_1 = <any> 1,
        NUMBER_3 = <any> 3,
        NUMBER_6 = <any> 6,
        NUMBER_12 = <any> 12,
        NUMBER_0 = <any> 0
    }
    /**
     * @export
     * @enum {string}
     */
    export enum MethodEnum {
        Recurring = <any> 'recurring',
        OneTime = <any> 'one-time'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum LibraryEnum {
        Music = <any> 'music',
        Latin = <any> 'latin',
        All = <any> 'all'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum PlatformEnum {
        Supreme = <any> 'supreme',
        Stream = <any> 'stream',
        Create = <any> 'create',
        Sync = <any> 'sync'
    }
}
/**
 * Subscription info
 * @export
 * @interface SubscriptionPackageInfo
 */
export interface SubscriptionPackageInfo {
    /**
     * Is trial allowed for the current user
     * @type {boolean}
     * @memberof SubscriptionPackageInfo
     */
    trial_allowed: boolean;
    /**
     * The discount applied due to the trial
     * @type {string}
     * @memberof SubscriptionPackageInfo
     */
    trial_discount?: string;
    /**
     * The amount charged right now (only set when promoted)
     * @type {string}
     * @memberof SubscriptionPackageInfo
     */
    promoted_amount?: string;
    /**
     * If this package should be highlighted/promoted in the UI due to a cheaper rater (see `promoted_amount`)
     * @type {boolean}
     * @memberof SubscriptionPackageInfo
     */
    promoted: boolean;
    /**
     * Set to false when this package is not allowed right now
     * @type {boolean}
     * @memberof SubscriptionPackageInfo
     */
    allowed: boolean;
}
/**
 * 
 * @export
 * @interface SubscriptionPreview
 */
export interface SubscriptionPreview {
    /**
     * If trial is allowed
     * @type {boolean}
     * @memberof SubscriptionPreview
     */
    trial_allowed: boolean;
    /**
     * Amount charged right now
     * @type {string}
     * @memberof SubscriptionPreview
     */
    charged_now_amount: string;
    /**
     * 
     * @type {CouponUsageSchedule}
     * @memberof SubscriptionPreview
     */
    schedule?: CouponUsageSchedule;
    /**
     * The renewal amount of the package
     * @type {string}
     * @memberof SubscriptionPreview
     */
    renewal_amount: string;
    /**
     * If the subscription starts now (downgrades are delayed). Check starts_on if false
     * @type {boolean}
     * @memberof SubscriptionPreview
     */
    starts_now: boolean;
    /**
     * When does this subscription starts (downgrades are delayed). Use this date if starts_now is false
     * @type {string}
     * @memberof SubscriptionPreview
     */
    starts_on: string;
    /**
     * When the subscription renews. If mode is 'one-time', disregard this value!
     * @type {string}
     * @memberof SubscriptionPreview
     */
    renews_on: string;
    /**
     * 
     * @type {SubscriptionMode}
     * @memberof SubscriptionPreview
     */
    mode: SubscriptionMode;
    /**
     * If user has to confirm this change, show this message
     * @type {string}
     * @memberof SubscriptionPreview
     */
    confirm_message?: string;
    /**
     * 
     * @type {CouponUsageCalc}
     * @memberof SubscriptionPreview
     */
    coupon_info?: CouponUsageCalc;
}
/**
 * 
 * @export
 * @interface SupremeLibraryBody
 */
export interface SupremeLibraryBody {
    /**
     * The users current selected library
     * @type {string}
     * @memberof SupremeLibraryBody
     */
    library?: SupremeLibraryBody.LibraryEnum;
}

/**
 * @export
 * @namespace SupremeLibraryBody
 */
export namespace SupremeLibraryBody {
    /**
     * @export
     * @enum {string}
     */
    export enum LibraryEnum {
        Music = <any> 'music',
        Artist = <any> 'artist',
        Latin = <any> 'latin'
    }
}
/**
 * 
 * @export
 * @interface TrackClick
 */
export interface TrackClick {
    /**
     * 
     * @type {string}
     * @memberof TrackClick
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof TrackClick
     */
    type: TrackClick.TypeEnum;
    /**
     * Entity, if specific entity is viewed (like category or playlist)
     * @type {string}
     * @memberof TrackClick
     */
    entity_name?: string;
    /**
     * Entity id, if specific entity is viewed (like playlist id)
     * @type {string}
     * @memberof TrackClick
     */
    entity_id?: string;
    /**
     * Current path
     * @type {string}
     * @memberof TrackClick
     */
    from: string;
    /**
     * Additional meta data about the event
     * @type {any}
     * @memberof TrackClick
     */
    meta?: any;
}

/**
 * @export
 * @namespace TrackClick
 */
export namespace TrackClick {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Click = <any> 'click'
    }
}
/**
 * 
 * @export
 * @interface TrackGeneric
 */
export interface TrackGeneric {
    /**
     * 
     * @type {string}
     * @memberof TrackGeneric
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof TrackGeneric
     */
    type: TrackGeneric.TypeEnum;
    /**
     * The event to track (like \"add_to_favorite\")
     * @type {string}
     * @memberof TrackGeneric
     */
    event: string;
    /**
     * Current path
     * @type {string}
     * @memberof TrackGeneric
     */
    from: string;
    /**
     * Referrer
     * @type {string}
     * @memberof TrackGeneric
     */
    referrer: string;
    /**
     * Additional meta data about the event
     * @type {any}
     * @memberof TrackGeneric
     */
    meta?: any;
}

/**
 * @export
 * @namespace TrackGeneric
 */
export namespace TrackGeneric {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Generic = <any> 'generic'
    }
}
/**
 * 
 * @export
 * @interface TrackSession
 */
export interface TrackSession {
    /**
     * 
     * @type {string}
     * @memberof TrackSession
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof TrackSession
     */
    type: TrackSession.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TrackSession
     */
    session_type: TrackSession.SessionTypeEnum;
    /**
     * Current path
     * @type {string}
     * @memberof TrackSession
     */
    from: string;
    /**
     * 
     * @type {number}
     * @memberof TrackSession
     */
    screen_width: number;
    /**
     * 
     * @type {number}
     * @memberof TrackSession
     */
    screen_height: number;
    /**
     * User agent
     * @type {string}
     * @memberof TrackSession
     */
    agent: string;
}

/**
 * @export
 * @namespace TrackSession
 */
export namespace TrackSession {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Session = <any> 'session'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum SessionTypeEnum {
        End = <any> 'end',
        Start = <any> 'start'
    }
}
/**
 * 
 * @export
 * @interface TrackView
 */
export interface TrackView {
    /**
     * 
     * @type {string}
     * @memberof TrackView
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof TrackView
     */
    type: TrackView.TypeEnum;
    /**
     * Entity, if specific entity is viewed (like category or playlist)
     * @type {string}
     * @memberof TrackView
     */
    entity_name?: string;
    /**
     * Entity id, if specific entity is viewed (like playlist id)
     * @type {string}
     * @memberof TrackView
     */
    entity_id?: string;
    /**
     * Current path
     * @type {string}
     * @memberof TrackView
     */
    from: string;
    /**
     * Referrer
     * @type {string}
     * @memberof TrackView
     */
    referrer: string;
}

/**
 * @export
 * @namespace TrackView
 */
export namespace TrackView {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        View = <any> 'view'
    }
}
/**
 * 
 * @export
 * @interface UpdateDevice
 */
export interface UpdateDevice {
    /**
     * Set this to true if you want the response to include a delivery_iv
     * @type {boolean}
     * @memberof UpdateDevice
     */
    request_iv?: boolean;
    /**
     * Unique identifier of the application
     * @type {string}
     * @memberof UpdateDevice
     */
    app_type: string;
    /**
     * Current version of the application
     * @type {string}
     * @memberof UpdateDevice
     */
    app_version: string;
    /**
     * Current build version of the application
     * @type {string}
     * @memberof UpdateDevice
     */
    build_version: string;
    /**
     * Push token, if available
     * @type {string}
     * @memberof UpdateDevice
     */
    device_token?: string;
    /**
     * The token provider
     * @type {string}
     * @memberof UpdateDevice
     */
    token_provider?: UpdateDevice.TokenProviderEnum;
    /**
     * The applications os (like web, mac os, ios, android). Note: If device_data_os == 'web', the api will automatic set session cookies.
     * @type {string}
     * @memberof UpdateDevice
     */
    device_data_os?: string;
    /**
     * Version of the applications os
     * @type {string}
     * @memberof UpdateDevice
     */
    device_data_os_version?: string;
    /**
     * For example iPhone 7, iPhone X
     * @type {string}
     * @memberof UpdateDevice
     */
    device_data_device_type?: string;
    /**
     * Given name of the device, like Alexander´s iPhone
     * @type {string}
     * @memberof UpdateDevice
     */
    device_data_device_name?: string;
    /**
     * For example Phone, Computer, Tablet, ...
     * @type {string}
     * @memberof UpdateDevice
     */
    device_data_device_category?: string;
    /**
     * Set to true if debug session. Note: Under iOS, this will also indicate the sandbox push environment for the API.
     * @type {boolean}
     * @memberof UpdateDevice
     */
    debug: boolean;
    /**
     * Device language code, like en-US
     * @type {string}
     * @memberof UpdateDevice
     */
    language: string;
    /**
     * The current device badge. Only applicable to devices with a app badge functionality.
     * @type {number}
     * @memberof UpdateDevice
     */
    badge?: number;
}

/**
 * @export
 * @namespace UpdateDevice
 */
export namespace UpdateDevice {
    /**
     * @export
     * @enum {string}
     */
    export enum TokenProviderEnum {
        Apple = <any> 'apple',
        Google = <any> 'google',
        Web = <any> 'web'
    }
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * Unique Entity Id
     * @type {number}
     * @memberof User
     */
    id: number;
    /**
     * Entity creation date
     * @type {string}
     * @memberof User
     */
    created_at: string;
    /**
     * Entity last updated date
     * @type {string}
     * @memberof User
     */
    updated_at: string;
    /**
     * The users first name
     * @type {string}
     * @memberof User
     */
    first_name: string;
    /**
     * The users last name
     * @type {string}
     * @memberof User
     */
    last_name: string;
    /**
     * The users full name
     * @type {string}
     * @memberof User
     */
    full_name: string;
    /**
     * The users email
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * The users current theme (black/white)
     * @type {string}
     * @memberof User
     */
    theme: User.ThemeEnum;
    /**
     * The DJ type
     * @type {string}
     * @memberof User
     */
    dj_type?: string;
    /**
     * The T-Shirt size
     * @type {string}
     * @memberof User
     */
    tshirt_size?: string;
    /**
     * The dob, in date format eg ``
     * @type {string}
     * @memberof User
     */
    dob?: string;
    /**
     * The users profile image thumbnail url
     * @type {string}
     * @memberof User
     */
    profile_image_thumbnail_url: string;
    /**
     * The users profile image url
     * @type {string}
     * @memberof User
     */
    profile_image_url: string;
    /**
     * If the user is a admin or not
     * @type {boolean}
     * @memberof User
     */
    is_admin: boolean;
    /**
     * The users user name
     * @type {string}
     * @memberof User
     */
    username: string;
    /**
     * The users DJ verification status
     * @type {string}
     * @memberof User
     */
    verification_status: User.VerificationStatusEnum;
    /**
     * If the user is a beta tester or not
     * @type {boolean}
     * @memberof User
     */
    beta_tester: boolean;
    /**
     * Is explicit content deactivated per PC?
     * @type {boolean}
     * @memberof User
     */
    disable_explicit_content: boolean;
    /**
     * Users last selected library
     * @type {string}
     * @memberof User
     */
    last_supreme_library?: User.LastSupremeLibraryEnum;
    /**
     * 
     * @type {UserAdminFeatures}
     * @memberof User
     */
    admin_features?: UserAdminFeatures;
}

/**
 * @export
 * @namespace User
 */
export namespace User {
    /**
     * @export
     * @enum {string}
     */
    export enum ThemeEnum {
        Black = <any> 'black',
        White = <any> 'white'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum VerificationStatusEnum {
        Verified = <any> 'verified',
        Pending = <any> 'pending',
        Declined = <any> 'declined',
        NotSubmitted = <any> 'not-submitted'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum LastSupremeLibraryEnum {
        Music = <any> 'music',
        Artist = <any> 'artist',
        Latin = <any> 'latin'
    }
}
/**
 * 
 * @export
 * @interface UserAddress
 */
export interface UserAddress {
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    line1?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    line2?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    zip?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    phone?: string;
}
/**
 * 
 * @export
 * @interface UserAdminFeatures
 */
export interface UserAdminFeatures {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserAdminFeatures
     */
    features?: Array<string>;
}
/**
 * 
 * @export
 * @interface UserSubscribeBody
 */
export interface UserSubscribeBody {
    /**
     * The campaign name
     * @type {string}
     * @memberof UserSubscribeBody
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscribeBody
     */
    platform: UserSubscribeBody.PlatformEnum;
}

/**
 * @export
 * @namespace UserSubscribeBody
 */
export namespace UserSubscribeBody {
    /**
     * @export
     * @enum {string}
     */
    export enum PlatformEnum {
        Supreme = <any> 'supreme',
        Create = <any> 'create',
        Latino = <any> 'latino',
        Stream = <any> 'stream',
        Sync = <any> 'sync'
    }
}
/**
 * 
 * @export
 * @interface UsersBody
 */
export interface UsersBody {
    /**
     * Note: Usernames are also accepted
     * @type {string}
     * @memberof UsersBody
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UsersBody
     */
    password: string;
    /**
     * Like web, app, ...
     * @type {string}
     * @memberof UsersBody
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof UsersBody
     */
    phone?: string;
    /**
     * Full name required
     * @type {string}
     * @memberof UsersBody
     */
    name: string;
    /**
     * Google reCAPTCHA token
     * @type {string}
     * @memberof UsersBody
     */
    token?: string;
    /**
     * 
     * @type {CreateDevice}
     * @memberof UsersBody
     */
    device: CreateDevice;
}
/**
 * 
 * @export
 * @interface UsersMeBody
 */
export interface UsersMeBody {
    /**
     * If updated, a new confirmation email will be sent
     * @type {string}
     * @memberof UsersMeBody
     */
    email?: string;
    /**
     * The users user name
     * @type {string}
     * @memberof UsersMeBody
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersMeBody
     */
    tshirt_size?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersMeBody
     */
    dj_type?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersMeBody
     */
    dob?: string;
}
/**
 * 
 * @export
 * @interface VerifyPlatformBody
 */
export interface VerifyPlatformBody {
    /**
     * The code
     * @type {string}
     * @memberof VerifyPlatformBody
     */
    code: string;
    /**
     * The device name
     * @type {string}
     * @memberof VerifyPlatformBody
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface WebRedirectBody
 */
export interface WebRedirectBody {
    /**
     * 
     * @type {string}
     * @memberof WebRedirectBody
     */
    path?: WebRedirectBody.PathEnum;
}

/**
 * @export
 * @namespace WebRedirectBody
 */
export namespace WebRedirectBody {
    /**
     * @export
     * @enum {string}
     */
    export enum PathEnum {
        AccountmembershipInfo = <any> 'account/membership-info',
        AccountdownloadHistory = <any> 'account/download-history',
        Browse = <any> 'browse'
    }
}
/**
 * AdsApi - fetch parameter creator
 * @export
 */
export const AdsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get current ad
         * @param {string} platform 
         * @param {string} [library] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAd(platform: string, library?: string, options: any = {}): FetchArgs {
            // verify required parameter 'platform' is not null or undefined
            if (platform === null || platform === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling getAd.');
            }
            const localVarPath = `/ad`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (library !== undefined) {
                localVarQueryParameter['library'] = library;
            }

            if (platform !== undefined) {
                localVarQueryParameter['platform'] = platform;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Report Ad Activated
         * @param {string} id The ad id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportAdActivated(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling reportAdActivated.');
            }
            const localVarPath = `/ad/{id}/activated`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdsApi - functional programming interface
 * @export
 */
export const AdsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get current ad
         * @param {string} platform 
         * @param {string} [library] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAd(platform: string, library?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20040> {
            const localVarFetchArgs = AdsApiFetchParamCreator(configuration).getAd(platform, library, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Report Ad Activated
         * @param {string} id The ad id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportAdActivated(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = AdsApiFetchParamCreator(configuration).reportAdActivated(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AdsApi - factory interface
 * @export
 */
export const AdsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get current ad
         * @param {string} platform 
         * @param {string} [library] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAd(platform: string, library?: string, options?: any) {
            return AdsApiFp(configuration).getAd(platform, library, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Report Ad Activated
         * @param {string} id The ad id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportAdActivated(id: string, options?: any) {
            return AdsApiFp(configuration).reportAdActivated(id, options)(fetch, basePath);
        },
    };
};

/**
 * AdsApi - interface
 * @export
 * @interface AdsApi
 */
export interface AdsApiInterface {
    /**
     * 
     * @summary Get current ad
     * @param {string} platform 
     * @param {string} [library] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdsApiInterface
     */
    getAd(platform: string, library?: string, options?: any): Promise<InlineResponse20040>;

    /**
     * 
     * @summary Report Ad Activated
     * @param {string} id The ad id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdsApiInterface
     */
    reportAdActivated(id: string, options?: any): Promise<InlineResponse2002>;

}

/**
 * AdsApi - object-oriented interface
 * @export
 * @class AdsApi
 * @extends {BaseAPI}
 */
export class AdsApi extends BaseAPI implements AdsApiInterface {
    /**
     * 
     * @summary Get current ad
     * @param {string} platform 
     * @param {string} [library] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdsApi
     */
    public getAd(platform: string, library?: string, options?: any) {
        return AdsApiFp(this.configuration).getAd(platform, library, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Report Ad Activated
     * @param {string} id The ad id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdsApi
     */
    public reportAdActivated(id: string, options?: any) {
        return AdsApiFp(this.configuration).reportAdActivated(id, options)(this.fetch, this.basePath);
    }

}
/**
 * AnalyticsApi - fetch parameter creator
 * @export
 */
export const AnalyticsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Track Analytics events
         * @param {string} platform The platform to load the packages for. Supreme is Download.
         * @param {AnalyticsTrack} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackAnalytics(platform: string, body?: AnalyticsTrack, options: any = {}): FetchArgs {
            // verify required parameter 'platform' is not null or undefined
            if (platform === null || platform === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling trackAnalytics.');
            }
            const localVarPath = `/analytics/track/{platform}`
                .replace(`{${'platform'}}`, encodeURIComponent(String(platform)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'text/plain;charset=UTF-8';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'AnalyticsTrack' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalyticsApi - functional programming interface
 * @export
 */
export const AnalyticsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Track Analytics events
         * @param {string} platform The platform to load the packages for. Supreme is Download.
         * @param {AnalyticsTrack} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackAnalytics(platform: string, body?: AnalyticsTrack, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = AnalyticsApiFetchParamCreator(configuration).trackAnalytics(platform, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AnalyticsApi - factory interface
 * @export
 */
export const AnalyticsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Track Analytics events
         * @param {string} platform The platform to load the packages for. Supreme is Download.
         * @param {AnalyticsTrack} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackAnalytics(platform: string, body?: AnalyticsTrack, options?: any) {
            return AnalyticsApiFp(configuration).trackAnalytics(platform, body, options)(fetch, basePath);
        },
    };
};

/**
 * AnalyticsApi - interface
 * @export
 * @interface AnalyticsApi
 */
export interface AnalyticsApiInterface {
    /**
     * 
     * @summary Track Analytics events
     * @param {string} platform The platform to load the packages for. Supreme is Download.
     * @param {AnalyticsTrack} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApiInterface
     */
    trackAnalytics(platform: string, body?: AnalyticsTrack, options?: any): Promise<InlineResponse2002>;

}

/**
 * AnalyticsApi - object-oriented interface
 * @export
 * @class AnalyticsApi
 * @extends {BaseAPI}
 */
export class AnalyticsApi extends BaseAPI implements AnalyticsApiInterface {
    /**
     * 
     * @summary Track Analytics events
     * @param {string} platform The platform to load the packages for. Supreme is Download.
     * @param {AnalyticsTrack} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public trackAnalytics(platform: string, body?: AnalyticsTrack, options?: any) {
        return AnalyticsApiFp(this.configuration).trackAnalytics(platform, body, options)(this.fetch, this.basePath);
    }

}
/**
 * DJVerificationApi - fetch parameter creator
 * @export
 */
export const DJVerificationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Questions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerificationQuestions(options: any = {}): FetchArgs {
            const localVarPath = `/dj-verification/questions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Re-Submit Application
         * @param {string} id The application id
         * @param {SignUpApplicationSubmit} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resubmitVerificationQuestions(id: string, body?: SignUpApplicationSubmit, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling resubmitVerificationQuestions.');
            }
            const localVarPath = `/dj-verification/application/submit/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'SignUpApplicationSubmit' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit Application
         * @param {SignUpApplicationSubmit} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitVerificationQuestions(body?: SignUpApplicationSubmit, options: any = {}): FetchArgs {
            const localVarPath = `/dj-verification/application/submit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'SignUpApplicationSubmit' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DJVerificationApi - functional programming interface
 * @export
 */
export const DJVerificationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Questions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerificationQuestions(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20028> {
            const localVarFetchArgs = DJVerificationApiFetchParamCreator(configuration).getVerificationQuestions(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Re-Submit Application
         * @param {string} id The application id
         * @param {SignUpApplicationSubmit} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resubmitVerificationQuestions(id: string, body?: SignUpApplicationSubmit, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20029> {
            const localVarFetchArgs = DJVerificationApiFetchParamCreator(configuration).resubmitVerificationQuestions(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Submit Application
         * @param {SignUpApplicationSubmit} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitVerificationQuestions(body?: SignUpApplicationSubmit, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20029> {
            const localVarFetchArgs = DJVerificationApiFetchParamCreator(configuration).submitVerificationQuestions(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DJVerificationApi - factory interface
 * @export
 */
export const DJVerificationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get Questions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerificationQuestions(options?: any) {
            return DJVerificationApiFp(configuration).getVerificationQuestions(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Re-Submit Application
         * @param {string} id The application id
         * @param {SignUpApplicationSubmit} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resubmitVerificationQuestions(id: string, body?: SignUpApplicationSubmit, options?: any) {
            return DJVerificationApiFp(configuration).resubmitVerificationQuestions(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Submit Application
         * @param {SignUpApplicationSubmit} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitVerificationQuestions(body?: SignUpApplicationSubmit, options?: any) {
            return DJVerificationApiFp(configuration).submitVerificationQuestions(body, options)(fetch, basePath);
        },
    };
};

/**
 * DJVerificationApi - interface
 * @export
 * @interface DJVerificationApi
 */
export interface DJVerificationApiInterface {
    /**
     * 
     * @summary Get Questions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DJVerificationApiInterface
     */
    getVerificationQuestions(options?: any): Promise<InlineResponse20028>;

    /**
     * 
     * @summary Re-Submit Application
     * @param {string} id The application id
     * @param {SignUpApplicationSubmit} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DJVerificationApiInterface
     */
    resubmitVerificationQuestions(id: string, body?: SignUpApplicationSubmit, options?: any): Promise<InlineResponse20029>;

    /**
     * 
     * @summary Submit Application
     * @param {SignUpApplicationSubmit} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DJVerificationApiInterface
     */
    submitVerificationQuestions(body?: SignUpApplicationSubmit, options?: any): Promise<InlineResponse20029>;

}

/**
 * DJVerificationApi - object-oriented interface
 * @export
 * @class DJVerificationApi
 * @extends {BaseAPI}
 */
export class DJVerificationApi extends BaseAPI implements DJVerificationApiInterface {
    /**
     * 
     * @summary Get Questions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DJVerificationApi
     */
    public getVerificationQuestions(options?: any) {
        return DJVerificationApiFp(this.configuration).getVerificationQuestions(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Re-Submit Application
     * @param {string} id The application id
     * @param {SignUpApplicationSubmit} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DJVerificationApi
     */
    public resubmitVerificationQuestions(id: string, body?: SignUpApplicationSubmit, options?: any) {
        return DJVerificationApiFp(this.configuration).resubmitVerificationQuestions(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Submit Application
     * @param {SignUpApplicationSubmit} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DJVerificationApi
     */
    public submitVerificationQuestions(body?: SignUpApplicationSubmit, options?: any) {
        return DJVerificationApiFp(this.configuration).submitVerificationQuestions(body, options)(this.fetch, this.basePath);
    }

}
/**
 * DeviceApi - fetch parameter creator
 * @export
 */
export const DeviceApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Need to request a delivery IV before, use one of our SDK to create a key pair, and send the key and hash to this endpoint.
         * @summary Create device key
         * @param {string} id The device id. Note: This is the BPM generated id, NOT the device_uuid!
         * @param {IdKeyBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeviceKey(id: string, body?: IdKeyBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createDeviceKey.');
            }
            const localVarPath = `/devices/{id}/key`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'IdKeyBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * If the current device is deleted, this will also invalidate the current session!
         * @summary Delete a Device by Id
         * @param {string} id The device id
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeviceById(id: string, platform: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteDeviceById.');
            }
            // verify required parameter 'platform' is not null or undefined
            if (platform === null || platform === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling deleteDeviceById.');
            }
            const localVarPath = `/devices/id/{id}/{platform}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'platform'}}`, encodeURIComponent(String(platform)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Authorized Devices
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorizedDevices(platform: string, options: any = {}): FetchArgs {
            // verify required parameter 'platform' is not null or undefined
            if (platform === null || platform === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling getAuthorizedDevices.');
            }
            const localVarPath = `/devices/authorized/{platform}`
                .replace(`{${'platform'}}`, encodeURIComponent(String(platform)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Device DST
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceDst(options: any = {}): FetchArgs {
            const localVarPath = `/device/dst`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevices(options: any = {}): FetchArgs {
            const localVarPath = `/devices`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get key check test string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeyCheckString(options: any = {}): FetchArgs {
            const localVarPath = `/devices/check-key`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request Device Auth Code
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestDeviceAuthCode(platform: string, options: any = {}): FetchArgs {
            // verify required parameter 'platform' is not null or undefined
            if (platform === null || platform === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling requestDeviceAuthCode.');
            }
            const localVarPath = `/devices/authorize/request/{platform}`
                .replace(`{${'platform'}}`, encodeURIComponent(String(platform)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update or create Device
         * @param {string} device_uuid The unique device id. On web you can use fingerpintjs2 to create one
         * @param {UpdateDevice} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDevice(device_uuid: string, body?: UpdateDevice, options: any = {}): FetchArgs {
            // verify required parameter 'device_uuid' is not null or undefined
            if (device_uuid === null || device_uuid === undefined) {
                throw new RequiredError('device_uuid','Required parameter device_uuid was null or undefined when calling updateDevice.');
            }
            const localVarPath = `/devices/{device_uuid}`
                .replace(`{${'device_uuid'}}`, encodeURIComponent(String(device_uuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'UpdateDevice' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Device name
         * @param {string} device_uuid The unique device id. On web you can use fingerpintjs2 to create one
         * @param {DeviceUuidNameBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeviceName(device_uuid: string, body?: DeviceUuidNameBody, options: any = {}): FetchArgs {
            // verify required parameter 'device_uuid' is not null or undefined
            if (device_uuid === null || device_uuid === undefined) {
                throw new RequiredError('device_uuid','Required parameter device_uuid was null or undefined when calling updateDeviceName.');
            }
            const localVarPath = `/devices/{device_uuid}/name`
                .replace(`{${'device_uuid'}}`, encodeURIComponent(String(device_uuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'DeviceUuidNameBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify Device Auth Code
         * @param {string} platform 
         * @param {VerifyPlatformBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyDeviceAuthCode(platform: string, body?: VerifyPlatformBody, options: any = {}): FetchArgs {
            // verify required parameter 'platform' is not null or undefined
            if (platform === null || platform === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling verifyDeviceAuthCode.');
            }
            const localVarPath = `/devices/authorize/verify/{platform}`
                .replace(`{${'platform'}}`, encodeURIComponent(String(platform)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'VerifyPlatformBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceApi - functional programming interface
 * @export
 */
export const DeviceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Need to request a delivery IV before, use one of our SDK to create a key pair, and send the key and hash to this endpoint.
         * @summary Create device key
         * @param {string} id The device id. Note: This is the BPM generated id, NOT the device_uuid!
         * @param {IdKeyBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeviceKey(id: string, body?: IdKeyBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20017> {
            const localVarFetchArgs = DeviceApiFetchParamCreator(configuration).createDeviceKey(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * If the current device is deleted, this will also invalidate the current session!
         * @summary Delete a Device by Id
         * @param {string} id The device id
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeviceById(id: string, platform: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = DeviceApiFetchParamCreator(configuration).deleteDeviceById(id, platform, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Authorized Devices
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorizedDevices(platform: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20014> {
            const localVarFetchArgs = DeviceApiFetchParamCreator(configuration).getAuthorizedDevices(platform, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Device DST
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceDst(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20015> {
            const localVarFetchArgs = DeviceApiFetchParamCreator(configuration).getDeviceDst(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevices(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20013> {
            const localVarFetchArgs = DeviceApiFetchParamCreator(configuration).getDevices(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get key check test string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeyCheckString(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20016> {
            const localVarFetchArgs = DeviceApiFetchParamCreator(configuration).getKeyCheckString(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Request Device Auth Code
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestDeviceAuthCode(platform: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20018> {
            const localVarFetchArgs = DeviceApiFetchParamCreator(configuration).requestDeviceAuthCode(platform, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update or create Device
         * @param {string} device_uuid The unique device id. On web you can use fingerpintjs2 to create one
         * @param {UpdateDevice} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDevice(device_uuid: string, body?: UpdateDevice, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2001> {
            const localVarFetchArgs = DeviceApiFetchParamCreator(configuration).updateDevice(device_uuid, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update Device name
         * @param {string} device_uuid The unique device id. On web you can use fingerpintjs2 to create one
         * @param {DeviceUuidNameBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeviceName(device_uuid: string, body?: DeviceUuidNameBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2001> {
            const localVarFetchArgs = DeviceApiFetchParamCreator(configuration).updateDeviceName(device_uuid, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Verify Device Auth Code
         * @param {string} platform 
         * @param {VerifyPlatformBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyDeviceAuthCode(platform: string, body?: VerifyPlatformBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20018> {
            const localVarFetchArgs = DeviceApiFetchParamCreator(configuration).verifyDeviceAuthCode(platform, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DeviceApi - factory interface
 * @export
 */
export const DeviceApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Need to request a delivery IV before, use one of our SDK to create a key pair, and send the key and hash to this endpoint.
         * @summary Create device key
         * @param {string} id The device id. Note: This is the BPM generated id, NOT the device_uuid!
         * @param {IdKeyBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeviceKey(id: string, body?: IdKeyBody, options?: any) {
            return DeviceApiFp(configuration).createDeviceKey(id, body, options)(fetch, basePath);
        },
        /**
         * If the current device is deleted, this will also invalidate the current session!
         * @summary Delete a Device by Id
         * @param {string} id The device id
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeviceById(id: string, platform: string, options?: any) {
            return DeviceApiFp(configuration).deleteDeviceById(id, platform, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Authorized Devices
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorizedDevices(platform: string, options?: any) {
            return DeviceApiFp(configuration).getAuthorizedDevices(platform, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Device DST
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceDst(options?: any) {
            return DeviceApiFp(configuration).getDeviceDst(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevices(options?: any) {
            return DeviceApiFp(configuration).getDevices(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get key check test string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeyCheckString(options?: any) {
            return DeviceApiFp(configuration).getKeyCheckString(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Request Device Auth Code
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestDeviceAuthCode(platform: string, options?: any) {
            return DeviceApiFp(configuration).requestDeviceAuthCode(platform, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update or create Device
         * @param {string} device_uuid The unique device id. On web you can use fingerpintjs2 to create one
         * @param {UpdateDevice} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDevice(device_uuid: string, body?: UpdateDevice, options?: any) {
            return DeviceApiFp(configuration).updateDevice(device_uuid, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update Device name
         * @param {string} device_uuid The unique device id. On web you can use fingerpintjs2 to create one
         * @param {DeviceUuidNameBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeviceName(device_uuid: string, body?: DeviceUuidNameBody, options?: any) {
            return DeviceApiFp(configuration).updateDeviceName(device_uuid, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Verify Device Auth Code
         * @param {string} platform 
         * @param {VerifyPlatformBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyDeviceAuthCode(platform: string, body?: VerifyPlatformBody, options?: any) {
            return DeviceApiFp(configuration).verifyDeviceAuthCode(platform, body, options)(fetch, basePath);
        },
    };
};

/**
 * DeviceApi - interface
 * @export
 * @interface DeviceApi
 */
export interface DeviceApiInterface {
    /**
     * Need to request a delivery IV before, use one of our SDK to create a key pair, and send the key and hash to this endpoint.
     * @summary Create device key
     * @param {string} id The device id. Note: This is the BPM generated id, NOT the device_uuid!
     * @param {IdKeyBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    createDeviceKey(id: string, body?: IdKeyBody, options?: any): Promise<InlineResponse20017>;

    /**
     * If the current device is deleted, this will also invalidate the current session!
     * @summary Delete a Device by Id
     * @param {string} id The device id
     * @param {string} platform 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    deleteDeviceById(id: string, platform: string, options?: any): Promise<InlineResponse2002>;

    /**
     * 
     * @summary Get Authorized Devices
     * @param {string} platform 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    getAuthorizedDevices(platform: string, options?: any): Promise<InlineResponse20014>;

    /**
     * 
     * @summary Get Device DST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    getDeviceDst(options?: any): Promise<InlineResponse20015>;

    /**
     * 
     * @summary Get Devices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    getDevices(options?: any): Promise<InlineResponse20013>;

    /**
     * 
     * @summary Get key check test string
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    getKeyCheckString(options?: any): Promise<InlineResponse20016>;

    /**
     * 
     * @summary Request Device Auth Code
     * @param {string} platform 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    requestDeviceAuthCode(platform: string, options?: any): Promise<InlineResponse20018>;

    /**
     * 
     * @summary Update or create Device
     * @param {string} device_uuid The unique device id. On web you can use fingerpintjs2 to create one
     * @param {UpdateDevice} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    updateDevice(device_uuid: string, body?: UpdateDevice, options?: any): Promise<InlineResponse2001>;

    /**
     * 
     * @summary Update Device name
     * @param {string} device_uuid The unique device id. On web you can use fingerpintjs2 to create one
     * @param {DeviceUuidNameBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    updateDeviceName(device_uuid: string, body?: DeviceUuidNameBody, options?: any): Promise<InlineResponse2001>;

    /**
     * 
     * @summary Verify Device Auth Code
     * @param {string} platform 
     * @param {VerifyPlatformBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    verifyDeviceAuthCode(platform: string, body?: VerifyPlatformBody, options?: any): Promise<InlineResponse20018>;

}

/**
 * DeviceApi - object-oriented interface
 * @export
 * @class DeviceApi
 * @extends {BaseAPI}
 */
export class DeviceApi extends BaseAPI implements DeviceApiInterface {
    /**
     * Need to request a delivery IV before, use one of our SDK to create a key pair, and send the key and hash to this endpoint.
     * @summary Create device key
     * @param {string} id The device id. Note: This is the BPM generated id, NOT the device_uuid!
     * @param {IdKeyBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public createDeviceKey(id: string, body?: IdKeyBody, options?: any) {
        return DeviceApiFp(this.configuration).createDeviceKey(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * If the current device is deleted, this will also invalidate the current session!
     * @summary Delete a Device by Id
     * @param {string} id The device id
     * @param {string} platform 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deleteDeviceById(id: string, platform: string, options?: any) {
        return DeviceApiFp(this.configuration).deleteDeviceById(id, platform, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Authorized Devices
     * @param {string} platform 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public getAuthorizedDevices(platform: string, options?: any) {
        return DeviceApiFp(this.configuration).getAuthorizedDevices(platform, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Device DST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public getDeviceDst(options?: any) {
        return DeviceApiFp(this.configuration).getDeviceDst(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Devices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public getDevices(options?: any) {
        return DeviceApiFp(this.configuration).getDevices(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get key check test string
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public getKeyCheckString(options?: any) {
        return DeviceApiFp(this.configuration).getKeyCheckString(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Request Device Auth Code
     * @param {string} platform 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public requestDeviceAuthCode(platform: string, options?: any) {
        return DeviceApiFp(this.configuration).requestDeviceAuthCode(platform, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update or create Device
     * @param {string} device_uuid The unique device id. On web you can use fingerpintjs2 to create one
     * @param {UpdateDevice} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public updateDevice(device_uuid: string, body?: UpdateDevice, options?: any) {
        return DeviceApiFp(this.configuration).updateDevice(device_uuid, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update Device name
     * @param {string} device_uuid The unique device id. On web you can use fingerpintjs2 to create one
     * @param {DeviceUuidNameBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public updateDeviceName(device_uuid: string, body?: DeviceUuidNameBody, options?: any) {
        return DeviceApiFp(this.configuration).updateDeviceName(device_uuid, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Verify Device Auth Code
     * @param {string} platform 
     * @param {VerifyPlatformBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public verifyDeviceAuthCode(platform: string, body?: VerifyPlatformBody, options?: any) {
        return DeviceApiFp(this.configuration).verifyDeviceAuthCode(platform, body, options)(this.fetch, this.basePath);
    }

}
/**
 * GiveawayApi - fetch parameter creator
 * @export
 */
export const GiveawayApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete giveaway comment
         * @param {string} id The comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGiveawayComent(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteGiveawayComent.');
            }
            const localVarPath = `/giveaway/comments/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit giveaway comment
         * @param {string} id The comment id
         * @param {CommentsIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editGiveawayComent(id: string, body?: CommentsIdBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling editGiveawayComent.');
            }
            const localVarPath = `/giveaway/comments/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'CommentsIdBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enter the giveaway when logged in
         * @summary Enter the giveaway (existing user)
         * @param {string} id The giveaway id
         * @param {EnterExistingBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enterGiveaway(id: string, body?: EnterExistingBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling enterGiveaway.');
            }
            const localVarPath = `/giveaway/{id}/enter/existing`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'EnterExistingBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enter giveaway as new user. No password required, a temporary password will be sent via email. In case of 'device_data_os' = 'web', session cookie will be set.
         * @summary Enter the giveaway (new user)
         * @param {string} id The giveaway id
         * @param {EnterNewBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enterGiveawayNew(id: string, body?: EnterNewBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling enterGiveawayNew.');
            }
            const localVarPath = `/giveaway/{id}/enter/new`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'EnterNewBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Giveaway by slug
         * @param {string} slug The giveaway slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiveawayBySlug(slug: string, options: any = {}): FetchArgs {
            // verify required parameter 'slug' is not null or undefined
            if (slug === null || slug === undefined) {
                throw new RequiredError('slug','Required parameter slug was null or undefined when calling getGiveawayBySlug.');
            }
            const localVarPath = `/giveaways/bySlug/{slug}`
                .replace(`{${'slug'}}`, encodeURIComponent(String(slug)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the comments of the giveaway
         * @param {string} id The giveaway id
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiveawayComents(id: string, limit?: number, skip?: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getGiveawayComents.');
            }
            const localVarPath = `/giveaway/{id}/comments`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get giveaway entry if exists
         * @param {string} id The giveaway id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiveawayEntry(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getGiveawayEntry.');
            }
            const localVarPath = `/giveaway/{id}/entry`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all Giveaways
         * @param {string} [platform] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiveaways(platform?: string, options: any = {}): FetchArgs {
            const localVarPath = `/giveaways/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (platform !== undefined) {
                localVarQueryParameter['platform'] = platform;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Like giveaway comment
         * @param {string} id The comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeGiveawayComent(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling likeGiveawayComent.');
            }
            const localVarPath = `/giveaway/comments/{id}/like`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Comment the giveaway
         * @param {string} id The giveaway id
         * @param {IdCommentBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postGiveawayComent(id: string, body?: IdCommentBody, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling postGiveawayComent.');
            }
            const localVarPath = `/giveaway/{id}/comment`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'IdCommentBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Will add the share link points to the users entry, and redirect to the entry link
         * @summary Open giveaway share link
         * @param {string} slug The slug of the giveaway share link
         * @param {string} token The token of the giveaway entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareGiveawayLink(slug: string, token: string, options: any = {}): FetchArgs {
            // verify required parameter 'slug' is not null or undefined
            if (slug === null || slug === undefined) {
                throw new RequiredError('slug','Required parameter slug was null or undefined when calling shareGiveawayLink.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling shareGiveawayLink.');
            }
            const localVarPath = `/giveaway/{slug}/{token}/entry`
                .replace(`{${'slug'}}`, encodeURIComponent(String(slug)))
                .replace(`{${'token'}}`, encodeURIComponent(String(token)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unlike giveaway comment
         * @param {string} id The comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlikeGiveawayComent(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unlikeGiveawayComent.');
            }
            const localVarPath = `/giveaway/comments/{id}/like`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GiveawayApi - functional programming interface
 * @export
 */
export const GiveawayApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete giveaway comment
         * @param {string} id The comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGiveawayComent(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = GiveawayApiFetchParamCreator(configuration).deleteGiveawayComent(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Edit giveaway comment
         * @param {string} id The comment id
         * @param {CommentsIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editGiveawayComent(id: string, body?: CommentsIdBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20025> {
            const localVarFetchArgs = GiveawayApiFetchParamCreator(configuration).editGiveawayComent(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Enter the giveaway when logged in
         * @summary Enter the giveaway (existing user)
         * @param {string} id The giveaway id
         * @param {EnterExistingBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enterGiveaway(id: string, body?: EnterExistingBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20026> {
            const localVarFetchArgs = GiveawayApiFetchParamCreator(configuration).enterGiveaway(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Enter giveaway as new user. No password required, a temporary password will be sent via email. In case of 'device_data_os' = 'web', session cookie will be set.
         * @summary Enter the giveaway (new user)
         * @param {string} id The giveaway id
         * @param {EnterNewBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enterGiveawayNew(id: string, body?: EnterNewBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20026> {
            const localVarFetchArgs = GiveawayApiFetchParamCreator(configuration).enterGiveawayNew(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Giveaway by slug
         * @param {string} slug The giveaway slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiveawayBySlug(slug: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20023> {
            const localVarFetchArgs = GiveawayApiFetchParamCreator(configuration).getGiveawayBySlug(slug, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the comments of the giveaway
         * @param {string} id The giveaway id
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiveawayComents(id: string, limit?: number, skip?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20024> {
            const localVarFetchArgs = GiveawayApiFetchParamCreator(configuration).getGiveawayComents(id, limit, skip, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get giveaway entry if exists
         * @param {string} id The giveaway id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiveawayEntry(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20026> {
            const localVarFetchArgs = GiveawayApiFetchParamCreator(configuration).getGiveawayEntry(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all Giveaways
         * @param {string} [platform] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiveaways(platform?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20022> {
            const localVarFetchArgs = GiveawayApiFetchParamCreator(configuration).getGiveaways(platform, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Like giveaway comment
         * @param {string} id The comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeGiveawayComent(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20025> {
            const localVarFetchArgs = GiveawayApiFetchParamCreator(configuration).likeGiveawayComent(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Comment the giveaway
         * @param {string} id The giveaway id
         * @param {IdCommentBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postGiveawayComent(id: string, body?: IdCommentBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20025> {
            const localVarFetchArgs = GiveawayApiFetchParamCreator(configuration).postGiveawayComent(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Will add the share link points to the users entry, and redirect to the entry link
         * @summary Open giveaway share link
         * @param {string} slug The slug of the giveaway share link
         * @param {string} token The token of the giveaway entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareGiveawayLink(slug: string, token: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GiveawayApiFetchParamCreator(configuration).shareGiveawayLink(slug, token, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Unlike giveaway comment
         * @param {string} id The comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlikeGiveawayComent(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20025> {
            const localVarFetchArgs = GiveawayApiFetchParamCreator(configuration).unlikeGiveawayComent(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GiveawayApi - factory interface
 * @export
 */
export const GiveawayApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Delete giveaway comment
         * @param {string} id The comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGiveawayComent(id: string, options?: any) {
            return GiveawayApiFp(configuration).deleteGiveawayComent(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Edit giveaway comment
         * @param {string} id The comment id
         * @param {CommentsIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editGiveawayComent(id: string, body?: CommentsIdBody, options?: any) {
            return GiveawayApiFp(configuration).editGiveawayComent(id, body, options)(fetch, basePath);
        },
        /**
         * Enter the giveaway when logged in
         * @summary Enter the giveaway (existing user)
         * @param {string} id The giveaway id
         * @param {EnterExistingBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enterGiveaway(id: string, body?: EnterExistingBody, options?: any) {
            return GiveawayApiFp(configuration).enterGiveaway(id, body, options)(fetch, basePath);
        },
        /**
         * Enter giveaway as new user. No password required, a temporary password will be sent via email. In case of 'device_data_os' = 'web', session cookie will be set.
         * @summary Enter the giveaway (new user)
         * @param {string} id The giveaway id
         * @param {EnterNewBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enterGiveawayNew(id: string, body?: EnterNewBody, options?: any) {
            return GiveawayApiFp(configuration).enterGiveawayNew(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Giveaway by slug
         * @param {string} slug The giveaway slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiveawayBySlug(slug: string, options?: any) {
            return GiveawayApiFp(configuration).getGiveawayBySlug(slug, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the comments of the giveaway
         * @param {string} id The giveaway id
         * @param {number} [limit] Default is 50
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiveawayComents(id: string, limit?: number, skip?: number, options?: any) {
            return GiveawayApiFp(configuration).getGiveawayComents(id, limit, skip, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get giveaway entry if exists
         * @param {string} id The giveaway id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiveawayEntry(id: string, options?: any) {
            return GiveawayApiFp(configuration).getGiveawayEntry(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all Giveaways
         * @param {string} [platform] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiveaways(platform?: string, options?: any) {
            return GiveawayApiFp(configuration).getGiveaways(platform, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Like giveaway comment
         * @param {string} id The comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeGiveawayComent(id: string, options?: any) {
            return GiveawayApiFp(configuration).likeGiveawayComent(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Comment the giveaway
         * @param {string} id The giveaway id
         * @param {IdCommentBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postGiveawayComent(id: string, body?: IdCommentBody, options?: any) {
            return GiveawayApiFp(configuration).postGiveawayComent(id, body, options)(fetch, basePath);
        },
        /**
         * Will add the share link points to the users entry, and redirect to the entry link
         * @summary Open giveaway share link
         * @param {string} slug The slug of the giveaway share link
         * @param {string} token The token of the giveaway entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareGiveawayLink(slug: string, token: string, options?: any) {
            return GiveawayApiFp(configuration).shareGiveawayLink(slug, token, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Unlike giveaway comment
         * @param {string} id The comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlikeGiveawayComent(id: string, options?: any) {
            return GiveawayApiFp(configuration).unlikeGiveawayComent(id, options)(fetch, basePath);
        },
    };
};

/**
 * GiveawayApi - interface
 * @export
 * @interface GiveawayApi
 */
export interface GiveawayApiInterface {
    /**
     * 
     * @summary Delete giveaway comment
     * @param {string} id The comment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiveawayApiInterface
     */
    deleteGiveawayComent(id: string, options?: any): Promise<InlineResponse2002>;

    /**
     * 
     * @summary Edit giveaway comment
     * @param {string} id The comment id
     * @param {CommentsIdBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiveawayApiInterface
     */
    editGiveawayComent(id: string, body?: CommentsIdBody, options?: any): Promise<InlineResponse20025>;

    /**
     * Enter the giveaway when logged in
     * @summary Enter the giveaway (existing user)
     * @param {string} id The giveaway id
     * @param {EnterExistingBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiveawayApiInterface
     */
    enterGiveaway(id: string, body?: EnterExistingBody, options?: any): Promise<InlineResponse20026>;

    /**
     * Enter giveaway as new user. No password required, a temporary password will be sent via email. In case of 'device_data_os' = 'web', session cookie will be set.
     * @summary Enter the giveaway (new user)
     * @param {string} id The giveaway id
     * @param {EnterNewBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiveawayApiInterface
     */
    enterGiveawayNew(id: string, body?: EnterNewBody, options?: any): Promise<InlineResponse20026>;

    /**
     * 
     * @summary Get Giveaway by slug
     * @param {string} slug The giveaway slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiveawayApiInterface
     */
    getGiveawayBySlug(slug: string, options?: any): Promise<InlineResponse20023>;

    /**
     * 
     * @summary Get the comments of the giveaway
     * @param {string} id The giveaway id
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiveawayApiInterface
     */
    getGiveawayComents(id: string, limit?: number, skip?: number, options?: any): Promise<InlineResponse20024>;

    /**
     * 
     * @summary Get giveaway entry if exists
     * @param {string} id The giveaway id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiveawayApiInterface
     */
    getGiveawayEntry(id: string, options?: any): Promise<InlineResponse20026>;

    /**
     * 
     * @summary Get all Giveaways
     * @param {string} [platform] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiveawayApiInterface
     */
    getGiveaways(platform?: string, options?: any): Promise<InlineResponse20022>;

    /**
     * 
     * @summary Like giveaway comment
     * @param {string} id The comment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiveawayApiInterface
     */
    likeGiveawayComent(id: string, options?: any): Promise<InlineResponse20025>;

    /**
     * 
     * @summary Comment the giveaway
     * @param {string} id The giveaway id
     * @param {IdCommentBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiveawayApiInterface
     */
    postGiveawayComent(id: string, body?: IdCommentBody, options?: any): Promise<InlineResponse20025>;

    /**
     * Will add the share link points to the users entry, and redirect to the entry link
     * @summary Open giveaway share link
     * @param {string} slug The slug of the giveaway share link
     * @param {string} token The token of the giveaway entry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiveawayApiInterface
     */
    shareGiveawayLink(slug: string, token: string, options?: any): Promise<{}>;

    /**
     * 
     * @summary Unlike giveaway comment
     * @param {string} id The comment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiveawayApiInterface
     */
    unlikeGiveawayComent(id: string, options?: any): Promise<InlineResponse20025>;

}

/**
 * GiveawayApi - object-oriented interface
 * @export
 * @class GiveawayApi
 * @extends {BaseAPI}
 */
export class GiveawayApi extends BaseAPI implements GiveawayApiInterface {
    /**
     * 
     * @summary Delete giveaway comment
     * @param {string} id The comment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiveawayApi
     */
    public deleteGiveawayComent(id: string, options?: any) {
        return GiveawayApiFp(this.configuration).deleteGiveawayComent(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Edit giveaway comment
     * @param {string} id The comment id
     * @param {CommentsIdBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiveawayApi
     */
    public editGiveawayComent(id: string, body?: CommentsIdBody, options?: any) {
        return GiveawayApiFp(this.configuration).editGiveawayComent(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * Enter the giveaway when logged in
     * @summary Enter the giveaway (existing user)
     * @param {string} id The giveaway id
     * @param {EnterExistingBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiveawayApi
     */
    public enterGiveaway(id: string, body?: EnterExistingBody, options?: any) {
        return GiveawayApiFp(this.configuration).enterGiveaway(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * Enter giveaway as new user. No password required, a temporary password will be sent via email. In case of 'device_data_os' = 'web', session cookie will be set.
     * @summary Enter the giveaway (new user)
     * @param {string} id The giveaway id
     * @param {EnterNewBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiveawayApi
     */
    public enterGiveawayNew(id: string, body?: EnterNewBody, options?: any) {
        return GiveawayApiFp(this.configuration).enterGiveawayNew(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Giveaway by slug
     * @param {string} slug The giveaway slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiveawayApi
     */
    public getGiveawayBySlug(slug: string, options?: any) {
        return GiveawayApiFp(this.configuration).getGiveawayBySlug(slug, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the comments of the giveaway
     * @param {string} id The giveaway id
     * @param {number} [limit] Default is 50
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiveawayApi
     */
    public getGiveawayComents(id: string, limit?: number, skip?: number, options?: any) {
        return GiveawayApiFp(this.configuration).getGiveawayComents(id, limit, skip, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get giveaway entry if exists
     * @param {string} id The giveaway id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiveawayApi
     */
    public getGiveawayEntry(id: string, options?: any) {
        return GiveawayApiFp(this.configuration).getGiveawayEntry(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all Giveaways
     * @param {string} [platform] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiveawayApi
     */
    public getGiveaways(platform?: string, options?: any) {
        return GiveawayApiFp(this.configuration).getGiveaways(platform, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Like giveaway comment
     * @param {string} id The comment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiveawayApi
     */
    public likeGiveawayComent(id: string, options?: any) {
        return GiveawayApiFp(this.configuration).likeGiveawayComent(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Comment the giveaway
     * @param {string} id The giveaway id
     * @param {IdCommentBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiveawayApi
     */
    public postGiveawayComent(id: string, body?: IdCommentBody, options?: any) {
        return GiveawayApiFp(this.configuration).postGiveawayComent(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * Will add the share link points to the users entry, and redirect to the entry link
     * @summary Open giveaway share link
     * @param {string} slug The slug of the giveaway share link
     * @param {string} token The token of the giveaway entry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiveawayApi
     */
    public shareGiveawayLink(slug: string, token: string, options?: any) {
        return GiveawayApiFp(this.configuration).shareGiveawayLink(slug, token, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Unlike giveaway comment
     * @param {string} id The comment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiveawayApi
     */
    public unlikeGiveawayComent(id: string, options?: any) {
        return GiveawayApiFp(this.configuration).unlikeGiveawayComent(id, options)(this.fetch, this.basePath);
    }

}
/**
 * MarketingApi - fetch parameter creator
 * @export
 */
export const MarketingApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Subscribe not authenticated user to Marketing campaign
         * @param {MarketingSubscribeBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeNotAuthenticatedUserMarketing(body?: MarketingSubscribeBody, options: any = {}): FetchArgs {
            const localVarPath = `/marketing/subscribe`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'MarketingSubscribeBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Subscribe user to Marketing campaign
         * @param {UserSubscribeBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeUserMarketing(body?: UserSubscribeBody, options: any = {}): FetchArgs {
            const localVarPath = `/marketing/user/subscribe`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'UserSubscribeBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketingApi - functional programming interface
 * @export
 */
export const MarketingApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Subscribe not authenticated user to Marketing campaign
         * @param {MarketingSubscribeBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeNotAuthenticatedUserMarketing(body?: MarketingSubscribeBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = MarketingApiFetchParamCreator(configuration).subscribeNotAuthenticatedUserMarketing(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Subscribe user to Marketing campaign
         * @param {UserSubscribeBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeUserMarketing(body?: UserSubscribeBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = MarketingApiFetchParamCreator(configuration).subscribeUserMarketing(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * MarketingApi - factory interface
 * @export
 */
export const MarketingApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Subscribe not authenticated user to Marketing campaign
         * @param {MarketingSubscribeBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeNotAuthenticatedUserMarketing(body?: MarketingSubscribeBody, options?: any) {
            return MarketingApiFp(configuration).subscribeNotAuthenticatedUserMarketing(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Subscribe user to Marketing campaign
         * @param {UserSubscribeBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeUserMarketing(body?: UserSubscribeBody, options?: any) {
            return MarketingApiFp(configuration).subscribeUserMarketing(body, options)(fetch, basePath);
        },
    };
};

/**
 * MarketingApi - interface
 * @export
 * @interface MarketingApi
 */
export interface MarketingApiInterface {
    /**
     * 
     * @summary Subscribe not authenticated user to Marketing campaign
     * @param {MarketingSubscribeBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingApiInterface
     */
    subscribeNotAuthenticatedUserMarketing(body?: MarketingSubscribeBody, options?: any): Promise<InlineResponse2002>;

    /**
     * 
     * @summary Subscribe user to Marketing campaign
     * @param {UserSubscribeBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingApiInterface
     */
    subscribeUserMarketing(body?: UserSubscribeBody, options?: any): Promise<InlineResponse2002>;

}

/**
 * MarketingApi - object-oriented interface
 * @export
 * @class MarketingApi
 * @extends {BaseAPI}
 */
export class MarketingApi extends BaseAPI implements MarketingApiInterface {
    /**
     * 
     * @summary Subscribe not authenticated user to Marketing campaign
     * @param {MarketingSubscribeBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingApi
     */
    public subscribeNotAuthenticatedUserMarketing(body?: MarketingSubscribeBody, options?: any) {
        return MarketingApiFp(this.configuration).subscribeNotAuthenticatedUserMarketing(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Subscribe user to Marketing campaign
     * @param {UserSubscribeBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingApi
     */
    public subscribeUserMarketing(body?: UserSubscribeBody, options?: any) {
        return MarketingApiFp(this.configuration).subscribeUserMarketing(body, options)(this.fetch, this.basePath);
    }

}
/**
 * NewsApi - fetch parameter creator
 * @export
 */
export const NewsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the article for the given provider and slug. No Authorization required.
         * @summary Get article for provider and slug
         * @param {string} provider_id The provider id. For BPM Supreme news, use &#x27;6046bbc1-6805-11e9-8527-0ea141e7e82c&#x27;
         * @param {string} slug The article slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticle(provider_id: string, slug: string, options: any = {}): FetchArgs {
            // verify required parameter 'provider_id' is not null or undefined
            if (provider_id === null || provider_id === undefined) {
                throw new RequiredError('provider_id','Required parameter provider_id was null or undefined when calling getArticle.');
            }
            // verify required parameter 'slug' is not null or undefined
            if (slug === null || slug === undefined) {
                throw new RequiredError('slug','Required parameter slug was null or undefined when calling getArticle.');
            }
            const localVarPath = `/news/providers/{provider_id}/{slug}`
                .replace(`{${'provider_id'}}`, encodeURIComponent(String(provider_id)))
                .replace(`{${'slug'}}`, encodeURIComponent(String(slug)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the latest news for the given provider, limited to 50 max. No Authorization required.
         * @summary Get news for provider
         * @param {string} provider_id The provider id. For BPM Supreme news, use &#x27;6046bbc1-6805-11e9-8527-0ea141e7e82c&#x27;
         * @param {string} [term] Optional search term.
         * @param {string} [tag] Tag filter(s), use the tag id!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsForProvider(provider_id: string, term?: string, tag?: string, options: any = {}): FetchArgs {
            // verify required parameter 'provider_id' is not null or undefined
            if (provider_id === null || provider_id === undefined) {
                throw new RequiredError('provider_id','Required parameter provider_id was null or undefined when calling getNewsForProvider.');
            }
            const localVarPath = `/news/providers/{provider_id}/newsfeed`
                .replace(`{${'provider_id'}}`, encodeURIComponent(String(provider_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get news tags for provider
         * @param {string} provider_id The provider id. For BPM Supreme news, use &#x27;6046bbc1-6805-11e9-8527-0ea141e7e82c&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsTagsForProvider(provider_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'provider_id' is not null or undefined
            if (provider_id === null || provider_id === undefined) {
                throw new RequiredError('provider_id','Required parameter provider_id was null or undefined when calling getNewsTagsForProvider.');
            }
            const localVarPath = `/news/providers/{provider_id}/tags`
                .replace(`{${'provider_id'}}`, encodeURIComponent(String(provider_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsApi - functional programming interface
 * @export
 */
export const NewsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Returns the article for the given provider and slug. No Authorization required.
         * @summary Get article for provider and slug
         * @param {string} provider_id The provider id. For BPM Supreme news, use &#x27;6046bbc1-6805-11e9-8527-0ea141e7e82c&#x27;
         * @param {string} slug The article slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticle(provider_id: string, slug: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20021> {
            const localVarFetchArgs = NewsApiFetchParamCreator(configuration).getArticle(provider_id, slug, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Returns the latest news for the given provider, limited to 50 max. No Authorization required.
         * @summary Get news for provider
         * @param {string} provider_id The provider id. For BPM Supreme news, use &#x27;6046bbc1-6805-11e9-8527-0ea141e7e82c&#x27;
         * @param {string} [term] Optional search term.
         * @param {string} [tag] Tag filter(s), use the tag id!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsForProvider(provider_id: string, term?: string, tag?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20019> {
            const localVarFetchArgs = NewsApiFetchParamCreator(configuration).getNewsForProvider(provider_id, term, tag, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get news tags for provider
         * @param {string} provider_id The provider id. For BPM Supreme news, use &#x27;6046bbc1-6805-11e9-8527-0ea141e7e82c&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsTagsForProvider(provider_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20020> {
            const localVarFetchArgs = NewsApiFetchParamCreator(configuration).getNewsTagsForProvider(provider_id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * NewsApi - factory interface
 * @export
 */
export const NewsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Returns the article for the given provider and slug. No Authorization required.
         * @summary Get article for provider and slug
         * @param {string} provider_id The provider id. For BPM Supreme news, use &#x27;6046bbc1-6805-11e9-8527-0ea141e7e82c&#x27;
         * @param {string} slug The article slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticle(provider_id: string, slug: string, options?: any) {
            return NewsApiFp(configuration).getArticle(provider_id, slug, options)(fetch, basePath);
        },
        /**
         * Returns the latest news for the given provider, limited to 50 max. No Authorization required.
         * @summary Get news for provider
         * @param {string} provider_id The provider id. For BPM Supreme news, use &#x27;6046bbc1-6805-11e9-8527-0ea141e7e82c&#x27;
         * @param {string} [term] Optional search term.
         * @param {string} [tag] Tag filter(s), use the tag id!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsForProvider(provider_id: string, term?: string, tag?: string, options?: any) {
            return NewsApiFp(configuration).getNewsForProvider(provider_id, term, tag, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get news tags for provider
         * @param {string} provider_id The provider id. For BPM Supreme news, use &#x27;6046bbc1-6805-11e9-8527-0ea141e7e82c&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsTagsForProvider(provider_id: string, options?: any) {
            return NewsApiFp(configuration).getNewsTagsForProvider(provider_id, options)(fetch, basePath);
        },
    };
};

/**
 * NewsApi - interface
 * @export
 * @interface NewsApi
 */
export interface NewsApiInterface {
    /**
     * Returns the article for the given provider and slug. No Authorization required.
     * @summary Get article for provider and slug
     * @param {string} provider_id The provider id. For BPM Supreme news, use &#x27;6046bbc1-6805-11e9-8527-0ea141e7e82c&#x27;
     * @param {string} slug The article slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    getArticle(provider_id: string, slug: string, options?: any): Promise<InlineResponse20021>;

    /**
     * Returns the latest news for the given provider, limited to 50 max. No Authorization required.
     * @summary Get news for provider
     * @param {string} provider_id The provider id. For BPM Supreme news, use &#x27;6046bbc1-6805-11e9-8527-0ea141e7e82c&#x27;
     * @param {string} [term] Optional search term.
     * @param {string} [tag] Tag filter(s), use the tag id!
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    getNewsForProvider(provider_id: string, term?: string, tag?: string, options?: any): Promise<InlineResponse20019>;

    /**
     * 
     * @summary Get news tags for provider
     * @param {string} provider_id The provider id. For BPM Supreme news, use &#x27;6046bbc1-6805-11e9-8527-0ea141e7e82c&#x27;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    getNewsTagsForProvider(provider_id: string, options?: any): Promise<InlineResponse20020>;

}

/**
 * NewsApi - object-oriented interface
 * @export
 * @class NewsApi
 * @extends {BaseAPI}
 */
export class NewsApi extends BaseAPI implements NewsApiInterface {
    /**
     * Returns the article for the given provider and slug. No Authorization required.
     * @summary Get article for provider and slug
     * @param {string} provider_id The provider id. For BPM Supreme news, use &#x27;6046bbc1-6805-11e9-8527-0ea141e7e82c&#x27;
     * @param {string} slug The article slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public getArticle(provider_id: string, slug: string, options?: any) {
        return NewsApiFp(this.configuration).getArticle(provider_id, slug, options)(this.fetch, this.basePath);
    }

    /**
     * Returns the latest news for the given provider, limited to 50 max. No Authorization required.
     * @summary Get news for provider
     * @param {string} provider_id The provider id. For BPM Supreme news, use &#x27;6046bbc1-6805-11e9-8527-0ea141e7e82c&#x27;
     * @param {string} [term] Optional search term.
     * @param {string} [tag] Tag filter(s), use the tag id!
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public getNewsForProvider(provider_id: string, term?: string, tag?: string, options?: any) {
        return NewsApiFp(this.configuration).getNewsForProvider(provider_id, term, tag, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get news tags for provider
     * @param {string} provider_id The provider id. For BPM Supreme news, use &#x27;6046bbc1-6805-11e9-8527-0ea141e7e82c&#x27;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public getNewsTagsForProvider(provider_id: string, options?: any) {
        return NewsApiFp(this.configuration).getNewsTagsForProvider(provider_id, options)(this.fetch, this.basePath);
    }

}
/**
 * NotificationApi - fetch parameter creator
 * @export
 */
export const NotificationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Notification
         * @param {NotificationsBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotification(body?: NotificationsBody, options: any = {}): FetchArgs {
            const localVarPath = `/notifications`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'NotificationsBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Notifications
         * @param {Array<string>} platform The platforms. Can be multiple
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotifications(platform: Array<string>, options: any = {}): FetchArgs {
            // verify required parameter 'platform' is not null or undefined
            if (platform === null || platform === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling deleteNotifications.');
            }
            const localVarPath = `/notifications`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (platform) {
                localVarQueryParameter['platform'] = platform;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Notification
         * @param {string} id The notification id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotifications_1(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteNotifications_1.');
            }
            const localVarPath = `/notifications/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Notifications
         * @param {Array<string>} platform The platforms. Can be multiple
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(platform: Array<string>, options: any = {}): FetchArgs {
            // verify required parameter 'platform' is not null or undefined
            if (platform === null || platform === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling getNotifications.');
            }
            const localVarPath = `/notifications`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (platform) {
                localVarQueryParameter['platform'] = platform;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark Notifications read
         * @param {Array<string>} platform The platforms. Can be multiple
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNotifications(platform: Array<string>, options: any = {}): FetchArgs {
            // verify required parameter 'platform' is not null or undefined
            if (platform === null || platform === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling readNotifications.');
            }
            const localVarPath = `/notifications/read`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (platform) {
                localVarQueryParameter['platform'] = platform;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Notification
         * @param {NotificationsBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotification(body?: NotificationsBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20027> {
            const localVarFetchArgs = NotificationApiFetchParamCreator(configuration).createNotification(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete Notifications
         * @param {Array<string>} platform The platforms. Can be multiple
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotifications(platform: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = NotificationApiFetchParamCreator(configuration).deleteNotifications(platform, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete Notification
         * @param {string} id The notification id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotifications_1(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = NotificationApiFetchParamCreator(configuration).deleteNotifications_1(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Notifications
         * @param {Array<string>} platform The platforms. Can be multiple
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(platform: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse201> {
            const localVarFetchArgs = NotificationApiFetchParamCreator(configuration).getNotifications(platform, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Mark Notifications read
         * @param {Array<string>} platform The platforms. Can be multiple
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNotifications(platform: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = NotificationApiFetchParamCreator(configuration).readNotifications(platform, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Create Notification
         * @param {NotificationsBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotification(body?: NotificationsBody, options?: any) {
            return NotificationApiFp(configuration).createNotification(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete Notifications
         * @param {Array<string>} platform The platforms. Can be multiple
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotifications(platform: Array<string>, options?: any) {
            return NotificationApiFp(configuration).deleteNotifications(platform, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete Notification
         * @param {string} id The notification id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotifications_1(id: string, options?: any) {
            return NotificationApiFp(configuration).deleteNotifications_1(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Notifications
         * @param {Array<string>} platform The platforms. Can be multiple
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(platform: Array<string>, options?: any) {
            return NotificationApiFp(configuration).getNotifications(platform, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Mark Notifications read
         * @param {Array<string>} platform The platforms. Can be multiple
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNotifications(platform: Array<string>, options?: any) {
            return NotificationApiFp(configuration).readNotifications(platform, options)(fetch, basePath);
        },
    };
};

/**
 * NotificationApi - interface
 * @export
 * @interface NotificationApi
 */
export interface NotificationApiInterface {
    /**
     * 
     * @summary Create Notification
     * @param {NotificationsBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApiInterface
     */
    createNotification(body?: NotificationsBody, options?: any): Promise<InlineResponse20027>;

    /**
     * 
     * @summary Delete Notifications
     * @param {Array<string>} platform The platforms. Can be multiple
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApiInterface
     */
    deleteNotifications(platform: Array<string>, options?: any): Promise<InlineResponse2002>;

    /**
     * 
     * @summary Delete Notification
     * @param {string} id The notification id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApiInterface
     */
    deleteNotifications_1(id: string, options?: any): Promise<InlineResponse2002>;

    /**
     * 
     * @summary Get Notifications
     * @param {Array<string>} platform The platforms. Can be multiple
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApiInterface
     */
    getNotifications(platform: Array<string>, options?: any): Promise<InlineResponse201>;

    /**
     * 
     * @summary Mark Notifications read
     * @param {Array<string>} platform The platforms. Can be multiple
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApiInterface
     */
    readNotifications(platform: Array<string>, options?: any): Promise<InlineResponse2002>;

}

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI implements NotificationApiInterface {
    /**
     * 
     * @summary Create Notification
     * @param {NotificationsBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public createNotification(body?: NotificationsBody, options?: any) {
        return NotificationApiFp(this.configuration).createNotification(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete Notifications
     * @param {Array<string>} platform The platforms. Can be multiple
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public deleteNotifications(platform: Array<string>, options?: any) {
        return NotificationApiFp(this.configuration).deleteNotifications(platform, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete Notification
     * @param {string} id The notification id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public deleteNotifications_1(id: string, options?: any) {
        return NotificationApiFp(this.configuration).deleteNotifications_1(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Notifications
     * @param {Array<string>} platform The platforms. Can be multiple
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public getNotifications(platform: Array<string>, options?: any) {
        return NotificationApiFp(this.configuration).getNotifications(platform, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Mark Notifications read
     * @param {Array<string>} platform The platforms. Can be multiple
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public readNotifications(platform: Array<string>, options?: any) {
        return NotificationApiFp(this.configuration).readNotifications(platform, options)(this.fetch, this.basePath);
    }

}
/**
 * PromotionApi - fetch parameter creator
 * @export
 */
export const PromotionApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check promotion access code
         * @param {string} code The code, without hyphens
         * @param {string} partner The selected partner name
         * @param {string} group The selected group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionCheckCode(code: string, partner: string, group: string, options: any = {}): FetchArgs {
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling promotionCheckCode.');
            }
            // verify required parameter 'partner' is not null or undefined
            if (partner === null || partner === undefined) {
                throw new RequiredError('partner','Required parameter partner was null or undefined when calling promotionCheckCode.');
            }
            // verify required parameter 'group' is not null or undefined
            if (group === null || group === undefined) {
                throw new RequiredError('group','Required parameter group was null or undefined when calling promotionCheckCode.');
            }
            const localVarPath = `/promotion/check_code`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (partner !== undefined) {
                localVarQueryParameter['partner'] = partner;
            }

            if (group !== undefined) {
                localVarQueryParameter['group'] = group;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Promotion partners
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionGetPartners(options: any = {}): FetchArgs {
            const localVarPath = `/promotion/partners`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign up for promotion as existing user
         * @param {SignUpExistingBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionSignupExisting(body?: SignUpExistingBody, options: any = {}): FetchArgs {
            const localVarPath = `/promotion/sign_up/existing`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'SignUpExistingBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign up for promotion as new user
         * @param {PromotionSignUpBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionSignupNew(body?: PromotionSignUpBody, options: any = {}): FetchArgs {
            const localVarPath = `/promotion/sign_up`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'PromotionSignUpBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromotionApi - functional programming interface
 * @export
 */
export const PromotionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check promotion access code
         * @param {string} code The code, without hyphens
         * @param {string} partner The selected partner name
         * @param {string} group The selected group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionCheckCode(code: string, partner: string, group: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20041> {
            const localVarFetchArgs = PromotionApiFetchParamCreator(configuration).promotionCheckCode(code, partner, group, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Promotion partners
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionGetPartners(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20042> {
            const localVarFetchArgs = PromotionApiFetchParamCreator(configuration).promotionGetPartners(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Sign up for promotion as existing user
         * @param {SignUpExistingBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionSignupExisting(body?: SignUpExistingBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20043> {
            const localVarFetchArgs = PromotionApiFetchParamCreator(configuration).promotionSignupExisting(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Sign up for promotion as new user
         * @param {PromotionSignUpBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionSignupNew(body?: PromotionSignUpBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20043> {
            const localVarFetchArgs = PromotionApiFetchParamCreator(configuration).promotionSignupNew(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PromotionApi - factory interface
 * @export
 */
export const PromotionApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Check promotion access code
         * @param {string} code The code, without hyphens
         * @param {string} partner The selected partner name
         * @param {string} group The selected group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionCheckCode(code: string, partner: string, group: string, options?: any) {
            return PromotionApiFp(configuration).promotionCheckCode(code, partner, group, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Promotion partners
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionGetPartners(options?: any) {
            return PromotionApiFp(configuration).promotionGetPartners(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Sign up for promotion as existing user
         * @param {SignUpExistingBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionSignupExisting(body?: SignUpExistingBody, options?: any) {
            return PromotionApiFp(configuration).promotionSignupExisting(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Sign up for promotion as new user
         * @param {PromotionSignUpBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionSignupNew(body?: PromotionSignUpBody, options?: any) {
            return PromotionApiFp(configuration).promotionSignupNew(body, options)(fetch, basePath);
        },
    };
};

/**
 * PromotionApi - interface
 * @export
 * @interface PromotionApi
 */
export interface PromotionApiInterface {
    /**
     * 
     * @summary Check promotion access code
     * @param {string} code The code, without hyphens
     * @param {string} partner The selected partner name
     * @param {string} group The selected group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApiInterface
     */
    promotionCheckCode(code: string, partner: string, group: string, options?: any): Promise<InlineResponse20041>;

    /**
     * 
     * @summary Get Promotion partners
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApiInterface
     */
    promotionGetPartners(options?: any): Promise<InlineResponse20042>;

    /**
     * 
     * @summary Sign up for promotion as existing user
     * @param {SignUpExistingBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApiInterface
     */
    promotionSignupExisting(body?: SignUpExistingBody, options?: any): Promise<InlineResponse20043>;

    /**
     * 
     * @summary Sign up for promotion as new user
     * @param {PromotionSignUpBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApiInterface
     */
    promotionSignupNew(body?: PromotionSignUpBody, options?: any): Promise<InlineResponse20043>;

}

/**
 * PromotionApi - object-oriented interface
 * @export
 * @class PromotionApi
 * @extends {BaseAPI}
 */
export class PromotionApi extends BaseAPI implements PromotionApiInterface {
    /**
     * 
     * @summary Check promotion access code
     * @param {string} code The code, without hyphens
     * @param {string} partner The selected partner name
     * @param {string} group The selected group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public promotionCheckCode(code: string, partner: string, group: string, options?: any) {
        return PromotionApiFp(this.configuration).promotionCheckCode(code, partner, group, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Promotion partners
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public promotionGetPartners(options?: any) {
        return PromotionApiFp(this.configuration).promotionGetPartners(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Sign up for promotion as existing user
     * @param {SignUpExistingBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public promotionSignupExisting(body?: SignUpExistingBody, options?: any) {
        return PromotionApiFp(this.configuration).promotionSignupExisting(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Sign up for promotion as new user
     * @param {PromotionSignUpBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionApi
     */
    public promotionSignupNew(body?: PromotionSignUpBody, options?: any) {
        return PromotionApiFp(this.configuration).promotionSignupNew(body, options)(this.fetch, this.basePath);
    }

}
/**
 * SubscriptionApi - fetch parameter creator
 * @export
 */
export const SubscriptionApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel subscription for platform
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscription(platform: string, options: any = {}): FetchArgs {
            // verify required parameter 'platform' is not null or undefined
            if (platform === null || platform === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling cancelSubscription.');
            }
            const localVarPath = `/subscription/{platform}/cancel`
                .replace(`{${'platform'}}`, encodeURIComponent(String(platform)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change current subscription
         * @param {number} packageId The package id
         * @param {ChangeSubscriptionRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeSubscription(packageId: number, body?: ChangeSubscriptionRequest, options: any = {}): FetchArgs {
            // verify required parameter 'packageId' is not null or undefined
            if (packageId === null || packageId === undefined) {
                throw new RequiredError('packageId','Required parameter packageId was null or undefined when calling changeSubscription.');
            }
            const localVarPath = `/subscription/change/{packageId}`
                .replace(`{${'packageId'}}`, encodeURIComponent(String(packageId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'ChangeSubscriptionRequest' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check coupon code
         * @param {number} packageId The package id
         * @param {string} name The name of the coupon code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCouponCode(packageId: number, name: string, options: any = {}): FetchArgs {
            // verify required parameter 'packageId' is not null or undefined
            if (packageId === null || packageId === undefined) {
                throw new RequiredError('packageId','Required parameter packageId was null or undefined when calling checkCouponCode.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling checkCouponCode.');
            }
            const localVarPath = `/coupon/{packageId}/{name}`
                .replace(`{${'packageId'}}`, encodeURIComponent(String(packageId)))
                .replace(`{${'name'}}`, encodeURIComponent(String(name)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Open this URL in a new window. Cookie needs to be set
         * @summary Download Invoice
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInvoice(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadInvoice.');
            }
            const localVarPath = `/subscription/{id}/invoice`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the current user and susbcription details
         * @summary Get Me with all subscriptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeAllSubscriptions(options: any = {}): FetchArgs {
            const localVarPath = `/all-platforms/users/me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the current user and susbcription details
         * @summary Get Me with subscription
         * @param {string} platform The platform to load the packages for. Supreme is Download.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeSubscription(platform: string, options: any = {}): FetchArgs {
            // verify required parameter 'platform' is not null or undefined
            if (platform === null || platform === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling getMeSubscription.');
            }
            const localVarPath = `/{platform}/users/me`
                .replace(`{${'platform'}}`, encodeURIComponent(String(platform)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get users payment history
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentHistory(platform: string, options: any = {}): FetchArgs {
            // verify required parameter 'platform' is not null or undefined
            if (platform === null || platform === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling getPaymentHistory.');
            }
            const localVarPath = `/users/me/payments`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (platform !== undefined) {
                localVarQueryParameter['platform'] = platform;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get users current payment instrument
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentInstrument(platform: string, options: any = {}): FetchArgs {
            // verify required parameter 'platform' is not null or undefined
            if (platform === null || platform === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling getPaymentInstrument.');
            }
            const localVarPath = `/users/me/payment-instrument`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (platform !== undefined) {
                localVarQueryParameter['platform'] = platform;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Payment Method By Intent
         * @param {string} platform The platform to load the packages for. Supreme is Download.
         * @param {SetupintentMethodBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethodBySetupIntent(platform: string, body?: SetupintentMethodBody, options: any = {}): FetchArgs {
            // verify required parameter 'platform' is not null or undefined
            if (platform === null || platform === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling getPaymentMethodBySetupIntent.');
            }
            const localVarPath = `/subscribe/{platform}/setup-intent/method`
                .replace(`{${'platform'}}`, encodeURIComponent(String(platform)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'SetupintentMethodBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Setup Intent
         * @param {string} platform The platform to load the packages for. Supreme is Download.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSetupIntent(platform: string, options: any = {}): FetchArgs {
            // verify required parameter 'platform' is not null or undefined
            if (platform === null || platform === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling getSetupIntent.');
            }
            const localVarPath = `/subscribe/{platform}/setup-intent`
                .replace(`{${'platform'}}`, encodeURIComponent(String(platform)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get subscription package
         * @param {number} packageId The package id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionPackage(packageId: number, options: any = {}): FetchArgs {
            // verify required parameter 'packageId' is not null or undefined
            if (packageId === null || packageId === undefined) {
                throw new RequiredError('packageId','Required parameter packageId was null or undefined when calling getSubscriptionPackage.');
            }
            const localVarPath = `/packages/{packageId}`
                .replace(`{${'packageId'}}`, encodeURIComponent(String(packageId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available subscription packages
         * @param {string} platform The platform to load the packages for. Supreme is Download.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionPackages(platform: string, options: any = {}): FetchArgs {
            // verify required parameter 'platform' is not null or undefined
            if (platform === null || platform === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling getSubscriptionPackages.');
            }
            const localVarPath = `/subscription/{platform}/packages`
                .replace(`{${'platform'}}`, encodeURIComponent(String(platform)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * To be called when user enters confirm subscription screen.
         * @summary Notify API that user is about to start the subscription.
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startSubscriptionProcess(platform: string, options: any = {}): FetchArgs {
            // verify required parameter 'platform' is not null or undefined
            if (platform === null || platform === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling startSubscriptionProcess.');
            }
            const localVarPath = `/subscription/{platform}/start-process`
                .replace(`{${'platform'}}`, encodeURIComponent(String(platform)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Subscribe to package with CC
         * @param {number} packageId The package id
         * @param {PackageIdCreditcardBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeCc(packageId: number, body?: PackageIdCreditcardBody, options: any = {}): FetchArgs {
            // verify required parameter 'packageId' is not null or undefined
            if (packageId === null || packageId === undefined) {
                throw new RequiredError('packageId','Required parameter packageId was null or undefined when calling subscribeCc.');
            }
            const localVarPath = `/subscribe/{packageId}/creditcard`
                .replace(`{${'packageId'}}`, encodeURIComponent(String(packageId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'PackageIdCreditcardBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Subscribe to package with Paypal
         * @param {number} packageId The package id
         * @param {PackageIdPaypalBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribePaypal(packageId: number, body?: PackageIdPaypalBody, options: any = {}): FetchArgs {
            // verify required parameter 'packageId' is not null or undefined
            if (packageId === null || packageId === undefined) {
                throw new RequiredError('packageId','Required parameter packageId was null or undefined when calling subscribePaypal.');
            }
            const localVarPath = `/subscribe/{packageId}/paypal`
                .replace(`{${'packageId'}}`, encodeURIComponent(String(packageId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'PackageIdPaypalBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Preview Susbcription
         * @param {number} packageId The package id
         * @param {PackageIdPreviewBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribePreview(packageId: number, body?: PackageIdPreviewBody, options: any = {}): FetchArgs {
            // verify required parameter 'packageId' is not null or undefined
            if (packageId === null || packageId === undefined) {
                throw new RequiredError('packageId','Required parameter packageId was null or undefined when calling subscribePreview.');
            }
            const localVarPath = `/subscribe/{packageId}/preview`
                .replace(`{${'packageId'}}`, encodeURIComponent(String(packageId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'PackageIdPreviewBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Subscribe to package with Stripe
         * @param {number} packageId The package id
         * @param {SubscribePackageIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeStripe(packageId: number, body?: SubscribePackageIdBody, options: any = {}): FetchArgs {
            // verify required parameter 'packageId' is not null or undefined
            if (packageId === null || packageId === undefined) {
                throw new RequiredError('packageId','Required parameter packageId was null or undefined when calling subscribeStripe.');
            }
            const localVarPath = `/subscribe/{packageId}`
                .replace(`{${'packageId'}}`, encodeURIComponent(String(packageId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'SubscribePackageIdBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionApi - functional programming interface
 * @export
 */
export const SubscriptionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel subscription for platform
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscription(platform: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = SubscriptionApiFetchParamCreator(configuration).cancelSubscription(platform, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Change current subscription
         * @param {number} packageId The package id
         * @param {ChangeSubscriptionRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeSubscription(packageId: number, body?: ChangeSubscriptionRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20037> {
            const localVarFetchArgs = SubscriptionApiFetchParamCreator(configuration).changeSubscription(packageId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Check coupon code
         * @param {number} packageId The package id
         * @param {string} name The name of the coupon code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCouponCode(packageId: number, name: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20032> {
            const localVarFetchArgs = SubscriptionApiFetchParamCreator(configuration).checkCouponCode(packageId, name, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Open this URL in a new window. Cookie needs to be set
         * @summary Download Invoice
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInvoice(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SubscriptionApiFetchParamCreator(configuration).downloadInvoice(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get the current user and susbcription details
         * @summary Get Me with all subscriptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeAllSubscriptions(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2006> {
            const localVarFetchArgs = SubscriptionApiFetchParamCreator(configuration).getMeAllSubscriptions(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get the current user and susbcription details
         * @summary Get Me with subscription
         * @param {string} platform The platform to load the packages for. Supreme is Download.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeSubscription(platform: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = SubscriptionApiFetchParamCreator(configuration).getMeSubscription(platform, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get users payment history
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentHistory(platform: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20012> {
            const localVarFetchArgs = SubscriptionApiFetchParamCreator(configuration).getPaymentHistory(platform, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get users current payment instrument
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentInstrument(platform: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20011> {
            const localVarFetchArgs = SubscriptionApiFetchParamCreator(configuration).getPaymentInstrument(platform, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Payment Method By Intent
         * @param {string} platform The platform to load the packages for. Supreme is Download.
         * @param {SetupintentMethodBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethodBySetupIntent(platform: string, body?: SetupintentMethodBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20039> {
            const localVarFetchArgs = SubscriptionApiFetchParamCreator(configuration).getPaymentMethodBySetupIntent(platform, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Setup Intent
         * @param {string} platform The platform to load the packages for. Supreme is Download.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSetupIntent(platform: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20038> {
            const localVarFetchArgs = SubscriptionApiFetchParamCreator(configuration).getSetupIntent(platform, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get subscription package
         * @param {number} packageId The package id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionPackage(packageId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20031> {
            const localVarFetchArgs = SubscriptionApiFetchParamCreator(configuration).getSubscriptionPackage(packageId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get available subscription packages
         * @param {string} platform The platform to load the packages for. Supreme is Download.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionPackages(platform: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20030> {
            const localVarFetchArgs = SubscriptionApiFetchParamCreator(configuration).getSubscriptionPackages(platform, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * To be called when user enters confirm subscription screen.
         * @summary Notify API that user is about to start the subscription.
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startSubscriptionProcess(platform: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = SubscriptionApiFetchParamCreator(configuration).startSubscriptionProcess(platform, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Subscribe to package with CC
         * @param {number} packageId The package id
         * @param {PackageIdCreditcardBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeCc(packageId: number, body?: PackageIdCreditcardBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20035> {
            const localVarFetchArgs = SubscriptionApiFetchParamCreator(configuration).subscribeCc(packageId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Subscribe to package with Paypal
         * @param {number} packageId The package id
         * @param {PackageIdPaypalBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribePaypal(packageId: number, body?: PackageIdPaypalBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20033> {
            const localVarFetchArgs = SubscriptionApiFetchParamCreator(configuration).subscribePaypal(packageId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Preview Susbcription
         * @param {number} packageId The package id
         * @param {PackageIdPreviewBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribePreview(packageId: number, body?: PackageIdPreviewBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20034> {
            const localVarFetchArgs = SubscriptionApiFetchParamCreator(configuration).subscribePreview(packageId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Subscribe to package with Stripe
         * @param {number} packageId The package id
         * @param {SubscribePackageIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeStripe(packageId: number, body?: SubscribePackageIdBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20036> {
            const localVarFetchArgs = SubscriptionApiFetchParamCreator(configuration).subscribeStripe(packageId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SubscriptionApi - factory interface
 * @export
 */
export const SubscriptionApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Cancel subscription for platform
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscription(platform: string, options?: any) {
            return SubscriptionApiFp(configuration).cancelSubscription(platform, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Change current subscription
         * @param {number} packageId The package id
         * @param {ChangeSubscriptionRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeSubscription(packageId: number, body?: ChangeSubscriptionRequest, options?: any) {
            return SubscriptionApiFp(configuration).changeSubscription(packageId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Check coupon code
         * @param {number} packageId The package id
         * @param {string} name The name of the coupon code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCouponCode(packageId: number, name: string, options?: any) {
            return SubscriptionApiFp(configuration).checkCouponCode(packageId, name, options)(fetch, basePath);
        },
        /**
         * Open this URL in a new window. Cookie needs to be set
         * @summary Download Invoice
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInvoice(id: number, options?: any) {
            return SubscriptionApiFp(configuration).downloadInvoice(id, options)(fetch, basePath);
        },
        /**
         * Get the current user and susbcription details
         * @summary Get Me with all subscriptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeAllSubscriptions(options?: any) {
            return SubscriptionApiFp(configuration).getMeAllSubscriptions(options)(fetch, basePath);
        },
        /**
         * Get the current user and susbcription details
         * @summary Get Me with subscription
         * @param {string} platform The platform to load the packages for. Supreme is Download.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeSubscription(platform: string, options?: any) {
            return SubscriptionApiFp(configuration).getMeSubscription(platform, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get users payment history
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentHistory(platform: string, options?: any) {
            return SubscriptionApiFp(configuration).getPaymentHistory(platform, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get users current payment instrument
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentInstrument(platform: string, options?: any) {
            return SubscriptionApiFp(configuration).getPaymentInstrument(platform, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Payment Method By Intent
         * @param {string} platform The platform to load the packages for. Supreme is Download.
         * @param {SetupintentMethodBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethodBySetupIntent(platform: string, body?: SetupintentMethodBody, options?: any) {
            return SubscriptionApiFp(configuration).getPaymentMethodBySetupIntent(platform, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Setup Intent
         * @param {string} platform The platform to load the packages for. Supreme is Download.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSetupIntent(platform: string, options?: any) {
            return SubscriptionApiFp(configuration).getSetupIntent(platform, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get subscription package
         * @param {number} packageId The package id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionPackage(packageId: number, options?: any) {
            return SubscriptionApiFp(configuration).getSubscriptionPackage(packageId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get available subscription packages
         * @param {string} platform The platform to load the packages for. Supreme is Download.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionPackages(platform: string, options?: any) {
            return SubscriptionApiFp(configuration).getSubscriptionPackages(platform, options)(fetch, basePath);
        },
        /**
         * To be called when user enters confirm subscription screen.
         * @summary Notify API that user is about to start the subscription.
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startSubscriptionProcess(platform: string, options?: any) {
            return SubscriptionApiFp(configuration).startSubscriptionProcess(platform, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Subscribe to package with CC
         * @param {number} packageId The package id
         * @param {PackageIdCreditcardBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeCc(packageId: number, body?: PackageIdCreditcardBody, options?: any) {
            return SubscriptionApiFp(configuration).subscribeCc(packageId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Subscribe to package with Paypal
         * @param {number} packageId The package id
         * @param {PackageIdPaypalBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribePaypal(packageId: number, body?: PackageIdPaypalBody, options?: any) {
            return SubscriptionApiFp(configuration).subscribePaypal(packageId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Preview Susbcription
         * @param {number} packageId The package id
         * @param {PackageIdPreviewBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribePreview(packageId: number, body?: PackageIdPreviewBody, options?: any) {
            return SubscriptionApiFp(configuration).subscribePreview(packageId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Subscribe to package with Stripe
         * @param {number} packageId The package id
         * @param {SubscribePackageIdBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeStripe(packageId: number, body?: SubscribePackageIdBody, options?: any) {
            return SubscriptionApiFp(configuration).subscribeStripe(packageId, body, options)(fetch, basePath);
        },
    };
};

/**
 * SubscriptionApi - interface
 * @export
 * @interface SubscriptionApi
 */
export interface SubscriptionApiInterface {
    /**
     * 
     * @summary Cancel subscription for platform
     * @param {string} platform 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    cancelSubscription(platform: string, options?: any): Promise<InlineResponse2002>;

    /**
     * 
     * @summary Change current subscription
     * @param {number} packageId The package id
     * @param {ChangeSubscriptionRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    changeSubscription(packageId: number, body?: ChangeSubscriptionRequest, options?: any): Promise<InlineResponse20037>;

    /**
     * 
     * @summary Check coupon code
     * @param {number} packageId The package id
     * @param {string} name The name of the coupon code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    checkCouponCode(packageId: number, name: string, options?: any): Promise<InlineResponse20032>;

    /**
     * Open this URL in a new window. Cookie needs to be set
     * @summary Download Invoice
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    downloadInvoice(id: number, options?: any): Promise<{}>;

    /**
     * Get the current user and susbcription details
     * @summary Get Me with all subscriptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    getMeAllSubscriptions(options?: any): Promise<InlineResponse2006>;

    /**
     * Get the current user and susbcription details
     * @summary Get Me with subscription
     * @param {string} platform The platform to load the packages for. Supreme is Download.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    getMeSubscription(platform: string, options?: any): Promise<InlineResponse2005>;

    /**
     * 
     * @summary Get users payment history
     * @param {string} platform 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    getPaymentHistory(platform: string, options?: any): Promise<InlineResponse20012>;

    /**
     * 
     * @summary Get users current payment instrument
     * @param {string} platform 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    getPaymentInstrument(platform: string, options?: any): Promise<InlineResponse20011>;

    /**
     * 
     * @summary Get Payment Method By Intent
     * @param {string} platform The platform to load the packages for. Supreme is Download.
     * @param {SetupintentMethodBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    getPaymentMethodBySetupIntent(platform: string, body?: SetupintentMethodBody, options?: any): Promise<InlineResponse20039>;

    /**
     * 
     * @summary Get Setup Intent
     * @param {string} platform The platform to load the packages for. Supreme is Download.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    getSetupIntent(platform: string, options?: any): Promise<InlineResponse20038>;

    /**
     * 
     * @summary Get subscription package
     * @param {number} packageId The package id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    getSubscriptionPackage(packageId: number, options?: any): Promise<InlineResponse20031>;

    /**
     * 
     * @summary Get available subscription packages
     * @param {string} platform The platform to load the packages for. Supreme is Download.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    getSubscriptionPackages(platform: string, options?: any): Promise<InlineResponse20030>;

    /**
     * To be called when user enters confirm subscription screen.
     * @summary Notify API that user is about to start the subscription.
     * @param {string} platform 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    startSubscriptionProcess(platform: string, options?: any): Promise<InlineResponse2002>;

    /**
     * 
     * @summary Subscribe to package with CC
     * @param {number} packageId The package id
     * @param {PackageIdCreditcardBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    subscribeCc(packageId: number, body?: PackageIdCreditcardBody, options?: any): Promise<InlineResponse20035>;

    /**
     * 
     * @summary Subscribe to package with Paypal
     * @param {number} packageId The package id
     * @param {PackageIdPaypalBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    subscribePaypal(packageId: number, body?: PackageIdPaypalBody, options?: any): Promise<InlineResponse20033>;

    /**
     * 
     * @summary Preview Susbcription
     * @param {number} packageId The package id
     * @param {PackageIdPreviewBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    subscribePreview(packageId: number, body?: PackageIdPreviewBody, options?: any): Promise<InlineResponse20034>;

    /**
     * 
     * @summary Subscribe to package with Stripe
     * @param {number} packageId The package id
     * @param {SubscribePackageIdBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    subscribeStripe(packageId: number, body?: SubscribePackageIdBody, options?: any): Promise<InlineResponse20036>;

}

/**
 * SubscriptionApi - object-oriented interface
 * @export
 * @class SubscriptionApi
 * @extends {BaseAPI}
 */
export class SubscriptionApi extends BaseAPI implements SubscriptionApiInterface {
    /**
     * 
     * @summary Cancel subscription for platform
     * @param {string} platform 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public cancelSubscription(platform: string, options?: any) {
        return SubscriptionApiFp(this.configuration).cancelSubscription(platform, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Change current subscription
     * @param {number} packageId The package id
     * @param {ChangeSubscriptionRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public changeSubscription(packageId: number, body?: ChangeSubscriptionRequest, options?: any) {
        return SubscriptionApiFp(this.configuration).changeSubscription(packageId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Check coupon code
     * @param {number} packageId The package id
     * @param {string} name The name of the coupon code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public checkCouponCode(packageId: number, name: string, options?: any) {
        return SubscriptionApiFp(this.configuration).checkCouponCode(packageId, name, options)(this.fetch, this.basePath);
    }

    /**
     * Open this URL in a new window. Cookie needs to be set
     * @summary Download Invoice
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public downloadInvoice(id: number, options?: any) {
        return SubscriptionApiFp(this.configuration).downloadInvoice(id, options)(this.fetch, this.basePath);
    }

    /**
     * Get the current user and susbcription details
     * @summary Get Me with all subscriptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public getMeAllSubscriptions(options?: any) {
        return SubscriptionApiFp(this.configuration).getMeAllSubscriptions(options)(this.fetch, this.basePath);
    }

    /**
     * Get the current user and susbcription details
     * @summary Get Me with subscription
     * @param {string} platform The platform to load the packages for. Supreme is Download.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public getMeSubscription(platform: string, options?: any) {
        return SubscriptionApiFp(this.configuration).getMeSubscription(platform, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get users payment history
     * @param {string} platform 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public getPaymentHistory(platform: string, options?: any) {
        return SubscriptionApiFp(this.configuration).getPaymentHistory(platform, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get users current payment instrument
     * @param {string} platform 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public getPaymentInstrument(platform: string, options?: any) {
        return SubscriptionApiFp(this.configuration).getPaymentInstrument(platform, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Payment Method By Intent
     * @param {string} platform The platform to load the packages for. Supreme is Download.
     * @param {SetupintentMethodBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public getPaymentMethodBySetupIntent(platform: string, body?: SetupintentMethodBody, options?: any) {
        return SubscriptionApiFp(this.configuration).getPaymentMethodBySetupIntent(platform, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Setup Intent
     * @param {string} platform The platform to load the packages for. Supreme is Download.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public getSetupIntent(platform: string, options?: any) {
        return SubscriptionApiFp(this.configuration).getSetupIntent(platform, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get subscription package
     * @param {number} packageId The package id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public getSubscriptionPackage(packageId: number, options?: any) {
        return SubscriptionApiFp(this.configuration).getSubscriptionPackage(packageId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get available subscription packages
     * @param {string} platform The platform to load the packages for. Supreme is Download.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public getSubscriptionPackages(platform: string, options?: any) {
        return SubscriptionApiFp(this.configuration).getSubscriptionPackages(platform, options)(this.fetch, this.basePath);
    }

    /**
     * To be called when user enters confirm subscription screen.
     * @summary Notify API that user is about to start the subscription.
     * @param {string} platform 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public startSubscriptionProcess(platform: string, options?: any) {
        return SubscriptionApiFp(this.configuration).startSubscriptionProcess(platform, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Subscribe to package with CC
     * @param {number} packageId The package id
     * @param {PackageIdCreditcardBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscribeCc(packageId: number, body?: PackageIdCreditcardBody, options?: any) {
        return SubscriptionApiFp(this.configuration).subscribeCc(packageId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Subscribe to package with Paypal
     * @param {number} packageId The package id
     * @param {PackageIdPaypalBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscribePaypal(packageId: number, body?: PackageIdPaypalBody, options?: any) {
        return SubscriptionApiFp(this.configuration).subscribePaypal(packageId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Preview Susbcription
     * @param {number} packageId The package id
     * @param {PackageIdPreviewBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscribePreview(packageId: number, body?: PackageIdPreviewBody, options?: any) {
        return SubscriptionApiFp(this.configuration).subscribePreview(packageId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Subscribe to package with Stripe
     * @param {number} packageId The package id
     * @param {SubscribePackageIdBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscribeStripe(packageId: number, body?: SubscribePackageIdBody, options?: any) {
        return SubscriptionApiFp(this.configuration).subscribeStripe(packageId, body, options)(this.fetch, this.basePath);
    }

}
/**
 * UserApi - fetch parameter creator
 * @export
 */
export const UserApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update Password
         * @param {MeChangePasswordBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(body?: MeChangePasswordBody, options: any = {}): FetchArgs {
            const localVarPath = `/users/me/change_password`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'MeChangePasswordBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the current users profile picture
         * @summary Delete Users picture
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMePic(options: any = {}): FetchArgs {
            const localVarPath = `/users/me/picture`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disable explicit content
         * @param {ParentalcontrolDisableexplicitcontentBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disapleExplicitContent(body?: ParentalcontrolDisableexplicitcontentBody, options: any = {}): FetchArgs {
            const localVarPath = `/users/me/parental-control/disable-explicit-content`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'ParentalcontrolDisableexplicitcontentBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enable explicit content
         * @param {ParentalcontrolEnableexplicitcontentBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableExplicitContent(body?: ParentalcontrolEnableexplicitcontentBody, options: any = {}): FetchArgs {
            const localVarPath = `/users/me/parental-control/enable-explicit-content`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'ParentalcontrolEnableexplicitcontentBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send forgot password email if email exists.
         * @summary Forgot Password
         * @param {ForgotpasswordBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(body?: ForgotpasswordBody, options: any = {}): FetchArgs {
            const localVarPath = `/forgotpassword`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'ForgotpasswordBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate link with session
         * @param {WebRedirectBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateWebRedirect(body?: WebRedirectBody, options: any = {}): FetchArgs {
            const localVarPath = `/users/web/redirect`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'WebRedirectBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the current user
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(options: any = {}): FetchArgs {
            const localVarPath = `/users/me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the current user and susbcription details
         * @summary Get Me with all subscriptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeAllSubscriptions(options: any = {}): FetchArgs {
            const localVarPath = `/all-platforms/users/me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the current user and susbcription details
         * @summary Get Me with subscription
         * @param {string} platform The platform to load the packages for. Supreme is Download.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeSubscription(platform: string, options: any = {}): FetchArgs {
            // verify required parameter 'platform' is not null or undefined
            if (platform === null || platform === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling getMeSubscription.');
            }
            const localVarPath = `/{platform}/users/me`
                .replace(`{${'platform'}}`, encodeURIComponent(String(platform)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the current users address
         * @summary Get User Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyAddress(options: any = {}): FetchArgs {
            const localVarPath = `/users/me/address`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the current users notification preferences
         * @summary Get User Preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPreferences(options: any = {}): FetchArgs {
            const localVarPath = `/users/me/preferences`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User tutorial status
         * @param {string} type The tutorial identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyTutorialStatus(type: string, options: any = {}): FetchArgs {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getMyTutorialStatus.');
            }
            const localVarPath = `/users/me/tutorial/{type}`
                .replace(`{${'type'}}`, encodeURIComponent(String(type)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get users payment history
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentHistory(platform: string, options: any = {}): FetchArgs {
            // verify required parameter 'platform' is not null or undefined
            if (platform === null || platform === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling getPaymentHistory.');
            }
            const localVarPath = `/users/me/payments`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (platform !== undefined) {
                localVarQueryParameter['platform'] = platform;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get users current payment instrument
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentInstrument(platform: string, options: any = {}): FetchArgs {
            // verify required parameter 'platform' is not null or undefined
            if (platform === null || platform === undefined) {
                throw new RequiredError('platform','Required parameter platform was null or undefined when calling getPaymentInstrument.');
            }
            const localVarPath = `/users/me/payment-instrument`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (platform !== undefined) {
                localVarQueryParameter['platform'] = platform;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Login a user. Note: If device_data_os is set to 'web', a session cookie will be set.
         * @summary Login
         * @param {LoginBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(body?: LoginBody, options: any = {}): FetchArgs {
            const localVarPath = `/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'LoginBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Logout a user. Will unset cookies.
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options: any = {}): FetchArgs {
            const localVarPath = `/logout`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request account deletion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestAccountDeletion(options: any = {}): FetchArgs {
            const localVarPath = `/users/me/request-account-deletion`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset users password
         * @param {ResetpasswordBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(body?: ResetpasswordBody, options: any = {}): FetchArgs {
            const localVarPath = `/resetpassword`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'ResetpasswordBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set User tutorial status
         * @param {string} type The tutorial identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMyTutorialStatus(type: string, options: any = {}): FetchArgs {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling setMyTutorialStatus.');
            }
            const localVarPath = `/users/me/tutorial/{type}`
                .replace(`{${'type'}}`, encodeURIComponent(String(type)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sign up a new User
         * @summary Sign Up
         * @param {UsersBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup(body?: UsersBody, options: any = {}): FetchArgs {
            const localVarPath = `/users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'UsersBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the current user
         * @summary Update Me
         * @param {UsersMeBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMe(body?: UsersMeBody, options: any = {}): FetchArgs {
            const localVarPath = `/users/me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'UsersMeBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the current users supreme library
         * @summary Update Users last selected supreme library
         * @param {SupremeLibraryBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeLibrary(body?: SupremeLibraryBody, options: any = {}): FetchArgs {
            const localVarPath = `/users/me/supreme/library`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'SupremeLibraryBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the current users profile picture
         * @summary Update Users picture
         * @param {Blob} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMePic(image?: Blob, options: any = {}): FetchArgs {
            const localVarPath = `/users/me/picture`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            if (image !== undefined) {
                localVarFormParams.set('image', image as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the current users theme
         * @summary Update Users Theme
         * @param {MeThemeBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeTheme(body?: MeThemeBody, options: any = {}): FetchArgs {
            const localVarPath = `/users/me/theme`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'MeThemeBody' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the current users address. Will also update the address on associated payment gateways.
         * @summary Update User Address
         * @param {UserAddress} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyAddress(body?: UserAddress, options: any = {}): FetchArgs {
            const localVarPath = `/users/me/address`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'UserAddress' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the current users notification preferences
         * @summary Update User Preferences
         * @param {Preferences} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyPreferences(body?: Preferences, options: any = {}): FetchArgs {
            const localVarPath = `/users/me/preferences`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminBearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Admin-Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Admin-Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('Authorization')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication Cookie required

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            // authentication IntegrationHeader required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey('BPM-Integration')
					: configuration.apiKey;
                if (localVarApiKeyValue !== undefined)
                    localVarHeaderParameter['BPM-Integration'] = localVarApiKeyValue;
            }

            if (configuration && configuration.includeCredentials) {
                localVarRequestOptions['credentials'] = 'include';
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'Preferences' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update Password
         * @param {MeChangePasswordBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(body?: MeChangePasswordBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).changePassword(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Delete the current users profile picture
         * @summary Delete Users picture
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMePic(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).deleteMePic(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Disable explicit content
         * @param {ParentalcontrolDisableexplicitcontentBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disapleExplicitContent(body?: ParentalcontrolDisableexplicitcontentBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).disapleExplicitContent(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Enable explicit content
         * @param {ParentalcontrolEnableexplicitcontentBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableExplicitContent(body?: ParentalcontrolEnableexplicitcontentBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).enableExplicitContent(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Send forgot password email if email exists.
         * @summary Forgot Password
         * @param {ForgotpasswordBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(body?: ForgotpasswordBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse200> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).forgotPassword(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Generate link with session
         * @param {WebRedirectBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateWebRedirect(body?: WebRedirectBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2007> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).generateWebRedirect(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get the current user
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).getMe(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get the current user and susbcription details
         * @summary Get Me with all subscriptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeAllSubscriptions(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2006> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).getMeAllSubscriptions(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get the current user and susbcription details
         * @summary Get Me with subscription
         * @param {string} platform The platform to load the packages for. Supreme is Download.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeSubscription(platform: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).getMeSubscription(platform, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get the current users address
         * @summary Get User Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyAddress(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2008> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).getMyAddress(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get the current users notification preferences
         * @summary Get User Preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPreferences(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2009> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).getMyPreferences(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get User tutorial status
         * @param {string} type The tutorial identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyTutorialStatus(type: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20010> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).getMyTutorialStatus(type, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get users payment history
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentHistory(platform: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20012> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).getPaymentHistory(platform, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get users current payment instrument
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentInstrument(platform: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse20011> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).getPaymentInstrument(platform, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Login a user. Note: If device_data_os is set to 'web', a session cookie will be set.
         * @summary Login
         * @param {LoginBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(body?: LoginBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2001> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).login(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Logout a user. Will unset cookies.
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).logout(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Request account deletion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestAccountDeletion(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).requestAccountDeletion(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Reset users password
         * @param {ResetpasswordBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(body?: ResetpasswordBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).resetPassword(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Set User tutorial status
         * @param {string} type The tutorial identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMyTutorialStatus(type: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).setMyTutorialStatus(type, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sign up a new User
         * @summary Sign Up
         * @param {UsersBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup(body?: UsersBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2001> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).signup(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Update the current user
         * @summary Update Me
         * @param {UsersMeBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMe(body?: UsersMeBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).updateMe(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Update the current users supreme library
         * @summary Update Users last selected supreme library
         * @param {SupremeLibraryBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeLibrary(body?: SupremeLibraryBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).updateMeLibrary(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Update the current users profile picture
         * @summary Update Users picture
         * @param {Blob} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMePic(image?: Blob, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).updateMePic(image, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Update the current users theme
         * @summary Update Users Theme
         * @param {MeThemeBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeTheme(body?: MeThemeBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).updateMeTheme(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Update the current users address. Will also update the address on associated payment gateways.
         * @summary Update User Address
         * @param {UserAddress} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyAddress(body?: UserAddress, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2008> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).updateMyAddress(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Update the current users notification preferences
         * @summary Update User Preferences
         * @param {Preferences} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyPreferences(body?: Preferences, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2009> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).updateMyPreferences(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Update Password
         * @param {MeChangePasswordBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(body?: MeChangePasswordBody, options?: any) {
            return UserApiFp(configuration).changePassword(body, options)(fetch, basePath);
        },
        /**
         * Delete the current users profile picture
         * @summary Delete Users picture
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMePic(options?: any) {
            return UserApiFp(configuration).deleteMePic(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Disable explicit content
         * @param {ParentalcontrolDisableexplicitcontentBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disapleExplicitContent(body?: ParentalcontrolDisableexplicitcontentBody, options?: any) {
            return UserApiFp(configuration).disapleExplicitContent(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Enable explicit content
         * @param {ParentalcontrolEnableexplicitcontentBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableExplicitContent(body?: ParentalcontrolEnableexplicitcontentBody, options?: any) {
            return UserApiFp(configuration).enableExplicitContent(body, options)(fetch, basePath);
        },
        /**
         * Send forgot password email if email exists.
         * @summary Forgot Password
         * @param {ForgotpasswordBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(body?: ForgotpasswordBody, options?: any) {
            return UserApiFp(configuration).forgotPassword(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Generate link with session
         * @param {WebRedirectBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateWebRedirect(body?: WebRedirectBody, options?: any) {
            return UserApiFp(configuration).generateWebRedirect(body, options)(fetch, basePath);
        },
        /**
         * Get the current user
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(options?: any) {
            return UserApiFp(configuration).getMe(options)(fetch, basePath);
        },
        /**
         * Get the current user and susbcription details
         * @summary Get Me with all subscriptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeAllSubscriptions(options?: any) {
            return UserApiFp(configuration).getMeAllSubscriptions(options)(fetch, basePath);
        },
        /**
         * Get the current user and susbcription details
         * @summary Get Me with subscription
         * @param {string} platform The platform to load the packages for. Supreme is Download.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeSubscription(platform: string, options?: any) {
            return UserApiFp(configuration).getMeSubscription(platform, options)(fetch, basePath);
        },
        /**
         * Get the current users address
         * @summary Get User Address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyAddress(options?: any) {
            return UserApiFp(configuration).getMyAddress(options)(fetch, basePath);
        },
        /**
         * Get the current users notification preferences
         * @summary Get User Preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPreferences(options?: any) {
            return UserApiFp(configuration).getMyPreferences(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get User tutorial status
         * @param {string} type The tutorial identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyTutorialStatus(type: string, options?: any) {
            return UserApiFp(configuration).getMyTutorialStatus(type, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get users payment history
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentHistory(platform: string, options?: any) {
            return UserApiFp(configuration).getPaymentHistory(platform, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get users current payment instrument
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentInstrument(platform: string, options?: any) {
            return UserApiFp(configuration).getPaymentInstrument(platform, options)(fetch, basePath);
        },
        /**
         * Login a user. Note: If device_data_os is set to 'web', a session cookie will be set.
         * @summary Login
         * @param {LoginBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(body?: LoginBody, options?: any) {
            return UserApiFp(configuration).login(body, options)(fetch, basePath);
        },
        /**
         * Logout a user. Will unset cookies.
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any) {
            return UserApiFp(configuration).logout(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Request account deletion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestAccountDeletion(options?: any) {
            return UserApiFp(configuration).requestAccountDeletion(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Reset users password
         * @param {ResetpasswordBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(body?: ResetpasswordBody, options?: any) {
            return UserApiFp(configuration).resetPassword(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Set User tutorial status
         * @param {string} type The tutorial identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMyTutorialStatus(type: string, options?: any) {
            return UserApiFp(configuration).setMyTutorialStatus(type, options)(fetch, basePath);
        },
        /**
         * Sign up a new User
         * @summary Sign Up
         * @param {UsersBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup(body?: UsersBody, options?: any) {
            return UserApiFp(configuration).signup(body, options)(fetch, basePath);
        },
        /**
         * Update the current user
         * @summary Update Me
         * @param {UsersMeBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMe(body?: UsersMeBody, options?: any) {
            return UserApiFp(configuration).updateMe(body, options)(fetch, basePath);
        },
        /**
         * Update the current users supreme library
         * @summary Update Users last selected supreme library
         * @param {SupremeLibraryBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeLibrary(body?: SupremeLibraryBody, options?: any) {
            return UserApiFp(configuration).updateMeLibrary(body, options)(fetch, basePath);
        },
        /**
         * Update the current users profile picture
         * @summary Update Users picture
         * @param {Blob} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMePic(image?: Blob, options?: any) {
            return UserApiFp(configuration).updateMePic(image, options)(fetch, basePath);
        },
        /**
         * Update the current users theme
         * @summary Update Users Theme
         * @param {MeThemeBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeTheme(body?: MeThemeBody, options?: any) {
            return UserApiFp(configuration).updateMeTheme(body, options)(fetch, basePath);
        },
        /**
         * Update the current users address. Will also update the address on associated payment gateways.
         * @summary Update User Address
         * @param {UserAddress} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyAddress(body?: UserAddress, options?: any) {
            return UserApiFp(configuration).updateMyAddress(body, options)(fetch, basePath);
        },
        /**
         * Update the current users notification preferences
         * @summary Update User Preferences
         * @param {Preferences} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyPreferences(body?: Preferences, options?: any) {
            return UserApiFp(configuration).updateMyPreferences(body, options)(fetch, basePath);
        },
    };
};

/**
 * UserApi - interface
 * @export
 * @interface UserApi
 */
export interface UserApiInterface {
    /**
     * 
     * @summary Update Password
     * @param {MeChangePasswordBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    changePassword(body?: MeChangePasswordBody, options?: any): Promise<InlineResponse2004>;

    /**
     * Delete the current users profile picture
     * @summary Delete Users picture
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    deleteMePic(options?: any): Promise<InlineResponse2004>;

    /**
     * 
     * @summary Disable explicit content
     * @param {ParentalcontrolDisableexplicitcontentBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    disapleExplicitContent(body?: ParentalcontrolDisableexplicitcontentBody, options?: any): Promise<InlineResponse2004>;

    /**
     * 
     * @summary Enable explicit content
     * @param {ParentalcontrolEnableexplicitcontentBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    enableExplicitContent(body?: ParentalcontrolEnableexplicitcontentBody, options?: any): Promise<InlineResponse2004>;

    /**
     * Send forgot password email if email exists.
     * @summary Forgot Password
     * @param {ForgotpasswordBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    forgotPassword(body?: ForgotpasswordBody, options?: any): Promise<InlineResponse200>;

    /**
     * 
     * @summary Generate link with session
     * @param {WebRedirectBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    generateWebRedirect(body?: WebRedirectBody, options?: any): Promise<InlineResponse2007>;

    /**
     * Get the current user
     * @summary Get Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getMe(options?: any): Promise<InlineResponse2003>;

    /**
     * Get the current user and susbcription details
     * @summary Get Me with all subscriptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getMeAllSubscriptions(options?: any): Promise<InlineResponse2006>;

    /**
     * Get the current user and susbcription details
     * @summary Get Me with subscription
     * @param {string} platform The platform to load the packages for. Supreme is Download.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getMeSubscription(platform: string, options?: any): Promise<InlineResponse2005>;

    /**
     * Get the current users address
     * @summary Get User Address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getMyAddress(options?: any): Promise<InlineResponse2008>;

    /**
     * Get the current users notification preferences
     * @summary Get User Preferences
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getMyPreferences(options?: any): Promise<InlineResponse2009>;

    /**
     * 
     * @summary Get User tutorial status
     * @param {string} type The tutorial identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getMyTutorialStatus(type: string, options?: any): Promise<InlineResponse20010>;

    /**
     * 
     * @summary Get users payment history
     * @param {string} platform 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getPaymentHistory(platform: string, options?: any): Promise<InlineResponse20012>;

    /**
     * 
     * @summary Get users current payment instrument
     * @param {string} platform 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getPaymentInstrument(platform: string, options?: any): Promise<InlineResponse20011>;

    /**
     * Login a user. Note: If device_data_os is set to 'web', a session cookie will be set.
     * @summary Login
     * @param {LoginBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    login(body?: LoginBody, options?: any): Promise<InlineResponse2001>;

    /**
     * Logout a user. Will unset cookies.
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    logout(options?: any): Promise<InlineResponse2002>;

    /**
     * 
     * @summary Request account deletion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    requestAccountDeletion(options?: any): Promise<any>;

    /**
     * 
     * @summary Reset users password
     * @param {ResetpasswordBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    resetPassword(body?: ResetpasswordBody, options?: any): Promise<InlineResponse2002>;

    /**
     * 
     * @summary Set User tutorial status
     * @param {string} type The tutorial identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    setMyTutorialStatus(type: string, options?: any): Promise<InlineResponse2002>;

    /**
     * Sign up a new User
     * @summary Sign Up
     * @param {UsersBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    signup(body?: UsersBody, options?: any): Promise<InlineResponse2001>;

    /**
     * Update the current user
     * @summary Update Me
     * @param {UsersMeBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    updateMe(body?: UsersMeBody, options?: any): Promise<InlineResponse2004>;

    /**
     * Update the current users supreme library
     * @summary Update Users last selected supreme library
     * @param {SupremeLibraryBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    updateMeLibrary(body?: SupremeLibraryBody, options?: any): Promise<InlineResponse2004>;

    /**
     * Update the current users profile picture
     * @summary Update Users picture
     * @param {Blob} [image] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    updateMePic(image?: Blob, options?: any): Promise<InlineResponse2004>;

    /**
     * Update the current users theme
     * @summary Update Users Theme
     * @param {MeThemeBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    updateMeTheme(body?: MeThemeBody, options?: any): Promise<InlineResponse2004>;

    /**
     * Update the current users address. Will also update the address on associated payment gateways.
     * @summary Update User Address
     * @param {UserAddress} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    updateMyAddress(body?: UserAddress, options?: any): Promise<InlineResponse2008>;

    /**
     * Update the current users notification preferences
     * @summary Update User Preferences
     * @param {Preferences} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    updateMyPreferences(body?: Preferences, options?: any): Promise<InlineResponse2009>;

}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI implements UserApiInterface {
    /**
     * 
     * @summary Update Password
     * @param {MeChangePasswordBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public changePassword(body?: MeChangePasswordBody, options?: any) {
        return UserApiFp(this.configuration).changePassword(body, options)(this.fetch, this.basePath);
    }

    /**
     * Delete the current users profile picture
     * @summary Delete Users picture
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteMePic(options?: any) {
        return UserApiFp(this.configuration).deleteMePic(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Disable explicit content
     * @param {ParentalcontrolDisableexplicitcontentBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public disapleExplicitContent(body?: ParentalcontrolDisableexplicitcontentBody, options?: any) {
        return UserApiFp(this.configuration).disapleExplicitContent(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Enable explicit content
     * @param {ParentalcontrolEnableexplicitcontentBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public enableExplicitContent(body?: ParentalcontrolEnableexplicitcontentBody, options?: any) {
        return UserApiFp(this.configuration).enableExplicitContent(body, options)(this.fetch, this.basePath);
    }

    /**
     * Send forgot password email if email exists.
     * @summary Forgot Password
     * @param {ForgotpasswordBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public forgotPassword(body?: ForgotpasswordBody, options?: any) {
        return UserApiFp(this.configuration).forgotPassword(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Generate link with session
     * @param {WebRedirectBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public generateWebRedirect(body?: WebRedirectBody, options?: any) {
        return UserApiFp(this.configuration).generateWebRedirect(body, options)(this.fetch, this.basePath);
    }

    /**
     * Get the current user
     * @summary Get Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getMe(options?: any) {
        return UserApiFp(this.configuration).getMe(options)(this.fetch, this.basePath);
    }

    /**
     * Get the current user and susbcription details
     * @summary Get Me with all subscriptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getMeAllSubscriptions(options?: any) {
        return UserApiFp(this.configuration).getMeAllSubscriptions(options)(this.fetch, this.basePath);
    }

    /**
     * Get the current user and susbcription details
     * @summary Get Me with subscription
     * @param {string} platform The platform to load the packages for. Supreme is Download.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getMeSubscription(platform: string, options?: any) {
        return UserApiFp(this.configuration).getMeSubscription(platform, options)(this.fetch, this.basePath);
    }

    /**
     * Get the current users address
     * @summary Get User Address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getMyAddress(options?: any) {
        return UserApiFp(this.configuration).getMyAddress(options)(this.fetch, this.basePath);
    }

    /**
     * Get the current users notification preferences
     * @summary Get User Preferences
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getMyPreferences(options?: any) {
        return UserApiFp(this.configuration).getMyPreferences(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get User tutorial status
     * @param {string} type The tutorial identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getMyTutorialStatus(type: string, options?: any) {
        return UserApiFp(this.configuration).getMyTutorialStatus(type, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get users payment history
     * @param {string} platform 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getPaymentHistory(platform: string, options?: any) {
        return UserApiFp(this.configuration).getPaymentHistory(platform, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get users current payment instrument
     * @param {string} platform 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getPaymentInstrument(platform: string, options?: any) {
        return UserApiFp(this.configuration).getPaymentInstrument(platform, options)(this.fetch, this.basePath);
    }

    /**
     * Login a user. Note: If device_data_os is set to 'web', a session cookie will be set.
     * @summary Login
     * @param {LoginBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public login(body?: LoginBody, options?: any) {
        return UserApiFp(this.configuration).login(body, options)(this.fetch, this.basePath);
    }

    /**
     * Logout a user. Will unset cookies.
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public logout(options?: any) {
        return UserApiFp(this.configuration).logout(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Request account deletion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public requestAccountDeletion(options?: any) {
        return UserApiFp(this.configuration).requestAccountDeletion(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Reset users password
     * @param {ResetpasswordBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public resetPassword(body?: ResetpasswordBody, options?: any) {
        return UserApiFp(this.configuration).resetPassword(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Set User tutorial status
     * @param {string} type The tutorial identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public setMyTutorialStatus(type: string, options?: any) {
        return UserApiFp(this.configuration).setMyTutorialStatus(type, options)(this.fetch, this.basePath);
    }

    /**
     * Sign up a new User
     * @summary Sign Up
     * @param {UsersBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public signup(body?: UsersBody, options?: any) {
        return UserApiFp(this.configuration).signup(body, options)(this.fetch, this.basePath);
    }

    /**
     * Update the current user
     * @summary Update Me
     * @param {UsersMeBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateMe(body?: UsersMeBody, options?: any) {
        return UserApiFp(this.configuration).updateMe(body, options)(this.fetch, this.basePath);
    }

    /**
     * Update the current users supreme library
     * @summary Update Users last selected supreme library
     * @param {SupremeLibraryBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateMeLibrary(body?: SupremeLibraryBody, options?: any) {
        return UserApiFp(this.configuration).updateMeLibrary(body, options)(this.fetch, this.basePath);
    }

    /**
     * Update the current users profile picture
     * @summary Update Users picture
     * @param {Blob} [image] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateMePic(image?: Blob, options?: any) {
        return UserApiFp(this.configuration).updateMePic(image, options)(this.fetch, this.basePath);
    }

    /**
     * Update the current users theme
     * @summary Update Users Theme
     * @param {MeThemeBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateMeTheme(body?: MeThemeBody, options?: any) {
        return UserApiFp(this.configuration).updateMeTheme(body, options)(this.fetch, this.basePath);
    }

    /**
     * Update the current users address. Will also update the address on associated payment gateways.
     * @summary Update User Address
     * @param {UserAddress} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateMyAddress(body?: UserAddress, options?: any) {
        return UserApiFp(this.configuration).updateMyAddress(body, options)(this.fetch, this.basePath);
    }

    /**
     * Update the current users notification preferences
     * @summary Update User Preferences
     * @param {Preferences} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateMyPreferences(body?: Preferences, options?: any) {
        return UserApiFp(this.configuration).updateMyPreferences(body, options)(this.fetch, this.basePath);
    }

}
